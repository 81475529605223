// Absolute imports
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

// Components
import KYCHeader from "../KYCHeader";
import KYCFirstStep from "../KYCFirstStep";
import KYCSecondStep from "../KYCSecondStep";
import KYCThirdStep from "../KYCThirdStep";
import Footer from "../Footer";
import ProfileSettings from "../ProfileSettings";

import KYCRejected from "../KYCRejected";

// Styles
import { KYCRoot, H3 } from "./styles";

// Action creators
import {
  getUserDataAction,
  toggleDropDownAction
} from "../../redux/actionCreators";
import KYCInReview from "../KYCInReview";

const KYC = ({ status, pageStep, getUserData, toggleDropDown }) => {
  const [{ step }, setState] = useState({
    step: 1
  });

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      getUserData(accessToken);
    }
  }, []);

  useEffect(() => {
    if (status === "pending") {
      setState(values => ({
        ...values,
        step: pageStep
      }));
    }

    if (status === "cancelled") {
      setState(values => ({
        ...values,
        step: 1
      }));
    }

    if (status === "review") {
      setState(values => ({
        ...values,
        step: 4
      }));
    }

    if (status === "declined") {
      setState(values => ({
        ...values,
        step: 5
      }));
    }

    if (status === "approved") {
      setState(values => ({
        ...values,
        step: 6
      }));
    }
  }, [status, pageStep]);

  const resetSteps = () => {
    setState(values => ({
      ...values,
      step: 1
    }));
  };

  const closeDropDown = event => {
    toggleDropDown(false);
  };

  return (
    <>
      <KYCHeader />
      <KYCRoot onClick={closeDropDown}>
        {(step < 4 || step === 6) && <H3>KYC verification</H3>}
        {step === 1 && <KYCFirstStep />}
        {step === 2 && <KYCSecondStep />}
        {step === 3 && <KYCThirdStep />}
        {step === 4 && <KYCInReview />}
        {step === 5 && <KYCRejected reset={resetSteps} />}
        {step === 6 && <KYCThirdStep approved={true} />}
      </KYCRoot>
      <Footer />
    </>
  );
};

const mapStateToProps = state => ({
  status: state.kyc.status,
  pageStep: state.kyc.step,
  isLoggedIn: state.registration.isLoggedIn
});

const mapDispatchToProps = dispatch => ({
  getUserData: token => dispatch(getUserDataAction(token)),
  toggleDropDown: isShown => dispatch(toggleDropDownAction(isShown))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KYC);
