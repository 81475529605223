import * as types from "../types";

const settingsState = {
  saveUserSettingsError: null,
  isUserSettingsSaved: false,
  userSettings: {},
  getUserSettingsError: null,
  getProfilePhotoError: null,
  saveUserSettingsAttempt: 0
};

const settingsReducer = (state = settingsState, { type, payload }) => {
  switch (type) {
    case types.SAVE_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        saveUserSettingsError: null,
        isUserSettingsSaved: true,
        saveUserSettingsAttempt: state.saveUserSettingsAttempt + 1
      };
    case types.SAVE_USER_SETTINGS_FAILED:
      return {
        ...state,
        saveUserSettingsError: payload,
        saveUserSettingsAttempt: state.saveUserSettingsAttempt + 1
      };
    case types.SET_IS_USER_SETTINGS_SAVED:
      return {
        ...state,
        isUserSettingsSaved: payload
      };
    case types.SET_USER_SETTINGS_DATA_SUCCESS:
      return {
        ...state,
        userSettings: payload,
        getUserSettingsError: null
      };
    case types.SET_USER_SETTINGS_DATA_FAILED:
      return {
        ...state,
        getUserSettingsError: payload
      };
    case types.UPLOAD_PROFILE_PHOTO_SUCCESS:
      return {
        ...state,
        getProfilePhotoError: null
      };
    case types.UPLOAD_PROFILE_PHOTO_FAILED:
      return {
        ...state,
        getProfilePhotoError: payload
      };
    case types.CLEAR_PROFILE_SETTINGS_ERRORS:
      return {
        ...state,
        getProfilePhotoError: null,
        getUserSettingsError: null
      };
    default:
      return state;
  }
};

export default settingsReducer;
