import styled from "styled-components";

// Breakpoints
import { breakpoints } from "../../theme/breakpoints";

export const Form = styled.div`
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  width: 100%;
  padding: 10px 20px;

  @media (max-width: ${breakpoints.md}px) {
    /* width: 60%; */
  }

  @media (max-width: ${breakpoints.sm}px) {
    /* width: 100%; */
    padding: 0;
    border: none;
  }
`;

export const Text = styled.p``;

export const Flex = styled.div`
  display: flex;
  width: 100%;

  justify-content: space-between;
  flex-wrap: wrap;

  ${({ justify }) => justify && `justify-content: ${justify};`}
  ${({ align }) => align && `align-items: ${align};`}
  ${({ width }) => width && `width: ${width};`}
  ${({ direction }) => direction && `flex-direction: ${direction};`}
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom};`}

  @media (max-width: ${breakpoints.md}px) {
    width: 100%;
    margin: 10px 0;
  }

  @media (max-width: ${breakpoints.sm}px) {
    margin: 0;
  }
`;

export const Input = styled.input`
  width: 48%;
  height: 50px;
  padding-left: 10px;
  color: #441eda;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  font-size: 16px;

  ${({ readOnly }) => readOnly && `background: #FAFAFA`};
  ${({ color }) => color && `color: ${color}`};
  ${({ width }) => width && `width: ${width};`}
  ${({ padding }) => padding && `padding: ${padding};`}

  @media (max-width: ${breakpoints.md}px) {
    width: 100%;
    margin: 5px 0;
  }
`;

export const Icon = styled.img`
  position: absolute;
  top: 15px;
  left: 15px;
  ${({ top }) => top && `top: ${top}`};

  @media (max-width: ${breakpoints.md}px) {
    top: 22px;
    ${({ top }) => top && `top: ${top}`};
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 48%;

  @media (max-width: ${breakpoints.md}px) {
    width: 100%;
    margin: 10px 0;
  }
`;

export const Span = styled.span`
  color: #12072c;

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 16px;
  }
`;

export const Button = styled.button`
  width: 24%;
  height: 40px;
  border: none;
  border-radius: 4px;
  margin: 30px 0 20px;
  font-size: 16px;
  cursor: pointer;
  background: ${({ disabled }) => (disabled ? "#987FF9" : "#441eda")};
  color: #fff;

  @media (max-width: ${breakpoints.md}px) {
    width: 100%;
    height: 50px;
  }
`;

export const Error = styled.div`
  color: #ba2612;
  margin: 30px 20px 20px 0;
  font-size: 16px;
  display: flex;
  align-items: center;

  @media (max-width: ${breakpoints.lg}px) {
    font-size: 14px;
    width: 100%;
    margin: 10px 0 0 0;
  }
`;
