import * as types from "../types";

const initialState = {
  btcExchangeAddress: null,
  ethExchangeAddress: null
};

export default function exchangeAddressReducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.FETCH_BTC_EXCHANGE_ADDRESS_SUCCESS:
      return {
        ...state,
        btcExchangeAddress: payload
      };
    case types.FETCH_ETH_EXCHANGE_ADDRESS_SUCCESS:
      return {
        ...state,
        ethExchangeAddress: payload
      };
    default:
      return state;
  }
}
