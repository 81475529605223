import styled, { css } from "styled-components";

// Breakpoints
import { breakpoints } from "../../theme/breakpoints";

export const Root = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid gray;
  color: #2c2a32;
  padding: 40px 0;

  @media (max-width: ${breakpoints.sm}px) {
    padding: 10px;
  }
`;

export const H3 = styled.h2`
  width: 50%;
  margin: 10px 0;

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 18px;
  }
`;

export const TabButton = styled.h2`
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 20px;
  opacity: ${({ active }) => (active ? 1 : 0.4)};
  ${process.env.REACT_APP_ENV !== "PRODUCTION" &&
    css`
      cursor: pointer;
      font-size: 16px;
    `}

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 14px;
  }
`;

export const Header = styled.div`
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;

  @media (max-width: ${breakpoints.md}px) {
    width: 60%;
    /* flex-direction: column; */
    /* align-items: flex-start; */
  }

  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
  }
`;

export const RatesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  /* flex-grow: 1; */
  font-size: 18px;
  font-weight: bold;
  width: 50%;
  margin: 10px 0;

  @media (max-width: ${breakpoints.md}px) {
    font-size: 16px;
    width: 100%;
  }

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 14px;
  }
`;

export const RatesLabel = styled.div`
  margin-right: 10px;
`;

export const RatesValues = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media (max-width: ${breakpoints.md}px) {
    /* flex-direction: row; */
  }
`;

export const RatesRow = styled.div`
  word-wrap: break-word;
  text-align: end;
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  margin: 10px 0;
  ${({ justify }) => justify && `justify-content: ${justify};`}

  ${({ align }) => align && `align-items: ${align};`}
  ${({ width }) => width && `width: ${width};`}
  ${({ direction }) => direction && `flex-direction: ${direction};`}
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom};`}

  @media (max-width: ${breakpoints.md}px) {
    width: 60%;
  }

  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
  }
`;

export const Text = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0;

  @media (max-width: ${breakpoints.md}px) {
    font-size: 16px;
  }

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 14px;
  }
`;

export const Info = styled.p`
  margin: 0;
  font-size: 14px;

  ${({ width }) => width && `width: ${width};`}
  ${({ align }) => align && `text-align: ${align};`}
  ${({ color }) => color && `color: ${color};`}
  ${({ size }) => size && `font-size: ${size};`}
  ${({ bold }) => bold && `font-weight: 600;`}
`;

export const InfoMessage = styled.div`
  font-family: "Roboto", sans-serif;
  color: #066b22;
  margin-bottom: 40px;
  position: relative;
  background: rgba(6, 107, 34, 0.1);
  border-radius: 5px;
  padding: 25px;

  @media (max-width: ${breakpoints.md}px) {
    font-size: 14px;
    margin: 0;
  }
`;

export const Icon = styled.img`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  width: 10px;
  height: 10px;
`;
