import * as types from "../types";

export const getExchangeRatesAction = () => ({
  type: types.GET_EXCHANGE_RATES_ASYNC
});

export const addSubscriberAction = email => ({
  type: types.ADD_SUBSCRIBER_ASYNC,
  payload: email
});

export const setLogInAction = isLoggedIn => ({
  type: types.SET_LOG_IN,
  payload: isLoggedIn
});

export const createUserAction = userData => ({
  type: types.CREATE_USER_ASYNC,
  payload: userData
});

export const checkUserRegistrationAction = email => ({
  type: types.CHECK_REGISTRATION_ASYNC,
  payload: email
});

export const verifyUserAction = data => ({
  type: types.VERIFY_USER_ASYNC,
  payload: data
});

export const checkCredentialsAction = credentials => ({
  type: types.CHECK_CREDENTIALS_ASYNC,
  payload: credentials
});

export const resendEmailAction = email => ({
  type: types.RESEND_EMAIL_ASYNC,
  payload: email
});

export const checkIsLoggedInAction = accessToken => ({
  type: types.CHECK_IS_LOGGED_IN_ASYNC,
  payload: accessToken
});

export const setUserEmailAction = email => ({
  type: types.SET_USER_EMAIL,
  payload: email
});

export const resendPasswordAction = email => ({
  type: types.RESEND_PASSWORD_ASYNC,
  payload: email
});

export const resetPasswordAction = data => ({
  type: types.CHANGE_PASSWORD_ASYNC,
  payload: data
});

export const resetIsRegisteredAction = () => ({
  type: types.RESET_IS_REGISTERED_ASYNC
});

export const setIsVerifiedAction = () => ({
  type: types.SET_IS_VERIFIED_ASYNC
});

export const getCountriesAction = () => ({
  type: types.GET_COUNTRIES_ASYNC
});

export const sendDataFirstStepAction = data => ({
  type: types.SEND_DATA_FIRST_STEP_ASYNC,
  payload: data
});

export const sendDataSecondStepAction = data => ({
  type: types.SEND_DATA_SECOND_STEP_ASYNC,
  payload: data
});

export const setUserIdAction = id => ({
  type: types.SET_USER_ID,
  payload: id
});

export const getUserDataAction = token => ({
  type: types.GET_USER_DATA_ASYNC,
  payload: token
});

export const setStepAction = step => ({
  type: types.SET_STEP,
  payload: step
});

export const getPhotosAction = id => ({
  type: types.GET_PHOTOS_ASYNC,
  payload: id
});

export const checkUserStatusAction = token => ({
  type: types.CHECK_USER_STATUS_ASYNC,
  payload: token
});

export const resetProfileAction = data => ({
  type: types.RESET_PROFILE_ASYNC,
  payload: data
});
export const setIsShownAction = () => ({
  type: types.SET_IS_SHOWN
});

export const submitProfileAction = data => ({
  type: types.SUBMIT_PROFILE_ASYNC,
  payload: data
});

export const setResetTokenAction = token => ({
  type: types.RESET_TOKEN,
  payload: token
});

export const toggleDropDownAction = isShown => ({
  type: types.TOGGLE_DROPDOWN,
  payload: isShown
});

export const saveUserSettingsAction = settings => ({
  type: types.SAVE_USER_SETTINGS_ASYNC,
  payload: settings
});

export const uploadProfilePhotoAction = photo => ({
  type: types.UPLOAD_PROFILE_PHOTO_ASYNC,
  payload: photo
});

export const setIsUserSettingsSavedAction = isSaved => ({
  type: types.SET_IS_USER_SETTINGS_SAVED,
  payload: isSaved
});

export const getUserSettingsAction = token => ({
  type: types.SET_USER_SETTINGS_DATA_ASYNC,
  payload: token
});

export const clearErrorsAction = () => ({
  type: types.CLEAR_PROFILE_SETTINGS_ERRORS
});
