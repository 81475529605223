import React from "react";
import { Pagination, PaginationLink } from "./styles";

export default function Paginator({ currentPage, pagesCount, onSelectPage }) {
  const selectPage = i => {
    if (i >= 0 && i < pagesCount) {
      onSelectPage(i);
    }
  };
  const selectFirstPage = () => selectPage(0);
  const selectLastPage = () => selectPage(pagesCount - 1);
  const selectPrevPage = () => selectPage(Math.max(currentPage - 1, 0));
  const selectNextPage = () => selectPage(Math.min(currentPage + 1, pagesCount - 1));
  const pagesOffset = Math.max(0, currentPage - 2);
  const pagesSlice = Array.from({ length: 5 }, (_, i) => pagesOffset + i);
  return (
    <Pagination aria-label="Page navigation example">
      <PaginationLink first href="#" disabled={currentPage === 0} onClick={selectFirstPage}>
        {"«"}
      </PaginationLink>
      <PaginationLink previous href="#" disabled={currentPage === 0} onClick={selectPrevPage}>
        {"‹"}
      </PaginationLink>
      {pagesSlice.map(i => (
        <PaginationLink
          key={i}
          href="#"
          active={currentPage === i}
          disabled={i >= pagesCount}
          onClick={() => selectPage(i)}
        >
          {i + 1}
        </PaginationLink>
      ))}
      <PaginationLink next href="#" disabled={currentPage === pagesCount - 1} onClick={selectNextPage}>
        {"›"}
      </PaginationLink>
      <PaginationLink last href="#" disabled={currentPage === pagesCount - 1} onClick={selectLastPage}>
        {"»"}
      </PaginationLink>
    </Pagination>
  );
}
