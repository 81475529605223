import React from "react";

// Img
import siteLogoImg from "./site-logo.svg";

// Styles
import { Root, LogoImg, ImageContainer, Loader, OpacityContainer } from "./styles";

const PageLoader = () => {
  return (
    <Root>
      <OpacityContainer>
        <Loader />
        <ImageContainer>
          <LogoImg src={siteLogoImg} alt="logo" />
        </ImageContainer>
      </OpacityContainer>
    </Root>
  );
};

export default PageLoader;
