import styled from "styled-components";

// Breakpoints
import { breakpoints } from "../../theme/breakpoints";

export const Root = styled.div`
  width: 40%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  @media (max-width: ${breakpoints.lg}px) {
    width: 50%;
  }

  @media (max-width: ${breakpoints.md}px) {
    width: 76%;
  }

  @media (max-width: ${breakpoints.sm}px) {
    display: none;
  }
`;

export const Outer = styled.div`
  border-radius: 50%;
  border: 1px solid #dbdbdb;
  width: 23px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ active }) =>
    active && `box-shadow: 0px 1px 2px rgba(139, 139, 139, 0.3);`}
  ${({ done }) => done && `background: #441eda;border: 1px solid #441eda;`}
`;

export const Inner = styled.div`
  border-radius: 50%;
  background: #441eda;
  width: 11px;
  height: 11px;
`;

export const Line = styled.div`
  height: 2px;
  background: #dbdbdb;
  width: 45%;

  ${({ active }) => active && `background: #441eda;`}

  media (max-width: ${breakpoints.md}px) {
    width: 43%;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  width: 40%;
  justify-content: space-between;

  @media (max-width: ${breakpoints.lg}px) {
    width: 50%;
  }

  @media (max-width: ${breakpoints.md}px) {
    width: 76%;
  }
  @media (max-width: ${breakpoints.sm}px) {
    display: none;
  }
`;

export const Text = styled.p`
  font-family: "Roboto", sans-serif;
  margin: 20px 0;

  &:nth-child(2) {
    margin-right: 90px;
  }
`;
