import React from "react";
import { Link } from "react-router-dom";

// Styles
import { Root, H2, Button, Text } from "./styles";

const styles = {
  textDecoration: "none",
  color: "#fff",
  cursor: "pointer"
};

const PageNotFound = () => {
  return (
    <>
      <Root>
        <H2>Oops!</H2>
        <Text>Something went wrong.</Text>
        <Text>It looks like this page doesn't exist.</Text>

        <Button data-test="main">
          <Link to="/" style={styles}>
            Go back
          </Link>
        </Button>
      </Root>
      )}
    </>
  );
};

export default PageNotFound;
