// Absolute imports
import styled from "styled-components/macro";

// Theme
import { breakpoints } from "../../../theme/breakpoints";

export const Root = styled.section`
  min-height: 74vh;
  display: flex;
  overflow: hidden;

  background: linear-gradient(to right, rgba(244, 244, 244, 0.7), #ccc);
  color: #12072c;

  // background: linear-gradient(
  //     44.07deg,
  //     rgba(235, 52, 166, 0.3) 0%,
  //     rgba(235, 52, 166, 0) 100%
  //   ),
  //   linear-gradient(
  //     135.93deg,
  //     rgba(68, 30, 218, 0.7) 0%,
  //     rgba(8, 2, 19, 0.7) 0.01%,
  //     rgba(24, 10, 59, 0.7) 100%
  //   ),
  //   #441eda;
`;

export const Container = styled.section`
  margin: auto;
  min-height: 260px;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: ${breakpoints.md}px) {
    min-height: 280px;
  }

  @media (max-width: ${breakpoints.md}px) {
    min-height: 300px;
  }
`;
