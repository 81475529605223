import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import QRCode from "qrcode.react";
import moment from "moment";
import * as stringUtils from "../../utils/stringUtils";
import * as types from "../../redux/types";
import KYCHeader from "../KYCHeader";
import Footer from "../Footer";
import CopyIcon from "../CopyIcon";
import {
  Root,
  TransactionContainer,
  TransactionHeader,
  TransactionBody,
  Label,
  HR,
  Flex,
  H3,
  InputContainer,
  Icon,
  Input,
  QrContainer,
  Button,
  StatusContainer,
  Status,
  ExpireContainer,
  ExpireLabel,
  ExpireTime
} from "./styles";
import { useEffect } from "react";

const ExchangeComplete = ({
  hasTransaction,
  targetCurrency,
  targetAddress,
  price,
  status,
  secondsUntilExpire,
  info
}) => {
  const [expireCountdown, setExpireCountdown] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setExpireCountdown(s => s - 1);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  useEffect(() => {
    const lagDelta = Math.abs(expireCountdown - secondsUntilExpire);
    if (lagDelta > 10) {
      setExpireCountdown(secondsUntilExpire);
    }
  }, [secondsUntilExpire, setExpireCountdown]);

  const targetAddressRef = React.createRef();
  const [copied, setCopied] = useState(false);
  const copyText = () => {
    targetAddressRef.current.select();
    document.execCommand("copy");
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };

  const m = Math.max(0, Math.floor(expireCountdown / 60))
    .toString()
    .padStart(2, "0");
  const s = Math.max(0, Math.floor(expireCountdown % 60))
    .toString()
    .padStart(2, "0");
  return !hasTransaction ? (
    <Redirect to="/exchange" />
  ) : (
    <React.Fragment>
      <KYCHeader />
      <Root>
        <Flex>
          <H3>Complete Exchange</H3>
          <StatusContainer>
            Status: <Status>{stringUtils.capitalize(status)}</Status>
          </StatusContainer>
        </Flex>
        <ExpireContainer>
          <ExpireLabel>Your times ends in</ExpireLabel>
          <ExpireTime>
            {m} : {s}
          </ExpireTime>
        </ExpireContainer>
        <TransactionContainer>
          <TransactionHeader>
            Pay: {price} {targetCurrency.toUpperCase()}
          </TransactionHeader>
          <HR />
          <TransactionBody>
            <Label>To</Label>
            <InputContainer>
              <Input
                readOnly
                type="text"
                data-test="exchange-complete-target-address"
                placeholder="Please enter your public key"
                value={targetAddress}
                name="targetAddress"
                ref={targetAddressRef}
              />
              <Icon onClick={copyText} data-test="copy-icon">
                <CopyIcon active={copied} />
              </Icon>
            </InputContainer>
            <QrContainer>
              <QRCode value={targetAddress} size={144} />
              {info}
            </QrContainer>
          </TransactionBody>
        </TransactionContainer>
        <Button>
          <Link to="/">Home page</Link>
        </Button>
      </Root>
      <Footer />
    </React.Fragment>
  );
};

const btcInfo = (
  <div>
    <p>
      To complete transaction please send exact amount of BTC to given address above. Please note that you should pay
      fee for transaction.
    </p>
    <p>If you send less amount - we will sell you less EEX coins according to current Exchange rate.</p>
    <p>We are waiting for your transaction during 15 minutes, after that order will be cancelled.</p>
  </div>
);

const ethInfo = (
  <div>
    <p>
      To complete transaction please send exact amount of ETH to given address above. Please note that you should pay
      fee for transaction.
    </p>
    <p>If you send less amount - we will sell you less EEX coins according to current Exchange rate.</p>
    <p>
      We will track transactions only from ETH wallet listed in your profile. You will receive your EEX coins on the
      wallet listed in your profile. Please make sure you are sending your payment from your wallet, otherwise it will
      be ignored.
    </p>
    <p>We are waiting for your transaction during 15 minutes, after that order will be cancelled.</p>
  </div>
);

const mapStateToProps = state => {
  if (!state.eexPurchase.currentTransaction) {
    return {
      hasTransaction: false
    };
  } else {
    const targetCurrency = state.eexPurchase.currentTransaction.targetCurrency;
    return {
      hasTransaction: true,
      targetCurrency,
      targetAddress:
        {
          [types.CURRENCIES.BTC]: state.exchangeAddress.btcExchangeAddress,
          [types.CURRENCIES.ETH]: state.exchangeAddress.ethExchangeAddress
        }[targetCurrency] || "",
      price: state.eexPurchase.currentTransaction.price,
      status: state.eexPurchase.currentTransaction.status,
      secondsUntilExpire:
        state.eexPurchase.currentTransaction &&
        moment.duration(moment(state.eexPurchase.currentTransaction.expireAt).diff(moment())).asSeconds(),
      info:
        {
          [types.CURRENCIES.BTC]: btcInfo,
          [types.CURRENCIES.ETH]: ethInfo
        }[targetCurrency] || ""
    };
  }
};

const mapDispatchToProps = _dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExchangeComplete);
