import styled from "styled-components";
import { Table } from "reactstrap";

// Breakpoints
import { breakpoints } from "../../theme/breakpoints";

export const Root = styled.section`
  display: flex;
  flex-direction: column;
  border: 1px solid #ececec;
  border-radius: 8px;
  width: 50%;

  @media (max-width: ${breakpoints.md}px) {
    width: 60%;
  }

  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
  }

  & td {
    word-wrap: break-word;
  }
`;

export const StyledTable = styled(Table)`
  table-layout: fixed;
  width: 100%;
  border-spacing: 0;
`;

export const TableHeader = styled.thead`
  background: #f4f4f4;
  color: #494949;

  & > tr {
    height: 40px;
  }

  & > tr > th {
    font-weight: normal;
  }

  @media (max-width: ${breakpoints.md}px) {
    font-size: 14px;
  }

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 12px;
    padding: 0 5px;
  }
`;

export const HeaderRow = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  background: #f4f4f4;
  color: #494949;
  padding: 0 10px;
  border-radius: 8px 8px 0 0;

  @media (max-width: ${breakpoints.md}px) {
    font-size: 14px;
  }

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 12px;
    padding: 0 5px;
  }
`;

export const Item = styled.div`
  @media (max-width: ${breakpoints.sm}px) {
    font-size: 12px;
  }
`;

export const Row = styled.tr`
  height: 60px;
  text-align: center;
  color: #494949;
  padding: 0 10px;
  border-bottom: 1px solid #ececec;

  & td {
    border-bottom: 1px solid #ececec;
  }

  @media (max-width: ${breakpoints.md}px) {
    font-size: 12px;
  }
  @media (max-width: ${breakpoints.sm}px) {
    padding: 0 5px;
  }
`;

export const NoOrdersContainer = styled.div`
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
