// Absolute
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import uuid from "uuid/v4";
import { FormattedMessage } from "react-intl";

// Styles
import {
  Text,
  Form,
  Info,
  List,
  Item,
  InfoArrow,
  Flex,
  Photo,
  ButtonsBlock,
  Button,
  Error,
  H5,
  H4,
  FileInput,
  Img,
  Icon
} from "./styles";

// Components
import Steps from "../Steps";
import Loading from "../Loading";

// Images
import cloud from "./cloud.png";
import infoArrowDown from "./info-arrow-down.svg";
import infoArrowUp from "./info-arrow-up.svg";

// Action creators
import {
  sendDataSecondStepAction,
  getUserDataAction
} from "../../redux/actionCreators";

const KYCSecondStep = ({
  sendDataSecondStep,
  id,
  docPhotoErrors,
  profilePhotoErrors,
  generalErrors,
  isPhotosLoaded,
  getUserData
}) => {
  const [
    {
      isInfoOpened,
      passportPhotoErrors,
      userWithPassportPhotoErrors,
      isLoading,
      passportPhotoBg,
      userWithPassportPhotoBg,
      passportPhoto,
      userWithPassportPhoto,
      generalPhotoErrors
    },
    setState
  ] = useState({
    isInfoOpened: false,
    passportPhotoErrors: [],
    userWithPassportPhotoErrors: [],
    isLoading: false,
    passportPhotoBg: null,
    userWithPassportPhotoBg: null,
    passportPhoto: null,
    userWithPassportPhoto: null,
    generalPhotoErrors: []
  });

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      getUserData(accessToken);
    }
  }, []);

  useEffect(() => {
    if (docPhotoErrors || profilePhotoErrors || generalErrors) {
      setState(values => ({
        ...values,
        isLoading: false,
        passportPhotoErrors: docPhotoErrors,
        userWithPassportPhotoErrors: profilePhotoErrors,
        generalPhotoErrors: generalErrors
      }));
    } else {
      setState(values => ({
        ...values,
        isLoading: false,
        passportPhotoErrors: [],
        userWithPassportPhotoErrors: [],
        generalPhotoErrors: []
      }));
    }
  }, [docPhotoErrors, profilePhotoErrors, generalErrors]);

  useEffect(() => {
    if (isPhotosLoaded) {
      const accessToken = localStorage.getItem("accessToken");
      if (accessToken) {
        getUserData(accessToken);
      }
    }
  }, [isPhotosLoaded]);

  const toggleInfo = () => {
    setState(values => ({
      ...values,
      isInfoOpened: !values.isInfoOpened
    }));
  };

  const loadPhoto = (file, name) => {
    if (!file) return;

    if (name === "passport") {
      setState(values => ({
        ...values,
        passportPhoto: file,
        passportPhotoBg: URL.createObjectURL(file)
      }));
    }

    if (name === "userWithPassport") {
      setState(values => ({
        ...values,
        userWithPassportPhoto: file,
        userWithPassportPhotoBg: URL.createObjectURL(file)
      }));
    }
  };

  const submit = () => {
    const passportPhotoError = !passportPhoto
      ? "Passport photo is required."
      : null;

    const userWithPassportPhotoError = !userWithPassportPhoto
      ? "User photo with passport is required."
      : null;

    const generalErrors = [];

    if (passportPhotoError) {
      generalErrors.push(passportPhotoError);
    }
    if (userWithPassportPhotoError) {
      generalErrors.push(userWithPassportPhotoError);
    }

    if (passportPhotoError || userWithPassportPhotoError) {
      setState(values => ({
        ...values,
        generalPhotoErrors: generalErrors
      }));
    } else {
      setState(values => ({
        ...values,
        generalPhotoErrors: []
      }));

      sendDataSecondStep({
        passportPhoto,
        userWithPassportPhoto,
        id
      });

      setState(values => ({
        ...values,
        isLoading: true
      }));
    }
  };

  return (
    <>
      <Text>Step 2: Personal Documents</Text>
      <Steps step={2} />
      <Form>
        <Info isOpened={isInfoOpened}>
          {isInfoOpened ? (
            <>
              <strong>Important!</strong> You must provide:
              <p>
                1. Photo of the page of your Passport showing your personal
                details / or photo of the remaining side of your National ID
                showing your personal details.{" "}
              </p>
              <p>
                2. Selfie of you holding: the page of your Passport showing your
                personal details/ or your National ID with your face image and
                personal details.
              </p>
              <List>
                <Item>
                  Every word on the page must be legible, make sure your fingers
                  are not covering any text.
                </Item>
                <Item>
                  The content of the passport page showing details must be
                  readable.
                </Item>
                <Item>Photo must be clear and not blurry.</Item>
                <Item>
                  Photo must not be modified by any photo editing software.
                </Item>
                <Item>
                  Do not wear glasses, sunglasses, headphones or wireless
                  hands-free devices, a hat or head covering. If you wear a hat
                  or head covering for religious or medical purposes, please
                  make sure that your hat or head covering does not cast shadows
                  on your face.
                </Item>
                <Item>
                  Photo should be less than 40MB. JPG, JPEG, PNG format is
                  supported.
                </Item>
              </List>
            </>
          ) : (
            <>
              <strong>Important!</strong> You must provide:
            </>
          )}
          {isInfoOpened ? (
            <InfoArrow
              data-test="kyc-step2-arrow-up"
              onClick={toggleInfo}
              src={infoArrowUp}
              alt="arrow-up"
            />
          ) : (
            <InfoArrow
              onClick={toggleInfo}
              src={infoArrowDown}
              alt="arrow-down"
              data-test="kyc-step2-arrow-down"
            />
          )}
        </Info>
        <Flex>
          <Photo
            error={
              passportPhotoErrors ? Boolean(passportPhotoErrors.length) : null
            }
          >
            <Img opacity={isLoading ? "0.8" : null} src={passportPhotoBg} />
            {isLoading && <Loading size="lg" withFish={false} />}
            <FileInput
              onChange={event => {
                loadPhoto(event.target.files[0], event.target.name);
              }}
              type="file"
              name="passport"
              data-test="kyc-step2-load-document"
            />
            <Icon src={passportPhoto ? "" : cloud} />
            {!passportPhoto && (
              <>
                <H4>Upload photo</H4>
                <H5>(Your document)</H5>
              </>
            )}
          </Photo>
          <Photo
            error={
              userWithPassportPhotoErrors
                ? Boolean(userWithPassportPhotoErrors.length)
                : null
            }
          >
            <Img
              opacity={isLoading ? "0.8" : null}
              src={userWithPassportPhotoBg}
              isLoading={isLoading}
            />

            {isLoading && <Loading size={"lg"} withFish={false} />}
            <FileInput
              onChange={event =>
                loadPhoto(event.target.files[0], event.target.name)
              }
              type="file"
              name="userWithPassport"
              data-test="kyc-step2-load-photo"
            />
            <Icon src={userWithPassportPhoto ? "" : cloud} />
            {!userWithPassportPhoto && (
              <>
                <H4>Upload photo</H4> <H5>(Photo with document)</H5>
              </>
            )}
          </Photo>
        </Flex>
        <Error>
          <List>
            {passportPhotoErrors &&
              passportPhotoErrors.map(error => (
                <Item data-test="kyc-step2-document-error" key={uuid()}>
                  {<FormattedMessage id={error} />}
                </Item>
              ))}

            {userWithPassportPhotoErrors &&
              userWithPassportPhotoErrors.map(error => (
                <Item data-test="kyc-step2-photo-error" key={uuid()}>
                  {<FormattedMessage id={error} />}
                </Item>
              ))}
            {generalPhotoErrors &&
              generalPhotoErrors.map(error => (
                <Item data-test="kyc-step2-general-error" key={uuid()}>
                  {error}
                </Item>
              ))}
          </List>
        </Error>
      </Form>
      <ButtonsBlock>
        <Button
          onClick={!isLoading ? submit : () => {}}
          theme={isLoading ? "lightViolet" : "main"}
          data-test="kyc-step2-next"
        >
          Next
        </Button>
      </ButtonsBlock>
    </>
  );
};

const mapStateToProps = state => ({
  id: state.kyc.userData.id,
  docPhotoErrors: state.kyc.docPhotoErrors,
  profilePhotoErrors: state.kyc.profilePhotoErrors,
  generalErrors: state.kyc.generalErrors,
  isPhotosLoaded: state.kyc.isPhotosLoaded
});

const mapDispatchToProps = dispatch => ({
  sendDataSecondStep: data => dispatch(sendDataSecondStepAction(data)),
  getUserData: token => dispatch(getUserDataAction(token))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KYCSecondStep);
