import * as types from "../types";
import { genericActionCreator } from "../../utils/reduxUtils";

export const fetchBtcExchangeAddress = genericActionCreator(types.FETCH_BTC_EXCHANGE_ADDRESS);
export const fetchBtcExchangeAddressSuccess = genericActionCreator(types.FETCH_BTC_EXCHANGE_ADDRESS_SUCCESS);
export const fetchBtcExchangeAddressFailure = genericActionCreator(types.FETCH_BTC_EXCHANGE_ADDRESS_FAILURE);

export const fetchEthExchangeAddress = genericActionCreator(types.FETCH_ETH_EXCHANGE_ADDRESS);
export const fetchEthExchangeAddressSuccess = genericActionCreator(types.FETCH_ETH_EXCHANGE_ADDRESS_SUCCESS);
export const fetchEthExchangeAddressFailure = genericActionCreator(types.FETCH_ETH_EXCHANGE_ADDRESS_FAILURE);
