// Absolute imports
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// Styles
import { Root, H2, Text, Button, Line, Span } from "./styles";

// Action creators
import { setIsShownAction } from "../../redux/actionCreators";

const KYCInReview = ({ isShownFirstTime, setIsShown }) => {
  const styles = {
    textDecoration: "none",
    color: "#fff",
    cursor: "pointer",
    position: "absolute",
    top: "0",
    right: "0",
    left: "0",
    bottom: "0",
    paddingTop: "10px"
  };

  const setPageIsShown = () => {
    setIsShown();
    localStorage.setItem("isShown", "0");
  };

  const email = localStorage.getItem("userEmail");

  return (
    <Root>
      {isShownFirstTime ? (
        <>
          <H2>Thank you for KYC verification!</H2>
          <Line />
          <Text>
            We are doing our best to check your application as soon as possible!{" "}
          </Text>
          <Text>
            We will inform you via your email address which you provided during
            registration: <Span>{email}</Span>
          </Text>
          <Button onClick={setPageIsShown}>
            <Link data-test="kyc-in-review-to-home" style={styles} to="/">
              Home page
            </Link>
          </Button>
        </>
      ) : (
        <>
          <H2>Your documents are now in review</H2>
          <Line />
          <Text>
            We are doing our best to check your application as soon as possible!
          </Text>
          <Text>
            We will inform you via your email address which you provided during
            registration: <Span>{email}</Span>
          </Text>
          <Button onClick={setPageIsShown}>
            <Link data-test="kyc-in-review-to-home" style={styles} to="/">
              Home page
            </Link>
          </Button>
        </>
      )}
    </Root>
  );
};

const mapStateToProps = state => ({
  isShownFirstTime: state.kyc.isShownFirstTime
});

const mapDispatchToProps = dispatch => ({
  setIsShown: () => dispatch(setIsShownAction())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KYCInReview);
