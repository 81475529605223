// Absolute
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

// Styles
import {
  Root,
  Flex,
  Header,
  H3,
  Text,
  Info,
  InfoMessage,
  Icon,
  RatesContainer,
  RatesLabel,
  RatesValues,
  RatesRow,
  TabButton
} from "./styles";

// Components
import KYCHeader from "../KYCHeader";
import ExchangeForm from "../ExchangeForm";
import ExchangeHistory from "../ExchangeHistory";
import Footer from "../Footer";

// Images
import crossIcon from "./img/cross.svg";

// Action creators
import { toggleDropDownAction } from "../../redux/actionCreators";
// import { fetchUserLimit, hideFirstTimeEexPurchaseInfo } from "../../redux/actionCreators/eexPurchaseActions";
import * as actions from "../../redux/actionCreators/eexPurchaseActions";

const Exchange = ({
  isFirstTimeInfoShown,
  isLoggedIn,
  isOrderPending,
  btcPriceInEex,
  // ethPriceInEex,
  limit,
  updateLimits,
  toggleDropDown,
  onHideFirstTimeInfo,
  userPagesCount,
  userPage,
  userRecords,
  globalPagesCount,
  globalPage,
  globalRecords,
  onSelectUserTransactionsPage,
  onSelectGlobalTransactionsPage
}) => {
  const [activeTab, setActiveTab] = useState("user-transactions");
  useEffect(() => {
    if (isLoggedIn) {
      updateLimits();
    }
  }, [isLoggedIn, updateLimits]);
  const closeDropDown = event => {
    toggleDropDown(false);
  };
  return (
    <React.Fragment>
      <KYCHeader />
      <Root onClick={closeDropDown}>
        <Header>
          <H3>Exchange</H3>
          <RatesContainer>
            {/* <RatesLabel>Rates:</RatesLabel> */}
            <RatesValues>
              <RatesRow>1 BTC = {btcPriceInEex} EEX</RatesRow>
              {/* <RatesRow>1 ETH = {ethPriceInEex} EEX</RatesRow> */}
            </RatesValues>
          </RatesContainer>
        </Header>
        <Flex>
          {isLoggedIn ? (
            <Info>Today you can buy maximum {limit} EEX coins</Info>
          ) : (
            <Info>Please log in to see your limit</Info>
          )}
        </Flex>
        {isFirstTimeInfoShown && (
          <Flex>
            <InfoMessage>
              Important! Before the purchase you must fill your profile with corresponding public keys: both EEX and
              other pair in order to do purchase. Also you must pass KYC in order to do purchase.
              <Icon src={crossIcon} alt="cross-icon" onClick={onHideFirstTimeInfo} />
            </InfoMessage>
          </Flex>
        )}
        <Flex>
          <ExchangeForm />
        </Flex>
        <Flex justify="flex-start">
          <TabButton active={activeTab === "user-transactions"} onClick={() => setActiveTab("user-transactions")}>
            Exchange history
          </TabButton>
          {process.env.REACT_APP_ENV !== "PRODUCTION" && (
            <TabButton active={activeTab === "global-transactions"} onClick={() => setActiveTab("global-transactions")}>
              All Exchange History
            </TabButton>
          )}
        </Flex>

        {activeTab === "user-transactions" && (
          <ExchangeHistory
            pagesCount={userPagesCount}
            page={userPage}
            records={userRecords}
            onSelectPage={onSelectUserTransactionsPage}
          />
        )}

        {activeTab === "global-transactions" && (
          <ExchangeHistory
            pagesCount={globalPagesCount}
            page={globalPage}
            records={globalRecords}
            onSelectPage={onSelectGlobalTransactionsPage}
          />
        )}

        {isOrderPending && <Redirect to="/exchange-complete" />}
      </Root>
      <Footer />
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  isFirstTimeInfoShown: state.eexPurchase.isFirstTimeInfoShown,
  isLoggedIn: state.registration.isLoggedIn,
  isOrderPending: state.eexPurchase.currentTransaction && state.eexPurchase.currentTransaction.status !== "processing",
  btcPriceInEex: state.generalRates.btcPriceInEex,
  ethPriceInEex: state.generalRates.ethPriceInEex,
  limit: state.eexPurchase.limit,

  userPagesCount: state.eexPurchase.userTransactions.pagesCount,
  userPage: state.eexPurchase.userTransactions.page,
  userRecords: state.eexPurchase.userTransactions.records,

  globalPagesCount: state.eexPurchase.globalTransactions.pagesCount,
  globalPage: state.eexPurchase.globalTransactions.page,
  globalRecords: state.eexPurchase.globalTransactions.records
});

const mapDispatchToProps = dispatch => ({
  updateLimits: () => dispatch(actions.fetchUserLimit()),
  toggleDropDown: isShown => dispatch(toggleDropDownAction(isShown)),
  onHideFirstTimeInfo: () => dispatch(actions.hideFirstTimeEexPurchaseInfo()),

  onSelectUserTransactionsPage: page => dispatch(actions.fetchUserTransactions({ page })),
  onSelectGlobalTransactionsPage: page => dispatch(actions.fetchGlobalTransactions({ page }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Exchange);
