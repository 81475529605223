import styled from "styled-components/macro";

// breakpoints
import { breakpoints } from "../../theme/breakpoints";

export const Root = styled.footer`
  background: #12072c;
  display: flex;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  padding: 0 30px;

  @media (max-width: ${breakpoints.sm}px) {
    padding: 20px;
  }
`;

export const Top = styled.div`
  min-height: 16vh;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${breakpoints.md}px) {
    flex-direction: column;
  }
`;

export const Bottom = styled.div`
  min-height: 10vh;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${breakpoints.md}px) {
    flex-direction: column;
    padding: 20px 0;
  }
`;

export const FilesWrapper = styled.div`
  display: flex;
  width: 26%;
  font-size: 14px;

  & > a {
    padding: 0 20px;
  }

  & > a:first-child {
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    padding: 0 20px 0 0;
  }

  @media (max-width: ${breakpoints.md}px) {
    width: 100%;
    justify-content: center;
    margin: 10px 0 25px 0;

    & > a:first-child {
      border-right: 1px solid rgba(255, 255, 255, 0.5);
      padding: 0 20px;
    }
  }
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  margin-right: 40px;
  padding-right: 40px;
  border-right: 1px solid rgba(255, 255, 255, 0.5);

  @media (max-width: ${breakpoints.md}px) {
    margin: 20px 0;
    padding: 0;
    border-right: none;
  }
`;

export const LogoImg = styled.img`
  width: 49px;
  height: 49px;
  object-fit: contain;
`;

export const LogoDescription = styled.span`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: -0.47px;
  margin-left: 12px;
  color: #fff;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${breakpoints.md}px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const Icons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 140px;

  @media (max-width: ${breakpoints.md}px) {
    padding: 20px 0;
  }
`;

export const Span = styled.span`
  color: rgba(255, 255, 255, 0.5);
  margin: 0 5px;

  ${({ bold }) => bold && `font-weight: 600; color: #fff;`}


  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
  }
`;

export const Img = styled.img`
  cursor: pointer;
`;
