import styled from "styled-components";
import { breakpoints } from "../../theme/breakpoints";

export const ArrowDown = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #a8a5b4;
  cursor: pointer;
  position: absolute;
  right: 15px;
`;

export const ArrowUp = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #a8a5b4;
  cursor: pointer;
  position: absolute;
  right: 15px;
`;

export const DropDownButton = styled.div`
  border: 1px solid green;

  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 40px;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  font-size: 16px;
  background: #fff;
  position: relative;
  cursor: pointer;

  ${({ disabled }) => disabled && `background-color: #FAFAFA;`}
`;

export const DropDownContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: top;
  background: #fff;
  color: #333;
  position: absolute;
  padding: 0 40px;
  z-index: 3;
  top: 50px;
  left: 0;
  border-radius: 4px;
  border: 1px solid #dbdbdb;
`;

export const DropDownItem = styled.div`
  height: 50px;
  line-height: 50px;
  cursor: pointer;
`;

export const Icon = styled.img`
  position: absolute;
  top: 15px;
  left: 15px;
  ${({ top }) => top && `top: ${top}`};

  @media (max-width: ${breakpoints.md}px) {
    top: 22px;
    ${({ top }) => top && `top: ${top}`};
  }
`;

export const Span = styled.span`
  color: #12072c;

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 16px;
  }
`;
