import * as types from "../types";
import { genericActionCreator } from "../../utils/reduxUtils";

export const hideFirstTimeEexPurchaseInfo = genericActionCreator(types.HIDE_FIRST_TIME_EEX_PURCHASE_INFO);

export const fetchEexPriceInCurrency = genericActionCreator(types.FETCH_EEX_PRICE_IN_CURRENCY);
export const fetchEexPriceInCurrencySuccess = genericActionCreator(types.FETCH_EEX_PRICE_IN_CURRENCY_SUCCESS);
export const fetchEexPriceInCurrencyFailure = genericActionCreator(types.FETCH_EEX_PRICE_IN_CURRENCY_FAILURE);

export const fetchUserLimit = genericActionCreator(types.FETCH_USER_LIMIT);
export const fetchUserLimitSuccess = genericActionCreator(types.FETCH_USER_LIMIT_SUCCESS);
export const fetchUserLimitFailure = genericActionCreator(types.FETCH_USER_LIMIT_FAILURE);

export const fetchUserTransactions = genericActionCreator(types.FETCH_USER_TRANSACTIONS);
export const fetchUserTransactionsSuccess = genericActionCreator(types.FETCH_USER_TRANSACTIONS_SUCCESS);
export const fetchUserTransactionsFailure = genericActionCreator(types.FETCH_USER_TRANSACTIONS_FAILURE);

export const fetchGlobalTransactions = genericActionCreator(types.FETCH_GLOBAL_TRANSACTIONS);
export const fetchGlobalTransactionsSuccess = genericActionCreator(types.FETCH_GLOBAL_TRANSACTIONS_SUCCESS);
export const fetchGlobalTransactionsFailure = genericActionCreator(types.FETCH_GLOBAL_TRANSACTIONS_FAILURE);

export const fetchLastUserTransaction = genericActionCreator(types.FETCH_LAST_USER_TRANSACTION);
export const fetchLastUserTransactionSuccess = genericActionCreator(types.FETCH_LAST_USER_TRANSACTION_SUCCESS);
export const fetchLastUserTransactionFailure = genericActionCreator(types.FETCH_LAST_USER_TRANSACTION_FAILURE);

export const buyEex = genericActionCreator(types.BUY_EEX);
export const buyEexSuccess = genericActionCreator(types.BUY_EEX_SUCCESS);
export const buyEexFailure = genericActionCreator(types.BUY_EEX_FAILURE);
