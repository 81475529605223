// Absolute imports
import styled from "styled-components/macro";

// Theme
import { breakpoints } from "../../theme/breakpoints";

export const Root = styled.nav`
  margin: 0;
  padding: 0;
  display: none;

  @media (min-width: ${breakpoints.md}px) {
    display: flex;
    position: absolute;
    right: 20px;
    align-items: center;
  }
`;

export const Item = styled.li`
  list-style: none;
  margin-left: 30px;
  position: relative;

  ${({ margin }) => margin && `margin: ${margin};`}
`;

export const ArrowDown = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #fff;
  cursor: pointer;
`;

export const ArrowUp = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #fff;
  cursor: pointer;
`;

export const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
  background: rgba(255, 255, 255, 0);
  color: #fff;
  border-radius: 4px;
  height: 40px;
  width: 140px;
`;
