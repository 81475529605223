import styled from "styled-components";

import { breakpoints } from "../../theme/breakpoints";

export const Root = styled.header`
  width: 100%;
  min-height: 10vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to left, #261066, #3a1269, #471368, #59176c);
  color: #fff;
  padding: 0 20px;

  @media (max-width: ${breakpoints.sm}px) {
    padding: 10px 20px;
  }
`;

export const Logo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LogoImg = styled.img`
  width: 49px;
  margin-right: 10px;
  cursor: pointer;
`;

export const LogoDescription = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: -0.47px;
  margin-left: 12px;
  color: #fff;
  text-transform: uppercase;
`;

export const Menu = styled.div`
  display: flex;
  align-items: center;
`;

export const MobileMenu = styled.div`
  margin-left: 16px;
  position: relative;
  z-index: 15;

  @media (min-width: ${breakpoints.md}px) {
    display: none;
  }
`;
