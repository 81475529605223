import * as types from "../types";

const localeState = {
  lang: "en"
};

const localeReducer = (state = localeState, { type, payload }) => {
  switch (type) {
    default:
      return state;
  }
};

export default localeReducer;
