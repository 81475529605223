export const validatePassword = password => {
  const result = [];

  if (password.length < 8) {
    const index = result.indexOf("Min 8 characters");
    result.splice(index, 1);
  }
  if (!/[!@#$%&*_\^\._+-]/.test(password)) {
    const index = result.indexOf(
      "At least one special character (e.g. *, _, &)"
    );
    result.splice(index, 1);
  }
  if (!/[0-9]/.test(password)) {
    const index = result.indexOf("At least one number");
    result.splice(index, 1);
  }

  if (password.length >= 8) {
    result.push("Min 8 characters");
  }
  if (/[!@#$%&*_\^\._+-]/.test(password)) {
    result.push("At least one special character (e.g. *, _, &)");
  }
  if (/[0-9]/.test(password)) {
    result.push("At least one number");
  }

  return result;
};

export const validateFields = value => {
  if (!value.length) return "This field is required.";
  return null;
};

export const validatePassport = passport => {
  if (!passport.length) return "This field is required.";

  if (/[-!$%^&*()_+|~=`{}[:;<>?,.@#\]]/g.test(passport)) {
    return "Passport can contain latin or cyrillic letters and numbers";
  }

  return null;
};
