import styled from "styled-components";

// Breakpoints
import { breakpoints } from "../../theme/breakpoints";

const themes = {
  main: "background: #441eda; color: #fff;",
  disabled: "border: 1px solid #7B7A7F; color: 7B7A7F; background: #fff;",
  lightGray: "border: 1px solid #441eda; background: #fff; color: #441eda;",
  lightViolet: "border: 1px solid #a987e5; background: #a987e5; color: #fff;"
};

export const KYCRoot = styled.section`
  min-height: 64vh;
  color: #2c2a32;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 80px 0;

  @media (max-width: ${breakpoints.sm}px) {
    padding: 0;
  }
`;

export const H3 = styled.h2`
  width: 40%;
  margin: 0 0 20px 0;

  @media (max-width: ${breakpoints.lg}px) {
    width: 50%;
  }

  @media (max-width: ${breakpoints.md}px) {
    width: 76%;
  }

  @media (max-width: ${breakpoints.sm}px) {
    width: 90%;
    margin: 20px 0 0 0;
  }
`;

export const Form = styled.form`
  width: 40%;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 40px;

  @media (max-width: ${breakpoints.lg}px) {
    width: 50%;
  }

  @media (max-width: ${breakpoints.md}px) {
    width: 76%;
  }

  @media (max-width: ${breakpoints.sm}px) {
    width: 90%;
    padding: 0;
    border: none;
  }
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:first-child {
    margin-bottom: 20px;
  }

  @media (max-width: ${breakpoints.sm}px) {
    flex-direction: column;

    &:first-child {
      margin-bottom: 0px;
    }
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 48%;
  margin-bottom: 30px;
  position: relative;

  @media (max-width: ${breakpoints.lg}px) {
    margin-bottom: 36px;
  }

  @media (max-width: ${breakpoints.md}px) {
    margin-bottom: 40px;
  }

  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;

export const Label = styled.label`
  font-size: 16px;
`;

export const Input = styled.input`
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  font-size: 16px;
  height: 40px;
  margin-top: 10px;
  padding-left: 10px;
  ${({ error }) => error && `border: 2px solid #ba2612;`}
`;

export const Select = styled.select`
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  font-size: 16px;
  height: 40px;
  background: #fff;
  margin-top: 10px;
  padding-left: 10px;

  ${({ error }) => error && `border: 2px solid #ba2612;`}
`;

export const Option = styled.option`
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  font-size: 16px;
  height: 40px;
  background: #fff;
  padding-left: 10px;
`;

export const Button = styled.button`
  width: 24%;
  height: 40px;
  border: none;
  border-radius: 4px;
  margin: 0 10px;
  font-size: 16px;

  ${({ theme }) => theme && `${themes[theme]};`}

  ${({ notActive }) => notActive && `${themes["disabled"]};`}

  @media (max-width: ${breakpoints.sm}px) {
    width: 46%;
    margin: 0;
  }
`;

export const ButtonsBlock = styled.div`
  display: flex;
  justify-content: center;
  width: 30%;
  margin: 40px 0;

  @media (max-width: ${breakpoints.lg}px) {
    width: 36%;
  }

  @media (max-width: ${breakpoints.md}px) {
    width: 56%;
  }

  @media (max-width: ${breakpoints.sm}px) {
    width: 90%;
    margin: 20px 0 30px;
    justify-content: space-between;
  }
`;

export const Text = styled.p`
  margin: 0 0 20px 0;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  display: none;

  @media (max-width: ${breakpoints.sm}px) {
    width: 90%;
    margin: 20px 0;
    display: block;
  }
`;

export const Info = styled.div`
  font-family: "Roboto", sans-serif;
  color: #066b22;
  margin-bottom: 40px;
  position: relative;
  background: rgba(6, 107, 34, 0.1);
  border-radius: 5px;
  padding: 20px;
`;

export const InfoArrow = styled.img`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
`;

export const InfoText = styled.div`
  margin: 5px 10px;
`;

export const List = styled.ul`
  padding: 0;
  margin-left: 20px;
`;

export const Item = styled.li``;

export const Photo = styled.div`
  width: 44%;
  height: 160px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  border: 1px solid #dbdbdb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
  cursor: pointer;

  ${({ error }) => error && `border: 2px solid #ba2612;`}
  ${({ noCursor }) => noCursor && `cursor: auto;`}


  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
    ${({ width }) => width && `width: ${width};`}
  }
`;

export const Img = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
`;

export const FileInput = styled.input`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  cursor: pointer;
`;

export const Icon = styled.img`
  @media (max-width: ${breakpoints.lg}px) {
    ${({ iconWidth }) => iconWidth && `width: ${Number(iconWidth) + 40}px;`}
  }

  @media (max-width: ${breakpoints.md}px) {
    ${({ iconWidth }) => iconWidth && `width: ${Number(iconWidth) + 20}px;`}
  }

  @media (max-width: ${breakpoints.sm}px) {
    ${({ iconWidth }) => iconWidth && `width: ${iconWidth}px;`}
  }
`;

export const H4 = styled.h4`
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  margin: 10px 0;
`;

export const H5 = styled.h5`
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  margin: 0;
  color: #9c9c9c;
  text-align: center;
`;

export const Line = styled.div`
  height: 1px;
  width: 100%;
  background: #dbdbdb;
  margin: 40px 0;
`;

export const Error = styled.div`
  color: #ba2612;
  position: absolute;
  top: 80px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;

  @media (max-width: ${breakpoints.sm}px) {
    position: static;
    margin: 10px 0 0 0;
  }
`;

export const Step2Error = styled.div`
  color: #ba2612;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  margin: 10px 0;
  // text-align: center;
`;
