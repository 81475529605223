import * as types from "../types";

const kycState = {
  countries: [],
  getCountriesErrors: [],
  status: "",
  userId: null,
  firstStepVerificationErrors: [],
  isPhotosLoaded: false,
  docPhotoErrors: [],
  profilePhotoErrors: [],
  generalErrors: [],
  userData: {},
  getUserDataError: null,
  passportPhotoUrl: null,
  userWithPassportPhotoUrl: null,
  resetProfileError: null,
  step: 1,
  isShownFirstTime: localStorage.getItem("isShown") ? Boolean(Number(localStorage.getItem("isShown"))) : true,
  isDropDownShown: false,
  pageState: null
};

const kycReducer = (state = kycState, { type, payload }) => {
  switch (type) {
    case types.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: payload
      };
    case types.GET_COUNTRIES_FAILED:
      return {
        ...state,
        getCountriesErrors: payload
      };
    case types.SEND_DATA_FIRST_STEP_SUCCESS:
      return {
        ...state,
        status: payload.status,
        userId: payload.userId,
        step: payload.step
      };
    case types.SEND_DATA_FIRST_STEP_FAILED:
      return {
        ...state,
        firstStepVerificationErrors: payload
      };

    case types.SEND_DATA_SECOND_STEP_SUCCESS:
      return {
        ...state,
        isPhotosLoaded: true,
        docPhotoErrors: [],
        profilePhotoErrors: [],
        generalErrors: []
      };
    case types.SEND_DATA_SECOND_STEP_FAILED:
      return {
        ...state,
        isPhotosLoaded: false,
        docPhotoErrors: payload.docPhotoErrors,
        profilePhotoErrors: payload.profilePhotoErrors,
        generalErrors: payload.generalErrors
      };
    case types.SET_USER_ID:
      return {
        ...state,
        userId: payload
      };
    case types.GET_USER_DATA_SUCCESS:
      return {
        ...state,
        userData: {
          name: payload.first_name,
          surname: payload.last_name,
          passport: payload.doc_number,
          country: payload.country,
          id: payload.id
        },
        step: payload.step
      };
    case types.GET_USER_DATA_FAILED:
      return {
        ...state,
        getUserDataError: payload
      };
    case types.SET_STEP:
      return {
        ...state,
        step: payload
      };

    case types.GET_PHOTOS_SUCCESS:
      const { passportPhotoUrl, userWithPassportPhotoUrl } = payload;
      return {
        ...state,
        passportPhotoUrl,
        userWithPassportPhotoUrl
      };
    case types.CHECK_USER_STATUS_SUCCESS:
      return {
        ...state,
        status: payload.status
      };
    case types.RESET_PROFILE_SUCCESS:
      return {
        ...state,
        status: payload,
        isPhotosLoaded: false
      };
    case types.RESET_PROFILE_FAILED:
      return {
        ...state,
        resetProfileError: payload
      };
    case types.SET_IS_SHOWN:
      return {
        ...state,
        isShownFirstTime: false
      };
    case types.TOGGLE_DROPDOWN:
      return {
        ...state,
        isDropDownShown: payload
      };
    default:
      return state;
  }
};

export default kycReducer;
