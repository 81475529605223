import React from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";

// Components
import PageNotFound from "../PageNotFound";
import PageLoader from "../PageLoader";

const PrivateRoute = ({ component: Component, isLoggedIn, ...props }) => {
  return (
    <Route
      {...props}
      render={props =>
        isLoggedIn === true ? <Component {...props} /> : isLoggedIn === false ? <PageNotFound /> : <PageLoader />
      }
    />
  );
};

const mapStateToProps = state => ({
  isLoggedIn: state.registration.isLoggedIn
});

export default connect(mapStateToProps)(PrivateRoute);
