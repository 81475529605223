const messages = {
  en: {
    "recognition.photo_not_found": "Document photo wasn't recognized",
    "recognition.document_not_found": "Document wasn't recognized",
    "recognition.two_faces_not_found": "Photo not recognized.",
    "system.unhealthy": "Try again in a minute.",
    "profile.already_exist": "Profile already exists.",
    "user.not_verified": "Please confirm your email",
    "profile.document.invalid_format": "Invalid photo format",
    "upload.invalid_file_format": "File format is not valid",
    "profile.first_name.contains_digit": "Name shouldn't contain numbers.",
    "profile.last_name.contains_digit": "Last name shouldn't contain numbers."
  }
};

export default messages;
