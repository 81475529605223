import styled from "styled-components";

// Breakpoints
import { breakpoints } from "../../theme/breakpoints";

export const Form = styled.div`
  width: 40%;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 40px;

  @media (max-width: ${breakpoints.lg}px) {
    width: 50%;
  }

  @media (max-width: ${breakpoints.md}px) {
    width: 76%;
  }

  @media (max-width: ${breakpoints.sm}px) {
    width: 90%;
    padding: 0;
    border: none;
  }
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:first-child {
    margin-bottom: 20px;
  }

  @media (max-width: ${breakpoints.sm}px) {
    flex-direction: column;

    &:first-child {
      margin-bottom: 0px;
    }
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 48%;
  margin-bottom: 30px;
  position: relative;

  @media (max-width: ${breakpoints.lg}px) {
    margin-bottom: 36px;
  }

  @media (max-width: ${breakpoints.md}px) {
    margin-bottom: 40px;
  }

  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;

export const Label = styled.label`
  font-size: 16px;
`;

export const Input = styled.input`
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  font-size: 16px;
  height: 40px;
  margin-top: 10px;
  padding-left: 10px;
  ${({ error }) => error && `border: 2px solid #ba2612;`}
`;

export const Select = styled.select`
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  font-size: 16px;
  height: 40px;
  background: #fff;
  margin-top: 10px;
  padding-left: 10px;

  ${({ error }) => error && `border: 2px solid #ba2612;`}
`;

export const Option = styled.option`
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  font-size: 16px;
  height: 40px;
  background: #fff;
  padding-left: 10px;
`;

export const Button = styled.button`
  width: 26%;
  height: 40px;
  border: none;
  border-radius: 4px;
  margin: 0 10px;
  font-size: 16px;
  background: #441eda;
  color: #fff;
  cursor: pointer;

  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
    margin: 0;
  }
`;

export const ButtonsBlock = styled.div`
  display: flex;
  justify-content: center;
  width: 30%;
  margin: 40px 0;

  @media (max-width: ${breakpoints.lg}px) {
    width: 40%;
  }

  @media (max-width: ${breakpoints.md}px) {
    width: 60%;
  }

  @media (max-width: ${breakpoints.sm}px) {
    width: 90%;
    margin: 20px 0 30px;
  }
`;

export const Text = styled.p`
  margin: 0 0 20px 0;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  display: none;

  @media (max-width: ${breakpoints.sm}px) {
    width: 90%;
    margin: 20px 0;
    display: block;
  }
`;

export const Error = styled.div`
  color: #ba2612;
  position: absolute;
  top: 80px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;

  @media (max-width: ${breakpoints.sm}px) {
    position: static;
    margin: 10px 0 0 0;
  }
`;

export const ErrorList = styled.ul`
  padding-left: 20px;
  margin: 0;
  color: #ba2612;
  position: absolute;
  top: 80px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;

  ${({ position }) => position && `position: ${position};`}


  @media (max-width: ${breakpoints.sm}px) {
    position: static;
    margin: 10px 0 0 0;
  }
`;

export const ErrorItem = styled.li``;
