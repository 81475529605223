// Absolute imports
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// Styles
import { Root, Header, DropDownItem, Span, HeaderItem } from "./styles";

// Components
import ProfileIcon from "../ProfileIcon";

// Action creators
import {
  setLogInAction,
  setIsVerifiedAction,
  setIsShownAction,
  toggleDropDownAction,
  clearErrorsAction
} from "../../redux/actionCreators";

const DropDown = ({ email, status, setLogIn, setIsVerified, toggleDropDown, clearErrors }) => {
  const logOut = () => {
    // localStorage.clear();
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userEmail");
    setLogIn(false);
    setIsVerified();
    clearErrors();
  };

  const styles = {
    textDecoration: "none",
    color: "#2c2a32",
    cursor: "pointer",
    display: "block"
  };

  return (
    <Root>
      <Header>
        <HeaderItem>
          <ProfileIcon color="dark" size="sm" />
        </HeaderItem>
        <HeaderItem>{email}</HeaderItem>
      </Header>
      <DropDownItem
        onClick={() => {
          toggleDropDown(false);
        }}
      >
        <Link style={styles} to="/settings">
          Settings
        </Link>
      </DropDownItem>
      <DropDownItem>
        <Link
          data-test="kyc"
          style={styles}
          to="/kyc"
          onClick={() => {
            toggleDropDown(false);
          }}
        >
          Verification KYC <Span color={status}>({status})</Span>
        </Link>
      </DropDownItem>
      <DropDownItem onClick={logOut}>
        <Link data-test="log-out" style={styles} to="/" onClick={() => toggleDropDown(false)}>
          Sign out
        </Link>
      </DropDownItem>
    </Root>
  );
};

const mapDispatchToProps = dispatch => ({
  setLogIn: isLoggedIn => dispatch(setLogInAction(isLoggedIn)),
  setIsVerified: () => dispatch(setIsVerifiedAction()),
  setIsShown: () => dispatch(setIsShownAction()),
  toggleDropDown: isShown => dispatch(toggleDropDownAction(isShown)),
  clearErrors: () => dispatch(clearErrorsAction())
});

export default connect(
  null,
  mapDispatchToProps
)(DropDown);
