import styled, { css } from "styled-components";

export const Pagination = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 5px;
  margin: 20px 0;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
`;

export const PaginationLink = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  cursor: pointer;
  border: 1px solid #e4e3e8;
  user-select: none;

  ${({ disabled }) => disabled && css`
    color: #E5E5E5;
  `}
  ${({ active }) => active && css`
    background: #441eda;
    color: #fff;
  `}
`;
