import React from "react";

// Styles
import {
  Root,
  Top,
  Bottom,
  FilesWrapper,
  Logo,
  LogoImg,
  LogoDescription,
  Flex,
  Icons,
  Span,
  Img
} from "./styles";
import { Text } from "../UI/Typography";
import { AnchorLink } from "../UI/Typography";

// Img
import siteLogoImg from "./img/site-logo.svg";
import twitter from "./img/twitter.svg";
import facebook from "./img/facebook.svg";
import youtube from "./img/youtube.svg";
import telegram from "./img/telegram.svg";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Root>
      <Top>
        <Flex>
          <Logo>
            <LogoImg src={siteLogoImg} alt="Ethereum express coin" />
            <LogoDescription>
              {" "}
              ETHEREUM
              <br />
              EXPRESS
              <br />
              COIN
              <br />
            </LogoDescription>
          </Logo>
          <Text
            color="secondary"
            size="s"
            margin="0px 0 10px 0"
            dFlex={true}
            flexWrap={true}
          >
            <Span>Please contact us: </Span>
            <Span bold>support@ethereum-express.com</Span>
          </Text>
        </Flex>
        <Flex>
          <Text color="secondary" size="s" margin="0 20px">
            Follow Us
          </Text>
          <Icons>
            <AnchorLink
              href={`https://twitter.com/ethereumexpress`}
              target="_blank"
              data-test="twitter-icon"
            >
              <Img src={twitter} alt="twitter icon" />
            </AnchorLink>
            <AnchorLink
              href={`https://www.facebook.com/ethereumexpressfoundation`}
              target="_blank"
              data-test="fb-icon"
            >
              <Img src={facebook} alt="facebook icon" />
            </AnchorLink>
            <AnchorLink
              href={`https://www.youtube.com/channel/UC8nJLBfVD4lrs0_Zc4AYLEg`}
              target="_blank"
              data-test="youtube-icon"
            >
              <Img src={youtube} alt="youtube icon" />
            </AnchorLink>
            <AnchorLink
              href={`https://t.me/EthereumExpress`}
              target="_blank"
              data-test="telegram-icon"
            >
              <Img src={telegram} alt="telegram-icon" />
            </AnchorLink>
          </Icons>
        </Flex>
      </Top>

      <Bottom>
        <FilesWrapper>
          <AnchorLink
            href={`${process.env.PUBLIC_URL}/files/EEX_Privacy_Policy.pdf`}
            target="_blank"
            data-test="privacy-policy-file"
          >
            Privacy policy
          </AnchorLink>
          <AnchorLink
            href={`${process.env.PUBLIC_URL}/files/EEX_Terms_of_use.pdf`}
            target="_blank"
            data-test="terms-of-use-file"
          >
            Terms of use
          </AnchorLink>
        </FilesWrapper>
        <Text
          color="secondary"
          size="s"
          align="center"
          margin="0"
          dFlex={true}
          flexWrap={true}
        >
          <Span>© {currentYear} Ethereum Express Foundation Limited.</Span>
          <Span>All rights reserved.</Span>
        </Text>
      </Bottom>
    </Root>
  );
};

export default Footer;
