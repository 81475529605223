// Absolute imports
import React from "react";

// Styles
import { Root, Text } from "./styles";

const SuccessPopUp = ({ text }) => {
  return (
    <Root>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d)">
          <circle cx="10" cy="9" r="8" fill="white" />
        </g>
        <line
          x1="9.23233"
          y1="11.3592"
          x2="13.4062"
          y2="6.35916"
          stroke="#63A160"
          strokeWidth="2"
        />
        <line
          x1="6.88093"
          y1="7.68419"
          x2="9.66354"
          y2="10.4668"
          stroke="#63A160"
          strokeWidth="2"
        />
        <defs>
          <filter
            id="filter0_d"
            x="0"
            y="0"
            width="20"
            height="20"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.545833 0 0 0 0 0.545833 0 0 0 0 0.545833 0 0 0 0.3 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
      <Text>{text}</Text>
    </Root>
  );
};

export default SuccessPopUp;
