import React from "react";

const CopyIcon = ({ active }) => {
  return (
    <svg
      width="14"
      height="19"
      viewBox="0 0 14 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.2966 4.79822H1.85855C1.16061 4.79822 0.59375 5.36508 0.59375 6.06301V16.7351C0.59375 17.4331 1.16061 17.9999 1.85855 17.9999H9.2966C9.99453 17.9999 10.5614 17.4331 10.5614 16.7351V6.06301C10.5581 5.36508 9.99125 4.79822 9.2966 4.79822ZM9.67342 16.7319C9.67342 16.9416 9.50303 17.112 9.29332 17.112H1.85527C1.64556 17.112 1.47518 16.9416 1.47518 16.7319V6.06301C1.47518 5.85331 1.64556 5.68292 1.85527 5.68292H9.29332C9.50303 5.68292 9.67342 5.85331 9.67342 6.06301V16.7319Z"
        fill={active ? "#21af7b" : "#A8A5B4"}
        stroke={active ? "#21af7b" : "#A8A5B4"}
        strokeWidth="0.5"
      />
      <path
        d="M12.1413 1H4.70327C4.00534 1 3.43848 1.56686 3.43848 2.2648C3.43848 2.51055 3.63508 2.70715 3.88083 2.70715C4.12658 2.70715 4.32318 2.51055 4.32318 2.2648C4.32318 2.05509 4.49357 1.8847 4.70327 1.8847H12.1413C12.351 1.8847 12.5214 2.05509 12.5214 2.2648V12.9369C12.5214 13.1466 12.351 13.317 12.1413 13.317C11.8956 13.317 11.699 13.5136 11.699 13.7594C11.699 14.0051 11.8956 14.2017 12.1413 14.2017C12.8393 14.2017 13.4061 13.6349 13.4061 12.9369V2.2648C13.4061 1.56686 12.8393 1 12.1413 1Z"
        fill={active ? "#21af7b" : "#A8A5B4"}
        stroke={active ? "#21af7b" : "#A8A5B4"}
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default CopyIcon;
