import styled from "styled-components";

const colors = {
  "Not verified": "#B8B8B8",
  "In review": "#ECBE1C",
  Verified: "#42BA12"
};

export const Root = styled.div`
  width: 260px;
  background: #fff;
  border-radius: 4px;
  padding: 10px 15px;
  font-family: Roboto;
  color: #2c2a32;
  position: absolute;
  top: 35px;
  right: 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
`;

export const Header = styled.div`
  padding-bottom: 15px;
  border-bottom: 1px solid #e8e8e8;
  font-size: 14px;
  display: flex;
  align-items: center;
`;

export const HeaderItem = styled.div`
  margin-right: 5px;
`;

export const DropDownItem = styled.p`
  font-size: 16px;
  cursor: pointer;
`;

export const Span = styled.span`
  color: #b8b8b8;

  ${({ color }) => color && `color: ${colors[color]};`}
`;
