import React from "react";

import Header from "../Header";
import ExchangeSection from "../Screens/ExchangeSection";
import Footer from "../Footer";
import Subscribe from "../Screens/Subscribe";

const Main = () => (
  <>
    <Header />
    <ExchangeSection />
    <Subscribe />
    <Footer />
  </>
);

export default Main;
