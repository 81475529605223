// Absolute imports
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// Styles
import { Root, Item, ArrowDown, ArrowUp, Button } from "./styles";
import { AnchorLink } from "../UI/Typography";

// Components
import DropDown from "../DropDown";
import ProfileIcon from "../ProfileIcon";

// Action creators
import { toggleDropDownAction } from "../../redux/actionCreators";

const Navigation = ({
  isLoggedIn,
  userEmail,
  status,
  isKycOpened,
  isDropDownShown,
  toggleDropDown
}) => {
  const styles = {
    textDecoration: "none",
    color: "#fff",
    cursor: "pointer",
    display: "block"
  };

  useEffect(() => { }, [isDropDownShown]);

  const toggle = () => {
    toggleDropDown(!isDropDownShown);
  };

  return (
    <Root>
      <Item>
        <Link to="/exchange" style={styles}>
          Exchange
        </Link>
      </Item>

      <Item>
        <AnchorLink
          href={`${process.env.REACT_APP_WALLET_LINK}`}
          target="_blank"
          data-test="wallet"
        >
          Wallet
        </AnchorLink>
      </Item>
      <Item>
        <AnchorLink
          href="https://ethereum-express.com/"
          target="_blank"
          data-test="website"
        >
          Website
        </AnchorLink>
      </Item>
      <Item>
        <AnchorLink
          href={`${process.env.PUBLIC_URL}/files/whitepaper.pdf`}
          target="_blank"
          data-test="whitepaper"
        >
          Whitepaper
        </AnchorLink>
      </Item>
      {isLoggedIn ? (
        <>
          <Item>
            <ProfileIcon color="light" size="lg" />
          </Item>
          <Item>
            <AnchorLink data-test="drop-down" onClick={toggle}>
              {userEmail}
            </AnchorLink>
            {isDropDownShown && (
              <DropDown
                isKycOpened={isKycOpened}
                email={userEmail}
                status={status}
              />
            )}
          </Item>
          <Item margin="0 0 0 5px">
            {isDropDownShown ? (
              <ArrowUp onClick={toggle} data-test="arrow-up" />
            ) : (
                <ArrowDown onClick={toggle} data-test="arrow-down" />
              )}
          </Item>
        </>
      ) : (
          <>
            <Item>
              <Link data-test="signin" to="/signin" style={styles}>
                Sign In
            </Link>
            </Item>
            <Item>
              <Link data-test="signup" to="/signup" style={styles}>
                <Button>Sign Up</Button>
              </Link>
            </Item>
          </>
        )}
    </Root>
  );
};

const mapStateToProps = state => ({
  isLoggedIn: state.registration.isLoggedIn,
  userEmail: state.registration.userEmail,
  status: state.kyc.status,
  isDropDownShown: state.kyc.isDropDownShown
});

const mapDispatchToProps = dispatch => ({
  toggleDropDown: isShown => dispatch(toggleDropDownAction(isShown))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navigation);
