/**
 * Absolute imports
 */
import styled from "styled-components/macro";

export const Input = styled.input`
  width: 100%;
  margin: 0;
  display: block;
  padding: 14px 17px 14px;
  min-width: 0;
  background-color: #fff;
  border: 0;
  border-radius: 4px;

  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: #0e0e0e;
  -webkit-tap-highlight-color: transparent;

  ${({ error }) =>
    error &&
    `background-color: #ffe2e2; 
     color: #f02121;
     box-shadow: 0px 0px 0px 3px #ed2222 inset;
    `}

  &::placeholder {
    color: #b9b9b9;
  }

  &:focus {
    outline: 0;
  }
`;
