// Absolute imports
import React, { useState, useEffect } from "react";
import { isEmail } from "validator";
import { connect } from "react-redux";

// Components
import { SVG } from "../UI/SVG";

// Styles
import {
  Root,
  FormGroup,
  InputWrapper,
  ButtonWrapper,
  SubscribeInput,
  SubscribeButton,
  ValidationError,
  ThankYou
} from "./styles";

// Redux actions
import { addSubscriberAction } from "../../redux/actionCreators";

const validateEmail = value => {
  if (!isEmail(value)) {
    return "Invalid email address";
  }
  return null;
};

const SubscribeForm = ({ addSubscriber, isSubscriberAdded }) => {
  const [{ error, submited, email, clicked }, setState] = useState({
    email: "",
    error: null,
    submited: false,
    clicked: false
  });

  const handleSuccess = () => {
    setState(values => ({
      ...values,
      submited: true,
      email: "",
      clicked: false
    }));
  };

  const handleError = () =>
    setState(values => ({
      ...values,
      error: "Failed to subscribe, please try again in a moment.",
      email: ""
    }));

  useEffect(() => {
    if (isSubscriberAdded) {
      handleSuccess();
    } else if (clicked && !isSubscriberAdded) {
      handleError();
    }
  }, [isSubscriberAdded]);

  const handleSubmit = event => {
    event.preventDefault();

    const error = validateEmail(email);

    if (error) {
      setState(values => ({
        ...values,
        error
      }));
      return;
    }

    setState(values => ({
      ...values,
      clicked: true
    }));

    addSubscriber(email);
  };

  const handleChange = event => {
    const {
      target: { value, name }
    } = event;
    const error = validateEmail(value);

    setState(values => ({
      ...values,
      error,
      [name]: value
    }));
  };

  if (submited) {
    return (
      <ThankYou>
        We will inform you about latest news! Thanks for subscribing!
      </ThankYou>
    );
  }

  return (
    <Root onSubmit={handleSubmit}>
      <FormGroup>
        <InputWrapper>
          <SubscribeInput
            name="email"
            error={Boolean(error)}
            value={email}
            onChange={handleChange}
            placeholder="Email"
          />
        </InputWrapper>

        <ButtonWrapper>
          <SubscribeButton>Subscribe</SubscribeButton>
        </ButtonWrapper>
      </FormGroup>

      {error && (
        <ValidationError>
          <SVG viewBox="0 0 24 24" gutterRight>
            <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
          </SVG>
          {error}
        </ValidationError>
      )}
    </Root>
  );
};

const mapStateToProps = state => ({
  subscribers: state.subscribe.subscribers,
  isSubscriberAdded: state.subscribe.success
});

const mapDispatchToProps = dispatch => ({
  addSubscriber: email => dispatch(addSubscriberAction(email))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscribeForm);
