// Absolute imports
import styled, { css } from "styled-components/macro";
import { Link } from "react-router-dom";

// Breakpoints
import { breakpoints } from "../../theme/breakpoints";

const colors = {
  secondary: "#FFF"
};

const sizes = {
  s: "14px",
  m: "16px",
  ml: "18px",
  lg: "20px"
};

export const HBase = css`
  margin: 0;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: normal;
  ${({ color }) => color && `color: ${colors[color]};`}
  ${({ paragraph }) => paragraph && `margin-bottom: 1em;`}
  ${({ align }) => align && `text-align: ${align};`}
`;

export const H1 = styled.h1`
  ${HBase}
  font-size: 28px;
  font-weight: 600;
  font-family: "Fira Sans", sans-serif;

  ${({ align }) => align && `text-align: ${align};`}
  ${({ margin }) => margin && `margin: ${margin};`}


  @media (min-width: 600px) {
    font-size: 38px;
  }

  @media (min-width: 960px) {
    font-size: 58px;
  }

  @media (max-width: ${breakpoints.md}px) {
    text-align: center;
  }
`;

export const H2 = styled.h2`
  ${HBase}

  font-size: 24px;
  line-height: 1.23;
  font-weight: 600;

  @media (min-width: 960px) {
    font-size: 45px;
  }
`;

export const H3 = styled.h2`
  ${HBase}
  font-size: 28px;
  line-height: 1.23;
  font-weight: 600;
  ${({ align }) => align && `text-align: ${align};`}

  @media (min-width: 960px) {
    font-size: 40px;
  }
`;

export const Text = styled.div`
  ${({ color }) => color && `color: ${colors[color]};`}

  ${({ bold }) => bold && `font-weight: ${bold};`}

  ${({ size }) => size && `font-size: ${sizes[size]};`}

  ${({ gutterBottom }) => gutterBottom && `margin-bottom: 1rem;`}

  ${({ paragraph }) => paragraph && `margin-bottom: 1em;`}

  ${({ align }) => align && `text-align: ${align};`}

  ${({ margin }) => margin && `margin: ${margin};`}

  ${({ width }) => width && `width: ${width};`}

  ${({ dFlex }) => dFlex && `display: flex;`}
  ${({ flexWrap }) => flexWrap && `flex-wrap: wrap;`}
  font-family: 'Roboto', sans-serif;

  @media (max-width: ${breakpoints.lg}px) {
    text-align: center;
  }
`;

export const AnchorLink = styled.a`
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
  justify-content: center;

  ${({ color }) => color && `color: ${color};`}
`;

export const StyledLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
  justify-content: center;

  ${({ color }) => color && `color: ${color};`}
`;
