import * as types from "../types";

const subscribeState = {
  success: false
};

const subscribeReducer = (state = subscribeState, { type, payload }) => {
  switch (type) {
    case types.ADD_SUBSCRIBER:
      return {
        ...state,
        success: true
      };
    case types.ADD_SUBSCRIBER_FAILED:
      return {
        ...state,
        success: false
      };
    default:
      return state;
  }
};
export default subscribeReducer;
