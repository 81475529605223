// Absolute imports
import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import uuid from "uuid/v4";
import Web3 from "web3";
import validate from "bitcoin-address-validation";

// Styles
import {
  Form,
  H3,
  Flex,
  Info,
  Photo,
  Button,
  FileInput,
  Img,
  Text,
  Line,
  Label,
  Input,
  Icon,
  CheckBoxWrapper,
  CheckBoxLabel,
  CheckBox,
  Span,
  ButtonsBlock,
  BottomButton,
  Root,
  GeneralError
} from "./styles";

//Images
import profileIcon from "./img/profileIcon.svg";

// Components
import CopyIcon from "../CopyIcon";
import Eye from "../Eye";
import KYCHeader from "../KYCHeader";
import Footer from "../Footer";
import { AnchorLink } from "../UI/Typography";
import Loading from "../Loading";

// Action creators
import {
  saveUserSettingsAction,
  uploadProfilePhotoAction,
  setIsUserSettingsSavedAction,
  getUserSettingsAction,
  toggleDropDownAction
} from "../../redux/actionCreators";

// Helpers
import { validatePassword } from "../../utils/validate";
import { modifyDate } from "../../utils/modifyDate";
import { formatBytes } from "../../utils/formatBytes";

const web3 = new Web3(`${process.env.REACT_APP_RPC_URL}` || Web3.givenProvider);

const ProfileSettings = ({
  saveUserSettings,
  userEmail,
  uploadProfilePhoto,
  saveUserSettingsError,
  isUserSettingsSaved,
  setIsUserSettingsSaved,
  getUserSettings,
  userSettings,
  getUserSettingsError,
  getProfilePhotoError,
  saveUserSettingsAttempt,
  toggleDropDown
}) => {
  const [
    {
      profilePictureBg,
      checked,
      eexAddress,
      btcAddress,
      ethAddress,
      currentPassword,
      newPassword,
      isCurrentPasswordHidden,
      isNewPasswordHidden,
      disabled,
      isLoading,
      file,
      validated,
      updatedAt,
      bigPhotoError,
      eexAddressError,
      btcAddressError,
      ethAddressError
    },
    setState
  ] = useState({
    profilePictureBg: null,
    checked: true,
    eexAddress: "",
    btcAddress: "",
    ethAddress: "",
    currentPassword: "",
    newPassword: "",
    isCurrentPasswordHidden: true,
    isNewPasswordHidden: true,
    disabled: true,
    isLoading: false,
    file: null,
    validated: [],
    updatedAt: "",
    bigPhotoError: null,
    eexAddressError: null,
    btcAddressError: null,
    ethAddressError: null
  });

  const [passwordValidationErrors, setPasswordValidationErrors] = useState([]);

  const [{ eex, btc, eth }, setActiveIcons] = useState({
    eex: false,
    btc: false,
    eth: false
  });

  const eexRef = useRef(null);
  const btcRef = useRef(null);
  const ethRef = useRef(null);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    getUserSettings(accessToken);
  }, []);

  useEffect(() => {
    if (saveUserSettingsError) {
      setState(values => ({
        ...values,
        isLoading: false,
        currentPassword: "",
        newPassword: ""
      }));
    }
    if (isUserSettingsSaved && !getProfilePhotoError) {
      setState(values => ({
        ...values,
        isLoading: false,
        currentPassword: "",
        newPassword: ""
      }));
      setTimeout(() => {
        setIsUserSettingsSaved(false);
      }, 2000);
    }
  }, [isUserSettingsSaved, saveUserSettingsError, getProfilePhotoError, saveUserSettingsAttempt]);

  useEffect(() => {
    if (Object.keys(userSettings).length) {
      const { eexAddress, btcAddress, ethAddress, newsletters, photo, updatedAt } = userSettings;

      setState(values => ({
        ...values,
        eexAddress: eexAddress || "",
        btcAddress: btcAddress || "",
        ethAddress: ethAddress || "",
        checked: newsletters,
        profilePictureBg: photo,
        updatedAt
      }));
    }
  }, [userSettings, getUserSettingsError]);

  const loadPhoto = (file, name) => {
    if (!file) return;

    setState(values => ({
      ...values,
      file,
      profilePictureBg: URL.createObjectURL(file),
      disabled: false
    }));
  };
  const copyText = field => {
    switch (field) {
      case "eex":
        eexRef.current.select();
        break;
      case "btc":
        btcRef.current.select();
        break;
      case "eth":
        ethRef.current.select();
        break;
      default:
        return;
    }

    document.execCommand("copy");

    setActiveIcons(values => ({
      ...values,
      [field]: true
    }));
    setTimeout(() => {
      setActiveIcons(values => ({
        ...values,
        [field]: false
      }));
    }, 1000);
  };

  const handleToggle = event => {
    setState(values => ({
      ...values,
      checked: !checked,
      disabled: false
    }));
  };

  const handleChange = event => {
    const { name, value } = event.target;

    if (name === "newPassword") {
      const validated = validatePassword(value);

      setState(values => ({
        ...values,
        validated: [...validated]
      }));
    }

    setState(values => ({
      ...values,
      [name]: value,
      disabled: false
    }));
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (currentPassword && !newPassword) {
      setPasswordValidationErrors(["New password field is required"]);
      return;
    }

    if (!currentPassword && newPassword) {
      setPasswordValidationErrors(["Current password field is required"]);
      return;
    }

    if (validated.length < 3 && newPassword !== "") {
      const min8Chars = validated.includes("Min 8 characters");
      const atLeastOneSpecChar = validated.includes("At least one special character (e.g. *, _, &)");
      const atLeatOneNumber = validated.includes("At least one number");

      const errors = [];
      if (!min8Chars) {
        errors.push("Min 8 characters");
      }
      if (!atLeastOneSpecChar) {
        errors.push("At least one special character (e.g. *, _, &)");
      }
      if (!atLeatOneNumber) {
        errors.push("At least one number");
      }

      setPasswordValidationErrors(errors);
      return;
    }

    let eexAddressError = null;
    let ethAddressError = null;
    let btcAddressError = null;

    if (eexAddress) {
      const eexAddrValidated = validateAddress(eexAddress);

      if (!eexAddrValidated) {
        eexAddressError = "EEX address is not valid.";
      }
    }

    if (ethAddress) {
      const ethAddrValidated = validateAddress(ethAddress);

      if (!ethAddrValidated) {
        ethAddressError = "ETH address is not valid.";
      }
    }

    if (btcAddress) {
      const btcAddrValidated = validate(btcAddress);

      if (!btcAddrValidated) {
        btcAddressError = "BTC address is not valid.";
      }
    }

    if (!eexAddressError && !ethAddressError && !btcAddressError) {
      saveUserSettings({
        userEmail,
        eexAddress,
        btcAddress,
        ethAddress,
        password: { current: currentPassword, new: newPassword },
        newsletters: checked
      });

      setState(values => ({
        ...values,
        eexAddressError: null,
        btcAddressError: null,
        ethAddressError: null
      }));
    } else {
      setState(values => ({
        ...values,
        eexAddressError,
        ethAddressError,
        btcAddressError
      }));
      return;
    }

    if (file) {
      const fileSize = formatBytes(file.size);
      if (
        fileSize.size >= 20 &&
        (fileSize.type === "mb" ||
          fileSize.type === "gb" ||
          fileSize.type === "tb" ||
          fileSize.type === "pb" ||
          fileSize.type === "eb" ||
          fileSize.type === "zb" ||
          fileSize.type === "yb")
      ) {
        setState(values => ({
          ...values,
          bigPhotoError: "Maximum size is 20MB"
        }));
      } else {
        uploadProfilePhoto(file);
        setState(values => ({
          ...values,
          bigPhotoError: null
        }));
      }
    }

    setState(values => ({
      ...values,
      isLoading: true,
      validated: []
    }));

    setPasswordValidationErrors([]);
  };

  const togglePasswordVisibility = (name, field) => {
    setState(values => ({
      ...values,
      [name]: !field
    }));
  };

  const cancelChanges = () => {
    const accessToken = localStorage.getItem("accessToken");
    getUserSettings(accessToken);

    setState(values => ({
      ...values,
      currentPassword: "",
      newPassword: "",
      disabled: true
    }));
  };

  const closeDropDown = event => {
    toggleDropDown(false);
  };

  const validateAddress = address => {
    return web3.utils.isAddress(address);
  };

  return (
    <>
      <KYCHeader />
      <Root onClick={closeDropDown}>
        <H3>Profile settings</H3>
        <Form onSubmit={handleSubmit}>
          <Flex justify="space-between" marginBottom="40px">
            <Flex>
              <Photo>
                <Img
                  data-test="settings-image"
                  size={profilePictureBg ? "lg" : "sm"}
                  src={profilePictureBg ? profilePictureBg : profileIcon}
                  alt="Profile icon"
                />
              </Photo>
              <Flex direction="column">
                <Info>Format: jpg, gif, png. Maximal size: 20 MB.</Info>
                <Button data-test="settings-upload-btn">
                  Upload
                  <FileInput
                    onChange={event => loadPhoto(event.target.files[0], event.target.name)}
                    type="file"
                    name="profilePicture"
                  />
                </Button>
              </Flex>
            </Flex>
            <Info width="56%" marginLeft="20px" align="right">
              Updated at: {Boolean(updatedAt) && modifyDate(updatedAt)}
            </Info>
          </Flex>
          <Text>User data</Text>
          <Line />
          <Flex direction="column">
            <Label>
              Email
              {/* We need to add a space after an email because of google chrome autofill */}
              <Input
                type="text"
                name="settings-email"
                readOnly
                disable
                data-test="settings-email"
                value={userEmail + " "}
              />
            </Label>
            <Label>
              EEX Address
              <Input
                type="text"
                data-test="settings-eex-address"
                value={eexAddress}
                onChange={handleChange}
                name="eexAddress"
                ref={eexRef}
                placeholder="Please enter your public key"
              />
              <Icon onClick={() => copyText("eex")} data-test="copy-icon">
                <CopyIcon active={eex} />
              </Icon>{" "}
              <Info color="#441EDA" position="absolute">
                <AnchorLink color="#441eda" target="_blank" href={`${process.env.REACT_APP_WALLET_LINK}`}>
                  Create EEX Wallet
                </AnchorLink>
              </Info>
            </Label>

            <Label>
              BTC Address
              <Input
                type="text"
                data-test="settings-btc-address"
                placeholder="Please enter your public key"
                value={btcAddress}
                onChange={handleChange}
                name="btcAddress"
                ref={btcRef}
              />
              <Icon onClick={() => copyText("btc")} data-test="copy-icon">
                <CopyIcon active={btc} />
              </Icon>
            </Label>
            <Label htmlFor="ethAddress">
              ETH Address
              <Input
                type="text"
                placeholder="Please enter your public key"
                data-test="settings-eth-address"
                value={ethAddress}
                onChange={handleChange}
                name="ethAddress"
                ref={ethRef}
                id="ethAddress"
              />
              <Icon data-test="copy-icon" onClick={() => copyText("eth")}>
                <CopyIcon active={eth} />
              </Icon>
            </Label>
          </Flex>
          <Text>Change Password</Text>
          <Info>These fields are required</Info>
          <Label htmlFor="currentPassword">
            Current
            <Input
              value={currentPassword}
              onChange={handleChange}
              data-test="settings-current-password"
              name="currentPassword"
              type={isCurrentPasswordHidden ? "password" : "text"}
              id="currentPassword"
            />
            <Icon onClick={() => togglePasswordVisibility("isCurrentPasswordHidden", isCurrentPasswordHidden)}>
              <Eye
                size="sm"
                data-test="settings-current-password-eye"
                color={isCurrentPasswordHidden ? "light" : "main"}
              />
            </Icon>
          </Label>

          <Label htmlFor="newPassword">
            New
            <Input
              value={newPassword}
              onChange={handleChange}
              data-test="settings-new-password"
              name="newPassword"
              id="newPassword"
              type={isNewPasswordHidden ? "password" : "text"}
            />
            <Icon onClick={() => togglePasswordVisibility("isNewPasswordHidden", isNewPasswordHidden)}>
              <Eye data-test="settings-new-password-eye" size="sm" color={isNewPasswordHidden ? "light" : "main"} />
            </Icon>{" "}
          </Label>

          <CheckBoxWrapper>
            <CheckBox
              data-test="settings-checkbox"
              onChange={handleToggle}
              checked={checked}
              type="checkbox"
              id="checkbox"
            />
            <CheckBoxLabel
              htmlFor="checkbox"
              style={
                checked
                  ? {
                      background: "#441eda",
                      ":after": {
                        content: "",
                        display: "block",
                        borderRadius: "50%",
                        width: "18px",
                        height: "18px",
                        marginLeft: "21px",
                        transition: "0.2s"
                      }
                    }
                  : {
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "42px",
                      height: "26px",
                      borderRadius: "15px",
                      background: "#bebebe",
                      cursor: "pointer",
                      ":after": {
                        content: "",
                        display: "block",
                        borderRadius: "50%",
                        width: "18px",
                        height: "18px",
                        margin: "4px",
                        background: "#ffffff",
                        boxShadow: "1px 3px 3px 1px rgba(0, 0, 0, 0.2)",
                        transition: "0.2s"
                      }
                    }
              }
            />
            <Span>I would like to receive newsletters</Span>
          </CheckBoxWrapper>
          {saveUserSettingsError && <GeneralError data-test="settings-error">{saveUserSettingsError}</GeneralError>}
          {!getUserSettingsError &&
            !getProfilePhotoError &&
            !bigPhotoError &&
            isUserSettingsSaved &&
            !eexAddressError &&
            !btcAddressError &&
            !ethAddressError && (
              <Info data-test="settings-success-message" color="green" size="16px" bold>
                Successfully saved.
              </Info>
            )}
          {getUserSettingsError && <GeneralError data-test="settings-error">{getUserSettingsError}</GeneralError>}
          {getProfilePhotoError && <GeneralError data-test="settings-error">{getProfilePhotoError}</GeneralError>}
          {bigPhotoError && <GeneralError data-test="settings-error">{bigPhotoError}</GeneralError>}

          {/*  */}
          {eexAddressError && <GeneralError data-test="settings-error">{eexAddressError}</GeneralError>}
          {btcAddressError && <GeneralError data-test="settings-error">{btcAddressError}</GeneralError>}
          {ethAddressError && <GeneralError data-test="settings-error">{ethAddressError}</GeneralError>}
          {/*  */}

          {Boolean(passwordValidationErrors.length) && (
            <>
              {passwordValidationErrors.map(error => (
                <GeneralError key={uuid()}>{error}</GeneralError>
              ))}
            </>
          )}
          <ButtonsBlock>
            <BottomButton data-test="settings-cancel" theme="light" onClick={cancelChanges} type="button">
              Cancel
            </BottomButton>

            <BottomButton
              data-test="settings-save"
              theme="main"
              disabled={disabled}
              type="submit"
              theme={disabled ? "lightViolet" : isLoading ? "lighterViolet" : "main"}
            >
              {isLoading ? <Loading /> : <span>Save</span>}
            </BottomButton>
          </ButtonsBlock>
        </Form>
      </Root>
      <Footer />
    </>
  );
};

const mapStateToProps = state => ({
  userEmail: state.registration.userEmail,
  saveUserSettingsError: state.settings.saveUserSettingsError,
  isUserSettingsSaved: state.settings.isUserSettingsSaved,
  userSettings: state.settings.userSettings,
  getUserSettingsError: state.settings.getUserSettingsError,
  getProfilePhotoError: state.settings.getProfilePhotoError,
  saveUserSettingsAttempt: state.settings.saveUserSettingsAttempt
});

const mapDispatchToProps = dispatch => ({
  saveUserSettings: settings => dispatch(saveUserSettingsAction(settings)),
  uploadProfilePhoto: photo => dispatch(uploadProfilePhotoAction(photo)),
  setIsUserSettingsSaved: isSaved => dispatch(setIsUserSettingsSavedAction(isSaved)),
  getUserSettings: token => dispatch(getUserSettingsAction(token)),
  toggleDropDown: isShown => dispatch(toggleDropDownAction(isShown))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileSettings);
