// Absolute imports
import React, { useState, useEffect } from "react";
import { isEmail } from "validator";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// Styles
import {
  Root,
  Logo,
  LogoImg,
  LogoDescription,
  FormWrapper,
  Label,
  Input,
  Error,
  Button,
  H2,
  Info,
  IconWrapper
} from "./styles";
import { linkStyles } from "../../theme/linkStyles";

// Components
import Eye from "../Eye";
import Loading from "../Loading";

// Images
import siteLogoImg from "./site-logo.svg";

// Action creators
import { checkCredentialsAction, setLogInAction } from "../../redux/actionCreators";

const SignIn = ({ isVerified, checkCredentials, logInError, attempt, history, setLogIn, isLoggedIn }) => {
  const [{ email, emailError, password, passwordError, isPasswordHidden, error, isLoading }, setState] = useState({
    email: "",
    emailError: null,
    password: "",
    passwordError: "",
    isPasswordHidden: true,
    error: null,
    isLoading: false
  });

  useEffect(() => {
    window.scrollTo(0, 0);

    if (isVerified) {
      setLogIn(true);
    } else if (isVerified === false) {
      setState(values => ({
        ...values,
        error: logInError,
        isLoading: false,
        email: "",
        password: "",
        emailError: "",
        passwordError: ""
      }));
    }
  }, [isVerified, attempt, logInError]);

  const validatePassword = password => {
    if (!password) {
      return "This field is required";
    }
    return null;
  };

  const validateEmail = email => {
    if (!email) {
      return "This field is required";
    }
    if (!isEmail(email)) {
      return "Email is not valid";
    }
    return null;
  };

  const handleChange = event => {
    const {
      target: { value, name }
    } = event;

    setState(values => ({
      ...values,
      [name]: value
    }));
  };

  const handleSubmit = event => {
    event.preventDefault();

    setState(values => ({
      ...values,
      isLoading: true
    }));

    const emailError = validateEmail(email);
    const passwordError = validatePassword(password);

    if (emailError || passwordError) {
      setState(values => ({
        ...values,
        emailError,
        passwordError,
        email: "",
        password: "",
        isLoading: false
      }));
      return;
    }

    checkCredentials({ email, password });
  };

  const togglePasswordVisibility = () => {
    setState(values => ({
      ...values,
      isPasswordHidden: !isPasswordHidden
    }));
  };

  return (
    <>
      <Root>
        <Logo>
          <Link to="/" data-test="logo">
            <LogoImg src={siteLogoImg} alt="Logo" />
          </Link>
          <LogoDescription>
            Ethereum
            <br /> Express
            <br /> Coin
          </LogoDescription>
        </Logo>

        <FormWrapper>
          <form onSubmit={handleSubmit}>
            <>
              <H2>Sign in</H2>
              <Label htmlFor="email">
                Email <br />
                <Input
                  value={email}
                  onChange={handleChange}
                  type="email"
                  name="email"
                  placeholder="Enter email"
                  error={emailError}
                  data-test="sign-in-email"
                  id="email"
                />
              </Label>

              {emailError && <Error data-test="sign-in-email-error">{emailError}</Error>}

              <Label htmlFor="password">
                Password
                <Input
                  value={password}
                  onChange={handleChange}
                  type={isPasswordHidden ? "password" : "text"}
                  name="password"
                  placeholder="Enter password"
                  error={passwordError}
                  data-test="sign-in-password"
                  id="password"
                />
                <IconWrapper data-test="sign-in-eye" onClick={togglePasswordVisibility}>
                  <Eye color={isPasswordHidden ? "light" : "main"} />
                </IconWrapper>
              </Label>

              {passwordError && <Error data-test="sign-in-password-error">{passwordError}</Error>}

              {error && <Error data-test="sign-in-general-error">{error}</Error>}

              <Button data-test="sign-in-button" background={isLoading ? "lightViolet" : "main"} type="submit">
                {" "}
                {isLoading ? <Loading /> : <span>Sign in</span>}{" "}
              </Button>
              <Info data-test="sign-in-restore">
                <Link to="/restore" style={linkStyles}>
                  Forgot password?
                </Link>
              </Info>

              <Info data-test="sign-in-to-sign-up">
                <Link to="/signup" style={linkStyles}>
                  Create new account
                </Link>
              </Info>
            </>
          </form>
        </FormWrapper>
      </Root>
    </>
  );
};

const mapStateToProps = state => ({
  isVerified: state.registration.isVerified,
  attempt: state.registration.attemptForLogIn,
  logInError: state.registration.logInError,
  isLoggedIn: state.registration.isLoggedIn
});

const mapDispatchToProps = dispatch => ({
  checkCredentials: credentials => dispatch(checkCredentialsAction(credentials)),
  setLogIn: isLoggedIn => dispatch(setLogInAction(isLoggedIn))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignIn);
