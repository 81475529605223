// Absolute imports
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// Styles
import { Root, Item, Line } from "./styles";
import { AnchorLink } from "../UI/Typography";

//Actions
import { setIsVerifiedAction, setLogInAction, setIsShownAction, clearErrorsAction } from "../../redux/actionCreators";

const MobileNavigation = ({
  active,
  handleToggleMenu,
  isLoggedIn,
  userEmail,
  setIsVerified,
  setLogIn,
  status,
  isKycOpened,
  setIsShown,
  clearErrors
}) => {
  const styles = {
    textDecoration: "none",
    color: "#fff",
    cursor: "pointer"
  };

  const logOut = () => {
    // localStorage.clear();
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userEmail");
    setLogIn(false);
    setIsVerified();
    handleToggleMenu();
    clearErrors();
  };

  return (
    <Root active={active}>
      <Item>
        <AnchorLink data-test="website" href="https://ethereum-express.com/" target="_blank">
          Website
        </AnchorLink>
      </Item>

      <Item>
        <AnchorLink data-test="whitepaper" href={`${process.env.PUBLIC_URL}/files/whitepaper.pdf`} target="_blank">
          Whitepaper
        </AnchorLink>
      </Item>
      <Item>
        <AnchorLink href={`${process.env.REACT_APP_WALLET_LINK}`} target="_blank" data-test="wallet">
          Wallet
        </AnchorLink>
      </Item>
      <Item>
        <Link to="/exchange" style={styles}>
          Exchange
        </Link>
      </Item>

      {!isLoggedIn && (
        <>
          <Line />

          <Item>
            <Link style={styles} to="signin" data-test="sign-in-btn">
              Sign in
            </Link>
          </Item>
        </>
      )}

      {isLoggedIn && (
        <>
          <Line />
          <Item>
            <AnchorLink>
              <strong>{userEmail}</strong>
            </AnchorLink>
          </Item>

          <Item>
            <Link style={styles} to="/settings">
              Settings
            </Link>
          </Item>
          <Item data-test="kyc">
            <Link style={styles} to="/kyc">
              KYC ({status})
            </Link>
          </Item>
          <Item onClick={logOut} data-test="log-out">
            <Link style={styles} to="/">
              Log out
            </Link>
          </Item>
        </>
      )}
    </Root>
  );
};

const mapStateToProps = state => ({
  isLoggedIn: state.registration.isLoggedIn,
  userEmail: state.registration.userEmail,
  status: state.kyc.status
});

const mapDispatchToProps = dispatch => ({
  setLogIn: isLoggedIn => dispatch(setLogInAction(isLoggedIn)),
  setIsVerified: () => dispatch(setIsVerifiedAction()),
  setIsShown: () => dispatch(setIsShownAction()),
  clearErrors: () => dispatch(clearErrorsAction())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileNavigation);
