import styled, { keyframes } from "styled-components";

// Images
import bgImageTopLeft from "../Screens/ExchangeSection/img/bg-left-top.png";
import bgImageRightBottom from "../Screens/ExchangeSection/img/bg-right-bottom.png";

// Theme
import { breakpoints } from "../../theme/breakpoints";

export const Root = styled.section`
  min-height: 100vh;
  display: flex;
  padding: 140px 0 100px 0;
  align-items: center;
  justify-content: center;
  color: #fff;

  background-image: url(${bgImageTopLeft}), url(${bgImageRightBottom}),
    linear-gradient(to left, #261066, #3a1269, #471368, #59176c);
  background-position: left top, right bottom, center center;
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-size: 639px 650px, 560px 663px, 100% 100%;

  @media (max-width: ${breakpoints.md}px) {
    background-size: 539px 450px, 460px 463px, 100% 100%;
  }

  @media (max-width: ${breakpoints.sm}px) {
    background-size: 339px 250px, 260px 263px, 100% 100%;
  }
`;

export const LogoImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const ImageContainer = styled.div`
  width: 140px;
  height: 140px;
  position: absolute;
`;

const load3 = keyframes`
  0% {
    transform: rotate(0deg);
}
  100% {
    transform: rotate(360deg);
}
}
`;

export const Loader = styled.div`
    font-size: 24px;
    margin: 50px auto;
    text-indent: -9999em;
    width: 11em;
    height: 11em;
    border-radius: 50%;
    background: #ffffff;
    background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    position: relative;
    animation: ${load3} 1.4s infinite linear;
    transform: translateZ(0);

    &:before {
        width: 50%;
        height: 50%;
        background: #ffffff;
        border-radius: 100% 0 0 0;
        position: absolute;
        top: 0;
        left: 0;
        content: '';
      }

      &:after {
        background: #421469;
        width: 90%;
        height: 90%;
        border-radius: 50%;
        content: '';
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
  }`;

export const OpacityContainer = styled.div`
  opacity: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;
`;
