// Absolute
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// Styles
import { Root, H2, Line, Text, Button, ButtonsBlock, Span } from "./styles";
import { linkStyles } from "../../theme/linkStyles";

const KYCRejected = ({ userEmail, reset }) => {
  return (
    <Root>
      <H2>Your KYC verification was rejected!</H2>
      <Line />
      <Text>
        Check our answer in your email address which you provided during
        registration: <Span>{userEmail}</Span>
      </Text>
      <ButtonsBlock>
        <Button data-test="kyc-declined-to-home" theme="light">
          <Link
            style={{
              ...linkStyles,
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
              paddingTop: "10px"
            }}
            to="/"
          >
            Home page
          </Link>
        </Button>
        <Button onClick={reset} data-test="kyc-declined-to-kyc-step1">
          Upload again
        </Button>
      </ButtonsBlock>
    </Root>
  );
};

const mapStateToProps = state => ({
  userEmail: state.registration.userEmail
});

export default connect(mapStateToProps)(KYCRejected);
