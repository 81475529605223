// Absolute imports
import React, { useState } from "react";

// Components
import Eye from "../Eye";
import Loading from "../Loading";

// Styles
import {
  Label,
  Input,
  IconWrapper,
  List,
  ListItem,
  Button,
  Error
} from "./styles";

// Utils
import { validatePassword } from "../../utils/validate";

const PasswordValidation = ({ submit, isLoading, error }) => {
  const [
    {
      password,
      passwordError,
      isPasswordHidden,
      min8Chars,
      oneNumber,
      specialChar,
      validated,
      isBtnDisabled
    },
    setState
  ] = useState({
    password: "",
    passwordError: "",
    isPasswordHidden: true,
    min8Chars: false,
    oneNumber: false,
    specialChar: false,
    validated: false,
    isBtnDisabled: true
  });

  const validatePass = password => {
    const passwordValidMessages = validatePassword(password);

    if (passwordValidMessages.includes("Min 8 characters")) {
      setState(values => ({
        ...values,
        min8Chars: true
      }));
    } else {
      setState(values => ({
        ...values,
        min8Chars: false
      }));
    }

    if (passwordValidMessages.includes("At least one number")) {
      setState(values => ({
        ...values,
        oneNumber: true
      }));
    } else {
      setState(values => ({
        ...values,
        oneNumber: false
      }));
    }

    if (
      passwordValidMessages.includes(
        "At least one special character (e.g. *, _, &)"
      )
    ) {
      setState(values => ({
        ...values,
        specialChar: true
      }));
    } else {
      setState(values => ({
        ...values,
        specialChar: false
      }));
    }

    if (passwordValidMessages.length < 3) {
      setState(values => ({
        ...values,
        isBtnDisabled: true
      }));
    } else {
      setState(values => ({
        ...values,
        isBtnDisabled: false
      }));
    }

    setState(values => ({
      ...values,
      validated: true
    }));
  };

  const handleChange = event => {
    const { value } = event.target;

    setState(values => ({
      ...values,
      password: value
    }));

    validatePass(value);
  };

  const togglePasswordVisibility = () => {
    setState(values => ({
      ...values,
      isPasswordHidden: !isPasswordHidden
    }));
  };

  return (
    <>
      <Label>
        Password <br />
        <Input
          value={password}
          onChange={handleChange}
          type={isPasswordHidden ? "password" : "text"}
          name="password"
          placeholder="Enter password"
          error={passwordError || error}
          data-test="password"
        />
        <IconWrapper
          data-test="password-eye"
          onClick={togglePasswordVisibility}
        >
          <Eye color={isPasswordHidden ? "light" : "main"} />
        </IconWrapper>
      </Label>

      {error && <Error data-test="password-error">{error}</Error>}

      <List>
        <ListItem
          color={!validated ? "light" : min8Chars ? "success" : "error"}
          data-test="min-characters"
        >
          Min 8 characters
        </ListItem>
        <ListItem
          color={!validated ? "light" : oneNumber ? "success" : "error"}
          data-test="one-number"
        >
          At least one number
        </ListItem>
        <ListItem
          color={!validated ? "light" : specialChar ? "success" : "error"}
          data-test="one-special-character"
        >
          At least one special character (e.g. *, _, !)
        </ListItem>
      </List>
      <Button
        disabled={isBtnDisabled || isLoading}
        background={(isBtnDisabled || isLoading) && "lightViolet"}
        onClick={event => submit(event, password)}
        data-test="proceed"
      >
        {isLoading ? <Loading /> : <span>Proceed</span>}
      </Button>
    </>
  );
};

export default PasswordValidation;
