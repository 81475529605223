import { call } from "redux-saga/effects";

const API_URL = process.env.REACT_APP_EXCHANGE_API_URL;

function* apiCall(endpoint, options) {
  try {
    const response = yield call(fetch, API_URL + endpoint, options);
    return {
      [response.ok ? "result" : "error"]: yield call([response, response.json])
    };
  } catch (error) {
    return {
      error: {
        message: error.message
      }
    };
  }
}

const authHeaders = accessToken => ({
  Authorization: accessToken
});

export const convertRates = (from, to, value) => apiCall(`/rate-converter?from=${from}&to=${to}&value=${value}`);

export const getUserLimits = accessToken => apiCall(`/user-limits`, { headers: authHeaders(accessToken) });

export const getEthExchangeAddress = () => apiCall(`/eth-exchange-address`);

export const getBtcExchangeAddress = accessToken =>
  apiCall(`/btc-exchange-address`, { headers: authHeaders(accessToken) });

export const getUserTransactions = (accessToken, skip, limit, sortBy, sortOrder) =>
  apiCall(`/user-transactions?$skip=${skip}&$limit=${limit}&$sort[${sortBy}]=${sortOrder}`, {
    headers: authHeaders(accessToken)
  });

export const getGlobalTransactions = (skip, limit, sortBy, sortOrder) =>
  apiCall(`/all-user-transactions?$skip=${skip}&$limit=${limit}&$sort[${sortBy}]=${sortOrder}`);

export const postUserTransaction = (accessToken, pair, fundsExpected) =>
  apiCall(`/user-transactions`, {
    method: "POST",
    headers: {
      ...authHeaders(accessToken),
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      pair,
      fundsExpected
    })
  });
