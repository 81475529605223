import { delay, put, takeLatest, select, all } from "redux-saga/effects";
import * as constants from "../../constants";
import * as routes from "../../routes";
import * as types from "../types";
import * as actions from "../actionCreators/generalRatesActions";
import * as exchangeApi from "./services/exchangeApi";

export function* fetchGeneralRatesSaga() {
  const { btc } = yield all({
    btc: exchangeApi.convertRates("btc", "eex", "1")
    // eth: exchangeApi.convertRates("eth", "eex", "1")
  });
  if (
    btc.error
    // || eth.error
  ) {
    // yield put(actions.fetchGeneralRatesFailure(btc.error || eth.error));
    yield put(actions.fetchGeneralRatesFailure(btc.error));
  } else {
    yield put(
      actions.fetchGeneralRatesSuccess({
        btcPriceInEex: btc.result
        // ethPriceInEex: eth.result
      })
    );
  }
}

export function* periodicFetchGeneralRatesSaga() {
  while (true) {
    const currentRoute = yield select(s => s.router.location.pathname);
    if (currentRoute === routes.EXCHANGE) {
      yield put(actions.fetchGeneralRates());
      yield delay(constants.RATES_REFRESH_DELAY_MS - 100);
    }
    yield delay(100);
  }
}

export function* watchFetchGeneralRatesSaga() {
  yield takeLatest(types.FETCH_GENERAL_RATES, fetchGeneralRatesSaga);
}
