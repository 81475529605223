// Absolute imports
import styled from "styled-components/macro";

// Theme
import { breakpoints } from "../../theme/breakpoints";

export const Root = styled.nav`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  font-size: 20px;
  opacity: 0;
  z-index: 10;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    opacity 0.55s ease;

  // background-image: linear-gradient(to left, #1c073f, #0a0114);
  background-image: linear-gradient(
    to left,
    #261066,
    #3a1269,
    #471368,
    #59176c
  );

  ${({ active }) =>
    active &&
    `
      opacity: 1;
      transform: translate(0, 0);
    `};

  @media (min-width: ${breakpoints.md}px) {
    display: none;
  }
`;

export const Item = styled.li`
  list-style: none;
  padding: 10px 0;
`;

export const Line = styled.div`
  background: rgba(255, 255, 255, 0.5);
  height: 1px;
  width: 50%;
  margin: 30px 0;
`;
