import { delay, put, take, takeLatest, select } from "redux-saga/effects";
import moment from "moment";
import * as constants from "../../constants";
import * as routes from "../../routes";
import * as types from "../types";
import * as actions from "../actionCreators/eexPurchaseActions";
import * as exchangeApi from "./services/exchangeApi";
// import * as exchangeApiMock from "./services/exchangeApi.mock";

export function* fetchUserLimit() {
  const accessToken = localStorage.getItem("accessToken");
  const { error, result } = yield* exchangeApi.getUserLimits(accessToken);
  if (error) {
    yield put(actions.fetchUserLimitFailure(error));
  } else {
    yield put(actions.fetchUserLimitSuccess(result));
  }
}

export function* watchFetchUserLimit() {
  yield takeLatest(types.FETCH_USER_LIMIT, fetchUserLimit);
}

export function* fetchUserLimitOnSignIn() {
  while (true) {
    const { payload: isLoggedIn } = yield take(types.SET_LOG_IN);
    if (isLoggedIn) {
      yield put(actions.fetchUserLimit());
    }
  }
}

export function* fetchUserTransactions({ payload: { page } }) {
  const accessToken = localStorage.getItem("accessToken");
  const skip = page * constants.USER_TRANSACTIONS_PER_PAGE;
  const limit = constants.USER_TRANSACTIONS_PER_PAGE;
  const sortBy = "createdAt";
  const sortOrder = -1;
  const { error, result } = yield* exchangeApi.getUserTransactions(accessToken, skip, limit, sortBy, sortOrder);
  if (error) {
    yield put(actions.fetchUserTransactionsFailure(error));
  } else {
    yield put(actions.fetchUserTransactionsSuccess({ ...result, page }));
  }
}

export function* watchFetchUserTransactions() {
  yield takeLatest(types.FETCH_USER_TRANSACTIONS, fetchUserTransactions);
}

export function* periodicFetchUserTransactions() {
  while (true) {
    const isLoggedIn = yield select(s => s.registration.isLoggedIn);
    if (!isLoggedIn) {
      yield take(types.SET_LOG_IN);
      continue;
    }
    const currentRoute = yield select(s => s.router.location.pathname);
    if (currentRoute === routes.EXCHANGE) {
      const currentPage = yield select(s => s.eexPurchase.userTransactions.page);
      yield put(actions.fetchUserTransactions({ page: currentPage }));
      yield delay(constants.USER_TRANSACTIONS_REFRESH_DELAY_MS - 100);
    }
    yield delay(100);
  }
}

export function* fetchGlobalTransactions({ payload: { page } }) {
  const skip = page * constants.USER_TRANSACTIONS_PER_PAGE;
  const limit = constants.USER_TRANSACTIONS_PER_PAGE;
  const sortBy = "createdAt";
  const sortOrder = -1;
  const { error, result } = yield* exchangeApi.getGlobalTransactions(skip, limit, sortBy, sortOrder);
  if (error) {
    yield put(actions.fetchGlobalTransactionsFailure(error));
  } else {
    yield put(actions.fetchGlobalTransactionsSuccess({ ...result, page }));
  }
}

export function* watchFetchGlobalTransactions() {
  yield takeLatest(types.FETCH_GLOBAL_TRANSACTIONS, fetchGlobalTransactions);
}

export function* periodicFetchGlobalTransactions() {
  while (true) {
    const currentRoute = yield select(s => s.router.location.pathname);
    if (currentRoute === routes.EXCHANGE) {
      const currentPage = yield select(s => s.eexPurchase.globalTransactions.page);
      yield put(actions.fetchGlobalTransactions({ page: currentPage }));
      yield delay(constants.USER_TRANSACTIONS_REFRESH_DELAY_MS - 100);
    }
    yield delay(100);
  }
}

export function* fetchLastUserTransaction() {
  const accessToken = localStorage.getItem("accessToken");
  const skip = 0;
  const limit = 1;
  const sortBy = "createdAt";
  const sortOrder = -1;
  const { error, result } = yield* exchangeApi.getUserTransactions(accessToken, skip, limit, sortBy, sortOrder);
  if (error) {
    yield put(actions.fetchLastUserTransactionFailure(error));
  } else {
    const transaction = result.data[0];
    if (transaction) {
      const secondsUntilExpire = moment.duration(moment(transaction.expireAt).diff(moment())).asSeconds();
      yield put(actions.fetchLastUserTransactionSuccess({ ...transaction, secondsUntilExpire }));
    } else {
      yield put(actions.fetchLastUserTransactionSuccess());
    }
  }
}

export function* watchFetchLastUserTransaction() {
  yield takeLatest(types.FETCH_LAST_USER_TRANSACTION, fetchLastUserTransaction);
}

export function* periodicFetchLastUserTransaction() {
  while (true) {
    const isLoggedIn = yield select(s => s.registration.isLoggedIn);
    if (!isLoggedIn) {
      yield take(types.SET_LOG_IN);
      continue;
    }
    const currentRoute = yield select(s => s.router.location.pathname);
    if (currentRoute === routes.EXCHANGE || currentRoute === routes.COMPLETE_EXCHANGE) {
      yield put(actions.fetchLastUserTransaction());
      yield delay(constants.LAST_USER_TRANSACTION_REFRESH_DELAY_MS - 100);
    }
    yield delay(100);
  }
}

export function* fetchEexPriceInCurrency({ payload: { requestedEexAmount, targetCurrency } }) {
  const nonZeroDigitsCount = requestedEexAmount && requestedEexAmount.split("").filter(c => c > "0" && c <= "9").length;
  if (!requestedEexAmount || nonZeroDigitsCount === 0) {
    yield put(
      actions.fetchEexPriceInCurrencySuccess({
        price: "0"
      })
    );
    return;
  }
  const { error, result } = yield* exchangeApi.convertRates(types.CURRENCIES.EEX, targetCurrency, requestedEexAmount);
  if (error) {
    yield put(actions.fetchEexPriceInCurrencyFailure(error));
  } else {
    yield put(
      actions.fetchEexPriceInCurrencySuccess({
        price: result
      })
    );
  }
}

export function* watchFetchEexPriceInCurrency() {
  yield takeLatest(types.FETCH_EEX_PRICE_IN_CURRENCY, fetchEexPriceInCurrency);
}

export function* refetchEexPriceInCurrencyOnFetchGeneralRates() {
  yield takeLatest(types.FETCH_GENERAL_RATES, function*() {
    const { requestedEexAmount, targetCurrency } = yield select(s => s.eexPurchase);
    yield put(
      actions.fetchEexPriceInCurrency({
        requestedEexAmount,
        targetCurrency,
        automatic: true
      })
    );
  });
}

export function* buyEex({ payload: { targetCurrency, price } }) {
  const accessToken = localStorage.getItem("accessToken");

  const { error, result } = yield* exchangeApi.postUserTransaction(
    accessToken,
    `${targetCurrency.toUpperCase()}/EEX`,
    price
  );
  if (error) {
    yield put(actions.buyEexFailure(error));
  } else {
    yield put(actions.buyEexSuccess(result));
  }
}

export function* watchBuyEex() {
  yield takeLatest(types.BUY_EEX, buyEex);
}
