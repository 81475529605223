export const FETCH_GENERAL_RATES = "FETCH_GENERAL_RATES";
export const FETCH_GENERAL_RATES_SUCCESS = "FETCH_GENERAL_RATES_SUCCESS";
export const FETCH_GENERAL_RATES_FAILURE = "FETCH_GENERAL_RATES_FAILURE";

export const FETCH_BTC_EXCHANGE_ADDRESS = "FETCH_BTC_EXCHANGE_ADDRESS";
export const FETCH_BTC_EXCHANGE_ADDRESS_SUCCESS = "FETCH_BTC_EXCHANGE_ADDRESS_SUCCESS";
export const FETCH_BTC_EXCHANGE_ADDRESS_FAILURE = "FETCH_BTC_EXCHANGE_ADDRESS_FAILURE";

export const FETCH_ETH_EXCHANGE_ADDRESS = "FETCH_ETH_EXCHANGE_ADDRESS";
export const FETCH_ETH_EXCHANGE_ADDRESS_SUCCESS = "FETCH_ETH_EXCHANGE_ADDRESS_SUCCESS";
export const FETCH_ETH_EXCHANGE_ADDRESS_FAILURE = "FETCH_ETH_EXCHANGE_ADDRESS_FAILURE";

export const HIDE_FIRST_TIME_EEX_PURCHASE_INFO = "HIDE_FIRST_TIME_EEX_PURCHASE_INFO";

export const FETCH_EEX_PRICE_IN_CURRENCY = "FETCH_EEX_PRICE_IN_CURRENCY";
export const FETCH_EEX_PRICE_IN_CURRENCY_SUCCESS = "FETCH_EEX_PRICE_IN_CURRENCY_SUCCESS";
export const FETCH_EEX_PRICE_IN_CURRENCY_FAILURE = "FETCH_EEX_PRICE_IN_CURRENCY_FAILURE";

export const FETCH_USER_LIMIT = "FETCH_USER_LIMIT";
export const FETCH_USER_LIMIT_SUCCESS = "FETCH_USER_LIMIT_SUCCESS";
export const FETCH_USER_LIMIT_FAILURE = "FETCH_USER_LIMIT_FAILURE";

export const FETCH_USER_TRANSACTIONS = "FETCH_USER_TRANSACTIONS";
export const FETCH_USER_TRANSACTIONS_SUCCESS = "FETCH_USER_TRANSACTIONS_SUCCESS";
export const FETCH_USER_TRANSACTIONS_FAILURE = "FETCH_USER_TRANSACTIONS_FAILURE";

export const FETCH_GLOBAL_TRANSACTIONS = "FETCH_GLOBAL_TRANSACTIONS";
export const FETCH_GLOBAL_TRANSACTIONS_SUCCESS = "FETCH_GLOBAL_TRANSACTIONS_SUCCESS";
export const FETCH_GLOBAL_TRANSACTIONS_FAILURE = "FETCH_GLOBAL_TRANSACTIONS_FAILURE";

export const FETCH_LAST_USER_TRANSACTION = "FETCH_LAST_USER_TRANSACTION";
export const FETCH_LAST_USER_TRANSACTION_SUCCESS = "FETCH_LAST_USER_TRANSACTION_SUCCESS";
export const FETCH_LAST_USER_TRANSACTION_FAILURE = "FETCH_LAST_USER_TRANSACTION_FAILURE";

export const BUY_EEX = "BUY_EEX";
export const BUY_EEX_SUCCESS = "BUY_EEX_SUCCESS";
export const BUY_EEX_FAILURE = "BUY_EEX_FAILURE";

export const GET_EXCHANGE_RATES = "GET_EXCHANGE_RATES";
export const GET_EXCHANGE_RATES_ASYNC = "GET_EXCHANGE_RATES_ASYNC";
export const ADD_SUBSCRIBER_ASYNC = "ADD_SUBSCRIBER_ASYNC";
export const ADD_SUBSCRIBER = "ADD_SUBSCRIBER";
export const ADD_SUBSCRIBER_FAILED = "ADD_SUBSCRIBER_FAILED";
export const CHANGE_PAGE_STATE = "CHANGE_PAGE_STATE";
export const SET_LOG_IN = "SET_LOG_IN";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAILED = "CREATE_USER_FAILED";
export const CREATE_USER_ASYNC = "CREATE_USER_ASYNC";
export const VERIFY_USER_ASYNC = "VERIFY_USER_ASYNC";
export const VERIFY_USER = "VERIFY_USER";
export const EMAIL_VERIFICATION_SUCCESS = "EMAIL_VERIFICATION_SUCCESS";
export const EMAIL_VERIFICATION_FAILED = "EMAIL_VERIFICATION_FAILED";
export const USER_VERIFICATION_SUCCESS = "USER_VERIFICATION_SUCCESS";
export const USER_VERIFICATION_FAILED = "USER_VERIFICATION_FAILED";
export const CHECK_CREDENTIALS_ASYNC = "CHECK_CREDENTIALS_ASYNC";
export const CHECK_CREDENTIALS = "CHECK_CREDENTIALS";
export const RESEND_EMAIL_ASYNC = "RESEND_EMAIL_ASYNC";
export const CHECK_IS_LOGGED_IN_ASYNC = "CHECK_IS_LOGGED_IN_ASYNC";
export const CHECK_IS_LOGGED_IN = "CHECK_IS_LOGGED_IN";
export const SET_USER_EMAIL = "SET_USER_EMAIL";
export const RESEND_PASSWORD_ASYNC = "RESEND_PASSWORD_ASYNC";
export const RESEND_PASSWORD_SUCCESS = "RESEND_PASSWORD_SUCCESS";
export const RESEND_PASSWORD_FAILED = "RESEND_PASSWORD_FAILED";
export const CHANGE_PASSWORD_ASYNC = "RESET_PASSWORD_ASYNC";
export const CHANGE_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";
export const CHECK_REGISTRATION_ASYNC = "CHECK_REGISTRATION_ASYNC";
export const CHECK_REGISTRATION_SUCCESS = "CHECK_REGISTRATION_SUCCESS";
export const CHECK_REGISTRATION_FAILED = "CHECK_REGISTRATION_FAILED";
export const RESEND_EMAIL_SUCCESS = "RESEND_EMAIL_SUCCESS";
export const RESEND_EMAIL_FAILED = "RESEND_EMAIL_FAILED";
export const RESET_IS_REGISTERED_ASYNC = "RESET_IS_REGISTERED_ASYNC";
export const RESET_IS_REGISTERED = "RESET_IS_REGISTERED";
export const SET_IS_VERIFIED_ASYNC = "SET_IS_VERIFIED_ASYNC";
export const SET_IS_VERIFIED = "SET_IS_VERIFIED";
export const GET_COUNTRIES_ASYNC = "GET_COUNTRIES_ASYNC";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAILED = "GET_COUNTRIES_FAILED";
export const SEND_DATA_FIRST_STEP_ASYNC = "SEND_DATA_FIRST_STEP_ASYNC";
export const SEND_DATA_FIRST_STEP_SUCCESS = "SEND_DATA_FIRST_STEP_SUCCESS";
export const SEND_DATA_FIRST_STEP_FAILED = "SEND_DATA_FIRST_STEP_FAILED";
export const SEND_DATA_SECOND_STEP_ASYNC = "SEND_DATA_SECOND_STEP_ASYNC";
export const SEND_DATA_SECOND_STEP_SUCCESS = "SEND_DATA_SECOND_STEP_SUCCESS";
export const SEND_DATA_SECOND_STEP_FAILED = "SEND_DATA_SECOND_STEP_FAILED";
export const SET_USER_ID = "SET_USER_ID";
export const GET_USER_DATA_ASYNC = "GET_USER_DATA_ASYNC";
export const GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS";
export const GET_USER_DATA_FAILED = "GET_USER_DATA_FAILED";
export const SET_STEP = "SET_STEP";
export const GET_PHOTOS_ASYNC = "GET_PHOTOS_ASYNC";
export const GET_PHOTOS_SUCCESS = "GET_PHOTOS_SUCCESS";
export const GET_PHOTOS_FAILED = "GET_PHOTOS_FAILED";
export const CHECK_USER_STATUS_ASYNC = "CHECK_USER_STATUS_ASYNC";
export const CHECK_USER_STATUS_SUCCESS = "CHECK_USER_STATUS_SUCCESS";
export const CHECK_USER_STATUS_FAILED = "CHECK_USER_STATUS_FAILED";
export const RESET_PROFILE_ASYNC = "RESET_PROFILE_ASYNC";
export const RESET_PROFILE_SUCCESS = "RESET_PROFILE_SUCCESS";
export const RESET_PROFILE_FAILED = "RESET_PROFILE_FAILED";
export const SUBMIT_PROFILE_ASYNC = "SUBMIT_PROFILE_ASYNC";
export const SUBMIT_PROFILE_SUCCESS = "SUBMIT_PROFILE_SUCCESS";
export const SUBMIT_PROFILE_FAILED = "SUBMIT_PROFILE_FAILED";
export const SET_IS_SHOWN = "SET_IS_SHOWN";
export const RESET_TOKEN = "RESET_TOKEN";
export const SET_PAGES_INVISIBLE = "SET_PAGES_INVISIBLE";
export const TOGGLE_DROPDOWN = "TOGGLE_DROPDOWN";
export const SAVE_USER_SETTINGS_ASYNC = "SAVE_USER_SETTINGS_ASYNC";
export const SAVE_USER_SETTINGS_SUCCESS = "SAVE_USER_SETTINGS_SUCCESS";
export const SAVE_USER_SETTINGS_FAILED = "SAVE_USER_SETTINGS_FAILED";
export const UPLOAD_PROFILE_PHOTO_ASYNC = "UPLOAD_PROFILE_PHOTO_ASYNC";
export const UPLOAD_PROFILE_PHOTO_SUCCESS = "UPLOAD_PROFILE_PHOTO_SUCCESS";
export const UPLOAD_PROFILE_PHOTO_FAILED = "UPLOAD_PROFILE_PHOTO_FAILED";
export const SET_IS_USER_SETTINGS_SAVED = "SET_IS_USER_SETTINGS_SAVED";
export const SET_USER_SETTINGS_DATA_ASYNC = "SET_USER_SETTINGS_DATA_ASYNC";
export const SET_USER_SETTINGS_DATA_SUCCESS = "SET_USER_SETTINGS_DATA_SUCCESS";
export const SET_USER_SETTINGS_DATA_FAILED = "SET_USER_SETTINGS_DATA_FAILED";
export const CLEAR_PROFILE_SETTINGS_ERRORS = "CLEAR_PROFILE_SETTINGS_ERRORS";

export const CURRENCIES = Object.freeze({
  EEX: "eex",
  BTC: "btc",
  ETH: "eth"
});
