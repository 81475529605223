// Absolute imports
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// Styles
import {
  Root,
  Logo,
  LogoImg,
  LogoDescription,
  FormWrapper,
  H2
} from "./styles";

// Components
import PasswordValidation from "../PasswordValidation";
import PageNotFound from "../PageNotFound";
// import Loading from "../Loading";

// Images
import siteLogoImg from "./site-logo.svg";

// Action creators
import { resetPasswordAction } from "../../redux/actionCreators";

const NewPasswordForm = ({
  resetPassword,
  resetToken,
  isPasswordChanged,
  history,
  isLoggedIn,
  restoreError
}) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(false);

    if (isPasswordChanged) {
      history.push("/signin");
    }
  }, [isPasswordChanged]);

  useEffect(() => {
    if (restoreError) {
      setIsLoading(false);
    }
  }, [restoreError]);

  const handleSubmit = (event, password) => {
    event.preventDefault();

    setIsLoading(true);

    if (resetToken) {
      resetPassword({ resetToken, password });
    }
  };

  return (
    <>
      {isLoggedIn ? (
        <PageNotFound />
      ) : (
        <Root>
          <Logo>
            <Link to="/" data-test="logo">
              <LogoImg src={siteLogoImg} alt="Logo" />
            </Link>
            <LogoDescription>
              Ethereum
              <br /> Express
              <br /> Coin
            </LogoDescription>
          </Logo>

          <FormWrapper>
            {/* <form onSubmit={handleSubmit}> */}
            <form>
              <H2>Create new password</H2>
              <PasswordValidation
                submit={handleSubmit}
                isLoading={isLoading}
                error={restoreError}
              />
            </form>
          </FormWrapper>
        </Root>
      )}
    </>
  );
};
const mapStateToProps = state => ({
  isPasswordChanged: state.registration.isPasswordChanged,
  resetToken: state.registration.resetToken,
  isLoggedIn: state.registration.isLoggedIn,
  restoreError: state.registration.restoreError
});

const mapDispatchToProps = dispatch => ({
  resetPassword: data => dispatch(resetPasswordAction(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewPasswordForm);
