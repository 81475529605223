// Absolute imports
import React from 'react';

// Styles
import { Root, Ingredient } from './styles';

const Hamburger = ({ active, onClick }) => {
  const handleClick = () => onClick && onClick(!active);

  return (
    <Root onClick={handleClick}>
      <Ingredient active={active} />
      <Ingredient active={active} />
      <Ingredient active={active} />
    </Root>
  );
};

export default Hamburger;
