// Absolute
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import uuid from "uuid/v4";
import { FormattedMessage } from "react-intl";

// Styles
import {
  Text,
  Form,
  Flex,
  InputWrapper,
  Label,
  Input,
  Select,
  Option,
  ButtonsBlock,
  Button,
  Error,
  ErrorList,
  ErrorItem
} from "./styles";

// Action creators
import {
  getCountriesAction,
  sendDataFirstStepAction
} from "../../redux/actionCreators";

// Helpers
import { validateFields, validatePassport } from "../../utils/validate";

// Components
import Steps from "../Steps";

const KYCFirstStep = ({
  countries,
  getCountries,
  getCountriesErrors,
  sendDataFirstStep,
  firstStepVerificationErrors
}) => {
  const [
    {
      name,
      surname,
      country,
      passport,
      nameError,
      surnameError,
      passportError,
      countryError,
      isCountriesLoading,
      countryErrors
    },
    setState
  ] = useState({
    name: "",
    surname: "",
    passport: "",
    country: "",
    nameError: null,
    surnameError: null,
    passportError: null,
    countryError: null,
    isCountriesLoading: true,
    countryErrors: []
  });

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    if (countries.length && !getCountriesErrors.length) {
      setState(values => ({
        ...values,
        isCountriesLoading: false
      }));
    } else if (getCountriesErrors.length) {
      setState(values => ({
        ...values,
        isCountriesLoading: false,
        countryErrors: getCountriesErrors
      }));
    }
  }, [countries, getCountriesErrors]);

  useEffect(() => {
    if (firstStepVerificationErrors) {
    }
  }, [firstStepVerificationErrors]);

  const handleChange = event => {
    const { name, value } = event.target;

    setState(values => ({
      ...values,
      [name]: value
    }));
  };

  const submit = () => {
    const nameError = validateFields(name);
    const surnameError = validateFields(surname);
    const passportError = validatePassport(passport);
    const countryError = validateFields(country);

    if (!nameError && !surnameError && !passportError && !countryError) {
      sendDataFirstStep({ name, surname, passport, country });
    } else {
      setState(values => ({
        ...values,
        nameError,
        surnameError,
        passportError,
        countryError
      }));
    }
  };

  return (
    <>
      <Text>Step 1: Personal Information</Text>
      <Steps step={1} />
      <Form>
        <Flex>
          <InputWrapper>
            <Label htmlFor="name">Name</Label>
            <Input
              id="name"
              name="name"
              value={name}
              onChange={handleChange}
              error={Boolean(nameError)}
              data-test="kyc-step1-name"
            />
            {nameError && (
              <Error data-test="kyc-step1-name-error">{nameError}</Error>
            )}
          </InputWrapper>

          <InputWrapper>
            <Label htmlFor="surname">Surname</Label>
            <Input
              value={surname}
              name="surname"
              onChange={handleChange}
              id="surname"
              error={Boolean(surnameError)}
              data-test="kyc-step1-surname"
            />
            {surnameError && (
              <Error data-test="kyc-step1-surname-error">{surnameError}</Error>
            )}
          </InputWrapper>
        </Flex>

        <Flex>
          <InputWrapper>
            <Label htmlFor="passport">Passport/ID</Label>
            <Input
              value={passport}
              onChange={handleChange}
              id="passport"
              name="passport"
              error={Boolean(passportError)}
              data-test="kyc-step1-passport"
            />
            {passportError && (
              <Error data-test="kyc-step1-passport-error">
                {passportError}
              </Error>
            )}
          </InputWrapper>
          <InputWrapper>
            <Label htmlFor="country">Country</Label>
            <Select
              name="country"
              id="country"
              onChange={handleChange}
              value={country}
              error={Boolean(countryError)}
              data-test="kyc-step-1-country"
            >
              <Option value="chooseCountry">Choose country</Option>
              {countries.length
                ? countries.map(country => (
                    <Option key={uuid()} value={country}>
                      {country}
                    </Option>
                  ))
                : isCountriesLoading && (
                    <Option value="loading">Loading...</Option>
                  )}
            </Select>
            {countryError && (
              <Error data-test="kys-step1-country-error">{countryError}</Error>
            )}
            {Boolean(countryErrors.length) && (
              <ErrorList>
                {countryErrors.map(error => (
                  <ErrorItem data-test="kyc-step-1-country-error" key={uuid()}>
                    {error}
                  </ErrorItem>
                ))}
              </ErrorList>
            )}
          </InputWrapper>
        </Flex>
        {Boolean(firstStepVerificationErrors.length) && (
          <ErrorList position="static">
            {firstStepVerificationErrors.map(error => (
              <ErrorItem data-test="kyc-step1-general-error" key={uuid()}>
                {<FormattedMessage id={error} />}
              </ErrorItem>
            ))}
          </ErrorList>
        )}
      </Form>
      <ButtonsBlock>
        <Button data-test="kyc-step1-next" onClick={submit} theme="main">
          Next
        </Button>
      </ButtonsBlock>
    </>
  );
};

const mapStateToProps = state => ({
  countries: state.kyc.countries,
  getCountriesErrors: state.kyc.getCountriesErrors,
  firstStepVerificationErrors: state.kyc.firstStepVerificationErrors
});

const mapDispatchToProps = dispatch => ({
  getCountries: () => dispatch(getCountriesAction()),
  sendDataFirstStep: data => dispatch(sendDataFirstStepAction(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KYCFirstStep);
