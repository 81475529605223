// Absolute
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// Styles
import {
  Text,
  Form,
  Flex,
  InputWrapper,
  Label,
  Input,
  Select,
  Option,
  Line,
  Photo,
  Img,
  Icon,
  ButtonsBlock,
  Button
} from "./styles";

// Components
import Steps from "../Steps";

// Images
import user from "./user.png";

// Action creators
import {
  resetProfileAction,
  getUserDataAction,
  getPhotosAction,
  submitProfileAction
} from "../../redux/actionCreators";

const KYCThirdStep = ({
  userData,
  passportPhotoUrl,
  userWithPassportPhotoUrl,
  resetProfile,
  getUserData,
  getUserDataError,
  getPhotos,
  submitProfile,
  approved
}) => {
  const [{ name, surname, passport, country, id }, setState] = useState({
    name: "",
    surname: "",
    passport: "",
    country: "",
    id: ""
  });

  const styles = {
    color: "#fff",
    textDecoration: "none"
    // width: "100%"
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    getUserData(accessToken);
  }, []);

  useEffect(() => {
    if (Object.keys(userData).length === 5) {
      const { name, surname, passport, country, id } = userData;

      setState(values => ({
        ...values,
        name,
        surname,
        passport,
        country,
        id
      }));
      getPhotos(id);
    }
  }, [userData]);

  const reset = () => {
    resetProfile({ id, action: "cancel" });
    window.scrollTo(0, 0);

    setState(values => ({
      ...values,
      name: "",
      surname: "",
      country: "",
      passport: "",
      id: ""
    }));
  };

  const submit = () => {
    submitProfile({ id, action: "submit" });
  };

  return (
    <>
      {!approved && <Text>Step 3: Review</Text>}
      {!approved && <Steps step={3} />}
      <Form>
        <Flex>
          <InputWrapper>
            <Label htmlFor="name">Name</Label>
            <Input readOnly id="name" value={name} />
          </InputWrapper>
          <InputWrapper>
            <Label htmlFor="surname" value={surname}>
              Surname
            </Label>
            <Input readOnly id="surname" value={surname} />
          </InputWrapper>
        </Flex>

        <Flex>
          <InputWrapper>
            <Label htmlFor="passport">Passport/ID</Label>
            <Input readOnly id="passport" value={passport} />
          </InputWrapper>
          <InputWrapper>
            <Label htmlFor="country">Country</Label>
            <Select readOnly name="country" id="country">
              <Option>{country}</Option>
            </Select>
          </InputWrapper>
        </Flex>
        <Line />
        <Flex>
          <Photo noCursor>
            <Img src={passportPhotoUrl && passportPhotoUrl} />
            <Icon src={passportPhotoUrl ? "" : user} />
          </Photo>
          <Photo noCursor>
            <Img src={userWithPassportPhotoUrl && userWithPassportPhotoUrl} />
            <Icon src={userWithPassportPhotoUrl ? "" : user} />
          </Photo>
        </Flex>
      </Form>
      {!approved && (
        <ButtonsBlock>
          <Button
            data-test="kyc-step3-cancel"
            onClick={reset}
            theme="lightGray"
          >
            Cancel
          </Button>
          <Button data-test="kyc-step3-submit" onClick={submit} theme="main">
            Submit
          </Button>
        </ButtonsBlock>
      )}
      {approved && (
        <ButtonsBlock justify="center">
          <Button data-test="kyc-approved-to-home" width="30%" theme="main">
            <Link style={styles} to="/">
              Home Page
            </Link>
          </Button>
        </ButtonsBlock>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  userData: state.kyc.userData,
  passportPhotoUrl: state.kyc.passportPhotoUrl,
  userWithPassportPhotoUrl: state.kyc.userWithPassportPhotoUrl,
  getUserDataError: state.kyc.getUserDataError
});

const mapDispatchToProps = dispatch => ({
  resetProfile: data => dispatch(resetProfileAction(data)),
  getUserData: token => dispatch(getUserDataAction(token)),
  getPhotos: id => dispatch(getPhotosAction(id)),
  submitProfile: data => dispatch(submitProfileAction(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KYCThirdStep);
