import styled from "styled-components";
import { breakpoints } from "../../theme/breakpoints";

export const InputContainer = styled.div`
  position: relative;
  width: 48%;
  height: 50px;

  @media (max-width: ${breakpoints.md}px) {
    width: 100%;
    margin: 5px 0;
  }
`;

export const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  color: #441eda;
  font-size: 16px;
  padding-left: 10px;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
`;

export const Separator = styled.div`
  position: absolute;
  right: 81px;
  top: 13px;
  width: 0px;
  height: 25px;
  border-left: 1px solid #DBDBDB;
  cursor: text;
`;

export const OverlayButton = styled.div`
  position: absolute;
  right: 25px;
  top: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  font-size: 16px;
  color: #2C2A32;
  cursor: pointer;
  user-select: none;
`;
