// Absolute imports
import React from "react";
import { connect } from "react-redux";

// Styles
import { Container } from "../../UI/Container";
import { Text } from "../../UI/Typography";
import { StyledLink } from "../../UI/Typography";

import { Root, Button, H2, Flex, H4, Line } from "./styles";

// Action creators
import { toggleDropDownAction } from "../../../redux/actionCreators";

const ExchangeSection = ({ toggleDropDown }) => {
  return (
    <Root onClick={() => toggleDropDown(false)}>
      <Container>
        <Flex direction="column" justify="space-between" align="flex-start">
          <Flex wrap justify="space-between" margin="0 0 100px 0">
            <H2>Growth. Power. Authority.</H2>
            <Flex
              direction="column"
              width="44%"
              height="200px"
              justify="center"
              align="flex-start"
              marginAuto
              margin="50px 0 0 0"
            >
              <Text color="secondary">
                EEX coin creates an opportunity to optimize the costs of business processes and provide advantages of
                blockchain to community members.
              </Text>
              <Button>
                <StyledLink to="/exchange" data-test="link-to-wallet">
                  Buy EEX Coin
                </StyledLink>
              </Button>
            </Flex>
          </Flex>

          <Flex wrap justify="space-between">
            <Flex direction="column" height="160px" align="flex-start" width="30%">
              <Line />
              <H4> Eco-friendly operation </H4>
              <Text color="secondary">PoA consensus is consuming much less electricity than other consensuses.</Text>
            </Flex>
            <Flex direction="column" height="160px" align="flex-start" width="30%">
              <Line />
              <H4>Advanced financial tools</H4>
              <Text color="secondary">Enjoy most convenient P2P money transfer with “shaking money transfer”.</Text>
            </Flex>
            <Flex direction="column" height="160px" align="flex-start" width="30%">
              <Line />
              <H4>High-speed transactions</H4>
              <Text color="secondary">EEX coin speed is 200 transactions per second.</Text>
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </Root>
  );
};

const mapDispatchToProps = dispatch => ({
  toggleDropDown: isShown => dispatch(toggleDropDownAction(isShown))
});

export default connect(
  null,
  mapDispatchToProps
)(ExchangeSection);
