/**
 * Absolute imports
 */
import styled from 'styled-components/macro';

export const SVG = styled.svg`
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.6rem;
  color: currentColor;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;

  ${({ gutterLeft }) => gutterLeft && `margin-left: 16px;`}
  ${({ gutterRight }) => gutterRight && `margin-right: 16px;`}
`;
