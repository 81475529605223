// Absolute imports
import React from "react";

// Components
import SubscribeForm from "../../SubscribeForm";
import { H1, Text } from "../../UI/Typography";

// Styles
import { Root, Container } from "./styles";

const Subscribe = () => (
  <>
    <Root>
      <Container>
        {/* <H1 color="secondary">Be the first to know things</H1> */}
        <H1 margin="0 0 20px 0">Be the first to know things</H1>
        {/* <Text color="secondary" align="center">
            Leave your email address and we will inform you about the latest
            news!
          </Text> */}
        <Text size="ml" margin="0 0 20px 0" align="center">
          Leave your email address and we will inform you about the latest news!
        </Text>
        <SubscribeForm />
      </Container>
    </Root>
  </>
);

export default Subscribe;
