// Absolute imports
import React from "react";
import styled from "styled-components/macro";

// Images
import bgImageTopLeft from "./img/bg-left-top.png";
import bgImageRightBottom from "./img/bg-right-bottom.png";

// Theme
import { breakpoints } from "../../../theme/breakpoints";

export const Root = styled.section`
  min-height: 100vh;
  display: flex;
  padding: 140px 0 100px 0;
  align-items: center;
  justify-content: center;

  background-image: url(${bgImageTopLeft}), url(${bgImageRightBottom}),
    linear-gradient(to left, #261066, #3a1269, #471368, #59176c);
  background-position: left top, right bottom, center center;
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-size: 639px 650px, 560px 663px, 100% 100%;

  @media (max-width: ${breakpoints.md}px) {
    background-size: 539px 450px, 460px 463px, 100% 100%;
  }

  @media (max-width: ${breakpoints.sm}px) {
    background-size: 339px 250px, 260px 263px, 100% 100%;
  }
`;

export const Button = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  color: #fff;
  background: #441eda;
  border-radius: 4px;
  width: 30%;
  height: 50px;
  margin: 30px 0 0 0;
  font-size: 18px;
  font-family: "Roboto", sans-serif;

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 16px;
  }
`;

export const H2 = styled.h2`
  color: #fff;
  display: flex;
  justify-content: center;
  font-size: 48px;
  text-align: center;
  font-family: "Fira Sans", sans-serif;
  margin: 30px 0;
  white-space: nowrap;

  @media (max-width: ${breakpoints.lg}px) {
    font-size: 46px;
    width: 100%;
  }

  @media (max-width: ${breakpoints.md}px) {
    font-size: 44px;
    width: 100%;
  }

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 24px;
    margin: 0;
  }
`;

export const Flex = styled(({ wrap, marginAuto, ...props }) => <div {...props} />)`
  display: flex;
  align-items: center;
  width: 100%;

  ${({ align }) => align && `align-items: ${align}`};
  ${({ width }) => width && `width: ${width}`};
  ${({ wrap }) => wrap && `flex-wrap: wrap`};

  ${({ margin }) => margin && `margin: ${margin}`};

  ${({ height }) => height && `height: ${height}`};
  ${({ justify }) => justify && `justify-content: ${justify}`};
  ${({ direction }) => direction && `flex-direction: ${direction}`};

  @media (max-width: ${breakpoints.lg}px) {
    align-items: center;
    ${({ marginAuto }) => marginAuto && `margin: auto; width: 46%`};
    margin-bottom: 30px;
  }

  @media (max-width: ${breakpoints.md}px) {
    width: 100%;
    ${({ marginAuto }) => marginAuto && `margin: auto; width: 76%`};
    margin-bottom: 0;
    justify-content: center;
  }

  @media (max-width: ${breakpoints.sm}px) {
    ${({ marginAuto }) => marginAuto && `margin: auto; width: 100%`};
  }
`;

export const Line = styled.div`
  width: 100px;
  background: #fff;
  height: 1px;

  @media (max-width: ${breakpoints.sm}px) {
    margin-bottom: 30px;
  }
`;

export const H4 = styled.h2`
  color: #fff;
  display: flex;
  justify-content: center;
  font-size: 24px;
  text-align: center;
  font-family: "Fira Sans", sans-serif;
  margin: 30px 0;

  @media (max-width: ${breakpoints.lg}px) {
    font-size: 22px;
  }

  @media (max-width: ${breakpoints.md}px) {
    font-size: 20px;
  }

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 18px;
    margin: 0;
  }
`;
