// Absolute imports
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import { Switch, Route, Redirect } from "react-router-dom";
import ReactGa from "react-ga";

// Components
// import Header from "../Header";
// import ExchangeSection from "../Screens/ExchangeSection";
// import Instructions from "../Screens/Instructions";
// import Subscribe from "../Screens/Subscribe";
import SignUp from "../SignUp";
import SignIn from "../SignIn";
import Restore from "../Restore";
import SuccessPopUp from "../SuccessPopUp";
import ErrorPopUp from "../ErrorPopUp";
import NewPasswordForm from "../NewPasswordForm";
import KYC from "../KYC";
import PageNotFound from "../PageNotFound";
import Main from "../Main";
import PrivateRoute from "../PrivateRoute";
import ProtectedRoute from "../ProtectedRoute";
import ProfileSettings from "../ProfileSettings";
import Exchange from "../Exchange";
import ExchangeComplete from "../ExchangeComplete";
import PageLoader from "../PageLoader";

// Theme
import GlobalStyle from "../../theme/global-style";

// Action creators
import {
  verifyUserAction,
  checkIsLoggedInAction,
  setUserEmailAction,
  checkUserStatusAction,
  setResetTokenAction,
  setLogInAction
} from "../../redux/actionCreators";

// Intl messages
import messages from "../../locale";

// Routes
import * as routes from "../../routes";

const App = ({
  verifyUser,
  isEmailVerified,
  checkIsLoggedIn,
  resendEmailError,
  setUserEmail,
  isUserCreated,
  attemptForCreateUser,
  createUserError,
  isEmailResent,
  isPasswordChanged,
  isPasswordSent,
  attemptForResendPassword,
  attemptForResendEmail,
  resendPasswordError,
  attemptForPasswordChange,
  checkUserStatus,
  accessToken,
  lang,
  setResetToken,
  changePasswordError,
  isLoggedIn,
  noPopUp,
  setLogIn,
  isExchangeDataReady
}) => {
  const [
    {
      isVerificationPopUpShown,
      isUserCreatedPopUpShown,
      isRequestForCreateUserFailed,
      isEmailResentSuccess,
      isEmailResentFailed,
      isPasswordResent,
      isPasswordChangedFailed
    },
    setPopUps
  ] = useState({
    isVerificationPopUpShown: false,
    isUserCreatedPopUpShown: false,
    isRequestForCreateUserFailed: false,
    isEmailResentSuccess: false,
    isEmailResentFailed: false,
    isPasswordResent: false,
    isPasswordChangedFailed: false
  });

  useEffect(() => {
    if (localStorage.getItem("accessToken") || localStorage.getItem("userEmail")) {
      const accessToken = localStorage.getItem("accessToken");
      const userEmail = localStorage.getItem("userEmail");

      checkIsLoggedIn(accessToken);
      setUserEmail(userEmail);
      checkUserStatus(accessToken);
      return;
    } else {
      setLogIn(false);
    }

    if (window.location.pathname.includes("sign-up/verify")) {
      const url = window.location.pathname;
      const verifyToken = url.slice(url.lastIndexOf("/") + 1);

      verifyUser({ verifyToken });
    }

    if (window.location.pathname.includes("reset")) {
      const url = window.location.pathname;
      const resetToken = url.slice(url.lastIndexOf("/") + 1);

      setResetToken(resetToken);

      window.history.pushState("create_new_password", "", "/create-new-password");
    }

    // Google analytics
    ReactGa.initialize("G-GC5VLXZ9K7");
    ReactGa.pageview(window.location.pathname);
  }, []);

  useEffect(() => {
    if (accessToken) {
      checkUserStatus(accessToken);
    }
  }, [accessToken]);

  useEffect(() => {
    if (isEmailVerified) {
      window.history.pushState("sign_in", "", "/signin");

      if (!noPopUp) {
        setPopUps(values => ({
          ...values,
          isVerificationPopUpShown: true
        }));
        setTimeout(() => {
          setPopUps(values => ({
            ...values,
            isVerificationPopUpShown: false
          }));
        }, 2000);
      }
    }
  }, [isEmailVerified, noPopUp]);

  useEffect(() => {
    if (isUserCreated) {
      setPopUps(values => ({
        ...values,
        isUserCreatedPopUpShown: true
      }));
      setTimeout(() => {
        setPopUps(values => ({
          ...values,
          isUserCreatedPopUpShown: false
        }));
      }, 2000);
    }
  }, [isUserCreated, attemptForCreateUser]);

  useEffect(() => {
    if (createUserError) {
      setPopUps(values => ({
        ...values,
        isRequestForCreateUserFailed: true
      }));
      setTimeout(() => {
        setPopUps(values => ({
          ...values,
          isRequestForCreateUserFailed: false
        }));
      }, 2000);
    }
  }, [createUserError, attemptForCreateUser]);

  useEffect(() => {
    if (isEmailResent) {
      setPopUps(values => ({
        ...values,
        isEmailResentSuccess: true
      }));
      setTimeout(() => {
        setPopUps(values => ({
          ...values,
          isEmailResentSuccess: false
        }));
      }, 2000);
    } else if (isEmailResent === false) {
      setPopUps(values => ({
        ...values,
        isEmailResentFailed: true
      }));
      setTimeout(() => {
        setPopUps(values => ({
          ...values,
          isEmailResentFailed: false
        }));
      }, 2000);
    }
  }, [isEmailResent, attemptForResendEmail]);

  useEffect(() => {
    if (isPasswordSent) {
      setPopUps(values => ({
        ...values,
        isPasswordResent: true
      }));
      setTimeout(() => {
        setPopUps(values => ({
          ...values,
          isPasswordResent: false
        }));
      }, 2000);
    } else if (isPasswordSent === false) {
      setPopUps(values => ({
        ...values,
        isPasswordChangedFailed: true
      }));
      setTimeout(() => {
        setPopUps(values => ({
          ...values,
          isPasswordChangedFailed: false
        }));
      }, 2000);
    }
  }, [isPasswordSent, attemptForResendPassword]);

  useEffect(() => {
    if (!isPasswordChanged === false && changePasswordError) {
      setPopUps(values => ({
        ...values,
        isPasswordChangedFailed: true
      }));
      setTimeout(() => {
        setPopUps(values => ({
          ...values,
          isPasswordChangedFailed: false
        }));
      }, 2000);
    }
  }, [isPasswordChanged, attemptForPasswordChange, changePasswordError]);

  return (
    <IntlProvider locale={lang} messages={messages[lang]}>
      <GlobalStyle />
      {isRequestForCreateUserFailed && <ErrorPopUp text={createUserError} />}
      {isUserCreatedPopUpShown && <SuccessPopUp text="User was successfully created." />}
      {isVerificationPopUpShown && <SuccessPopUp text="Verification was successful." />}
      {isEmailResentSuccess && <SuccessPopUp text="Email has been sent." />}
      {isEmailResentFailed && <ErrorPopUp text={resendEmailError} />}
      {isPasswordResent && <SuccessPopUp text="Password has been sent." />}
      {isPasswordChangedFailed && <ErrorPopUp text={"Try again in a minute."} />}

      <Switch>
        <Route exact path="/" component={Main} />
        <Route
          exact
          path="/sign-up/verify/:verifyToken"
          render={() => (isLoggedIn ? <Redirect to="/" /> : <SignIn />)}
        />
        <Route exact path="/reset/:resetToken" component={NewPasswordForm} />
        <Route
          exact
          path="/signup"
          render={routerProps => (isLoggedIn ? <Redirect to="/" /> : <SignUp routerProps={routerProps} />)}
        />
        <Route exact path="/signin" render={() => (isLoggedIn ? <Redirect to="/" /> : <SignIn />)} />
        <Route exact path="/restore" render={() => (isLoggedIn ? <Redirect to="/" /> : <Restore />)} />
        <Route
          exact
          path="/create-new-password"
          render={() => (isLoggedIn ? <Redirect to="/" /> : <NewPasswordForm />)}
        />

        <PrivateRoute exact path={routes.SETTINGS} component={ProfileSettings} />

        <PrivateRoute exact path={routes.KYC} component={KYC} />

        <ProtectedRoute exact path={routes.EXCHANGE} condition={isExchangeDataReady} component={Exchange} />

        <ProtectedRoute exact path={routes.COMPLETE_EXCHANGE} condition={isLoggedIn} component={ExchangeComplete} />

        <Route exact path="/:any" component={PageNotFound} />
      </Switch>
    </IntlProvider>
  );
};

const mapStateToProps = state => ({
  isEmailVerified: state.registration.isEmailVerified,
  isUserCreated: state.registration.isUserCreated,
  createUserError: state.registration.createUserError,
  attemptForCreateUser: state.registration.attemptForCreateUser,
  resendEmailError: state.registration.resendEmailError,
  isEmailResent: state.registration.isEmailResent,
  isPasswordChanged: state.registration.isPasswordChanged,
  isPasswordSent: state.registration.isPasswordSent,
  attemptForResendPassword: state.registration.attemptForResendPassword,
  attemptForResendEmail: state.registration.attemptForResendEmail,
  resendPasswordError: state.registration.resendPasswordError,
  attemptForPasswordChange: state.registration.attemptForPasswordChange,
  accessToken: state.registration.accessToken,
  lang: state.locale.lang,
  changePasswordError: state.registration.changePasswordError,
  isLoggedIn: state.registration.isLoggedIn,
  noPopUp: state.registration.noPopUp,
  isExchangeDataReady:
    state.registration.isLoggedIn === false ||
    (state.eexPurchase.initialFetch.limitReady && state.eexPurchase.initialFetch.userTransactionsReady)
});

const mapDispatchToProps = dispatch => ({
  verifyUser: data => dispatch(verifyUserAction(data)),
  checkIsLoggedIn: accessToken => dispatch(checkIsLoggedInAction(accessToken)),
  setUserEmail: email => dispatch(setUserEmailAction(email)),
  checkUserStatus: token => dispatch(checkUserStatusAction(token)),
  setResetToken: token => dispatch(setResetTokenAction(token)),
  setLogIn: isLoggedIn => dispatch(setLogInAction(isLoggedIn))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
