import * as types from "../types";

const generalRatesState = {
  btcPriceInEex: 0,
  ethPriceInEex: 0
};

export default function generalRatesReducer(state = generalRatesState, { type, payload }) {
  switch (type) {
    case types.FETCH_GENERAL_RATES_SUCCESS:
      return {
        ...state,
        btcPriceInEex: payload.btcPriceInEex,
        ethPriceInEex: payload.ethPriceInEex
      };
    default:
      return state;
  }
}
