import styled from "styled-components";

// Breakpoints
import { breakpoints } from "../../theme/breakpoints";

const sizes = {
  sm: "padding: 10px;",
  lg: "width: 100px; height: 100px;"
};

const themes = {
  main: "background: #441eda; color: #fff;",
  light: "border: 1px solid #441eda; color: #441eda;",
  lightViolet: "border: 1px solid #a987e5; background: #a987e5; color: #fff;",
  lighterViolet: "background: rgba(68, 30, 218, .3);"
};

export const Root = styled.section`
  min-height: 64vh;
  color: #2c2a32;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 80px 0;

  @media (max-width: ${breakpoints.sm}px) {
    padding: 0;
  }
`;

export const H3 = styled.h2`
  width: 44%;
  margin: 0 0 20px 0;

  @media (max-width: ${breakpoints.lg}px) {
    width: 58%;
  }

  @media (max-width: ${breakpoints.md}px) {
    width: 76%;
  }

  @media (max-width: ${breakpoints.sm}px) {
    width: 90%;
    margin: 20px 0;
  }
`;

export const Form = styled.form`
  width: 44%;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 40px;

  @media (max-width: ${breakpoints.lg}px) {
    width: 58%;
    padding: 30px;
  }

  @media (max-width: ${breakpoints.md}px) {
    width: 76%;
    padding: 20px;
  }

  @media (max-width: ${breakpoints.sm}px) {
    width: 90%;
    padding: 0;
    border: none;
  }
`;

export const Flex = styled.div`
  display: flex;
  width: 100%;
  ${({ justify }) => justify && `justify-content: ${justify};`}

  ${({ align }) => align && `align-items: ${align};`}
  ${({ width }) => width && `width: ${width};`}
  ${({ direction }) => direction && `flex-direction: ${direction};`}
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom};`}

  @media (max-width: ${breakpoints.sm}px) {
    margin: 0;
  }
`;

export const Info = styled.p`
  margin: 0;
  font-size: 14px;
  color: #a8a5b4;

  ${({ width }) => width && `width: ${width};`}
  ${({ align }) => align && `text-align: ${align};`}
  ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft};`}
  ${({ color }) => color && `color: ${color};`}
  ${({ position }) => position && `position: ${position};left: 24%; top: 50px;`}
  ${({ size }) => size && `font-size: ${size};`}
  ${({ bold }) => bold && `font-weight: 600;`}

  @media (max-width: ${breakpoints.sm}px) {
    margin-left: 5px;
    left: 27%;
    top: 50px;
    font-size: 12px;
    &:first-child {
      width: 100%;
    }
  }
`;

export const Photo = styled.div`
  border: 1px solid #7b7a7f;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  height: 100px;
  margin-right: 30px;

  @media (max-width: ${breakpoints.sm}px) {
    margin-right: 5px;
  }
`;

export const Button = styled.button`
  border: 1px solid #7b7a7f;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #7b7a7f;
  font-size: 14px;
  width: 42%;
  padding: 8px 0;
  margin-top: 10px;
  background: #fff;
  position: relative;

  @media (max-width: ${breakpoints.md}px) {
    width: 50%;
  }

  @media (max-width: ${breakpoints.sm}px) {
    width: 90%;
    margin-left: 5px;
  }
`;

export const FileInput = styled.input`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  cursor: pointer;
`;

export const Img = styled.img`
  object-fit: cover;
  border-radius: 14px;
  ${({ size }) => size && `${sizes[size]};`}
`;

export const Text = styled.p`
  font-size: 20px;
  font-weight: bold;
  margin: 15px 0;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #dbdbdb;
`;

export const Label = styled.label`
  width: 100%;
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 14px;
  }
`;

export const Input = styled.input`
  width: 76%;
  border-radius: 4px;
  padding: 10px 40px 10px 10px;
  height: 40px;
  border: 1px solid #dbdbdb;
  color: #2c2a32;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ disable }) => disable && `background: #FAFAFA;`}

  &::placeholder {
    font-size: 14px;
  }

  @media (max-width: ${breakpoints.sm}px) {
    width: 72%;
    font-size: 12px;
    padding-right: 30px;

    &::placeholder {
      font-size: 12px;
    }
  }
`;

export const Icon = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  width: 20px;
  height: 20px;

  ${({ active }) => active && `border: 2px solid rgba(17,196,133,.7);`}
  @media (max-width: ${breakpoints.sm}px) {
    right: 5px;
  }
  
`;

export const CheckBoxWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 10px 0;
`;

export const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 26px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 4px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;

export const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  &:checked + ${CheckBoxLabel} {
    background: #441eda;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;

export const Span = styled.span`
  color: #2c2a32;
  font-size: 16px;
  font-family: "Roboto", sans-serif;

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 14px;
  }
`;

export const ButtonsBlock = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 40px 0;

  @media (max-width: ${breakpoints.sm}px) {
    margin: 20px 0 30px;
  }
`;

export const BottomButton = styled.button`
  width: 40%;
  height: 50px;
  border: none;
  border-radius: 4px;
  margin: 0 10px;
  font-size: 16px;
  cursor: pointer;
  background: #fff;

  ${({ theme }) => theme && `${themes[theme]};`}

  @media (max-width: ${breakpoints.lg}px) {
    width: 46%;
  }

  @media (max-width: ${breakpoints.sm}px) {
    width: 48%;
    margin: 0;

    &:nth-child(2) {
      margin-left: 10px;
    }
  }
`;

export const GeneralError = styled.div`
  color: #ed2222;
  margin: 10px;
`;
