import { connectRouter } from "connected-react-router";
import { persistCombineReducers } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createFilter } from "redux-persist-transform-filter";
import registrationReducer from "./registrationReducer";
import subscribeReducer from "./subscribeReducer";
import kycReducer from "./kycReducer";
import localeReducer from "./localeReducer";
import settingsReducer from "./settingsReducer";
import generalRatesReducer from "./generalRatesReducer";
import exchangeAddressReducer from "./exchangeAddressReducer";
import eexPurchaseReducer from "./eexPurchaseReducer";

const persistEexSubsetFilter = createFilter("eexPurchase", ["isFirstTimeInfoShown"], null, "whitelist");

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["eexPurchase"],
  transforms: [persistEexSubsetFilter]
};

export const createRootReducer = history =>
  persistCombineReducers(persistConfig, {
    router: connectRouter(history),
    generalRates: generalRatesReducer,
    exchangeAddress: exchangeAddressReducer,
    eexPurchase: eexPurchaseReducer,
    subscribe: subscribeReducer,
    registration: registrationReducer,
    kyc: kycReducer,
    locale: localeReducer,
    settings: settingsReducer
  });
