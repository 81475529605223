import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// Styles
import {
  Root,
  Logo,
  LogoImg,
  LogoDescription,
  Menu,
  MobileMenu
} from "./styles";

// Components
import Navigation from "../Navigation";
import MobileNavigation from "../MobileNavigation";
import Hamburger from "../Hamburger";

// Img
import siteLogoImg from "./site-logo.svg";

// Action creators
import { toggleDropDownAction } from "../../redux/actionCreators";

const KYCHeader = ({ toggleDropDown }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleToggleMenu = () => {
    setIsMenuOpen(state => !state);
  };

  const closeDropDown = event => {
    if (event.target.tagName !== "A") {
      toggleDropDown(false);
    }
  };

  return (
    <Root onClick={closeDropDown} active={isMenuOpen}>
      <Logo>
        <Link to="/">
          <LogoImg src={siteLogoImg} />
        </Link>
        <LogoDescription>
          Ethereum <br /> Express <br /> Coin
        </LogoDescription>
      </Logo>

      <Navigation isKycOpened />

      <MobileNavigation
        active={isMenuOpen}
        handleToggleMenu={handleToggleMenu}
        isKycOpened
      />

      <Menu>
        <MobileMenu>
          <Hamburger active={isMenuOpen} onClick={handleToggleMenu} />
        </MobileMenu>
      </Menu>
    </Root>
  );
};

const mapDispatchToProps = dispatch => ({
  toggleDropDown: isShown => dispatch(toggleDropDownAction(isShown))
});

export default connect(
  null,
  mapDispatchToProps
)(KYCHeader);
