// Absolute imports
import React, { useState, useEffect } from "react";
import { isEmail } from "validator";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// Styles
import {
  Root,
  Logo,
  LogoImg,
  LogoDescription,
  FormWrapper,
  Label,
  Input,
  Error,
  Button,
  H2,
  Info,
  Text
} from "./styles";
import { linkStyles } from "../../theme/linkStyles";

// Components
import PasswordValidation from "../PasswordValidation";
import PageNotFound from "../PageNotFound";

// Images
import siteLogoImg from "./site-logo.svg";

// Action creators
import {
  createUserAction,
  resendEmailAction,
  checkUserRegistrationAction,
  resetIsRegisteredAction
} from "../../redux/actionCreators";

const SignUp = ({
  createUser,
  createUserError,
  isUserCreated,
  isRegistered,
  resendEmail,
  checkUserRegistration,
  registeredEmail,
  attempt,
  isEmailResent,
  resetIsRegistered,
  attemptForCreateUser,
  routerProps,
  isLoggedIn
}) => {
  const [{ step, email, emailError, isBtnDisabled }, setState] = useState({
    step: 1,
    email: "",
    emailError: null,
    isBtnDisabled: false
  });

  useEffect(() => {
    window.scrollTo(0, 0);

    if (isUserCreated) {
      setState(values => ({
        ...values,
        step: 3
      }));
    }
  }, [isUserCreated, attemptForCreateUser]);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (isRegistered === false) {
      setState(values => ({
        ...values,
        step: 2
      }));
    } else if (isRegistered && attempt >= 1) {
      setState(values => ({
        ...values,
        email: "",
        emailError: "User already exists."
      }));
    } else {
      setState(values => ({
        ...values,
        step: 1
      }));
    }
  }, [isRegistered, registeredEmail, attempt]);

  useEffect(() => {
    if (isEmailResent) {
      setState(values => ({
        ...values,
        isBtnDisabled: true
      }));
    }
  }, [isEmailResent]);

  const validateEmail = email => {
    if (!email) {
      return "This field is required";
    }
    if (!isEmail(email)) {
      return "Email is not valid";
    }
    return null;
  };

  const nextStep = (event, password) => {
    if (step === 1) {
      const error = validateEmail(email);

      if (error) {
        setState(values => ({
          ...values,
          emailError: error,
          email: ""
        }));

        return;
      }

      checkUserRegistration(email);
    }

    if (step === 2) {
      createUser({ email, password });
    }
  };

  const handleChange = event => {
    const {
      target: { value, name }
    } = event;

    setState(values => ({
      ...values,
      [name]: value
    }));
  };

  const handleSubmit = event => {
    event.preventDefault();

    resetIsRegistered();
    routerProps.history.push("/");
  };

  const sendAgain = () => {
    resendEmail(email);
  };

  const resetSteps = () => {
    resetIsRegistered();
  };

  return (
    <>
      {isLoggedIn ? (
        <PageNotFound />
      ) : (
        <Root>
          <Logo>
            <Link to="/" data-test="logo">
              <LogoImg src={siteLogoImg} alt="Logo" />
            </Link>
            <LogoDescription>
              Ethereum
              <br /> Express
              <br /> Coin
            </LogoDescription>
          </Logo>

          <FormWrapper>
            <form
              onSubmit={event => {
                event.preventDefault();
              }}
            >
              {step === 1 && (
                <>
                  <H2>Sign up</H2>
                  <Label>
                    Email <br />
                    <Input
                      value={email}
                      onChange={handleChange}
                      type="text"
                      name="email"
                      placeholder="Enter email"
                      error={emailError}
                      data-test="sign-up-email"
                    />
                  </Label>
                  {emailError && (
                    <Error data-test="sign-up-email-error">{emailError}</Error>
                  )}
                  <br />
                  <Button data-test="sign-up-button" onClick={nextStep}>
                    Sign up
                  </Button>
                  <Info data-test="sign-up-to-sign-in">
                    <Link style={linkStyles} to="/signin">
                      I already have an account
                    </Link>
                  </Info>
                </>
              )}

              {step === 2 && (
                <>
                  <H2>Create password</H2>
                  <PasswordValidation submit={nextStep} />
                  <Info data-test="sign-up-to-sign-in" onClick={resetSteps}>
                    <Link to="signin" style={linkStyles}>
                      I already have an account
                    </Link>
                  </Info>
                </>
              )}
              {step === 3 && (
                <>
                  <H2>Confirm your email</H2>
                  <Text>
                    We have send you confirmation link. Please activate your
                    account!
                  </Text>
                  <Button data-test="main" onClick={handleSubmit}>
                    {/* <Link
                      style={{ color: "#fff", textDecoration: "none" }}
                      to="/"
                    > */}
                    OK
                    {/* </Link> */}
                  </Button>
                  <Info
                    color={isBtnDisabled ? "lightViolet" : "main"}
                    onClick={isBtnDisabled ? () => {} : sendAgain}
                    data-test="send-again"
                  >
                    Send again
                  </Info>
                </>
              )}
            </form>
          </FormWrapper>
        </Root>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  isUserCreated: state.registration.isUserCreated,
  isRegistered: state.registration.isRegistered,
  registeredEmail: state.registration.registeredEmail,
  attempt: state.registration.attempt,
  isEmailResent: state.registration.isEmailResent,
  attemptForCreateUser: state.registration.attemptForCreateUser,
  isLoggedIn: state.registration.isLoggedIn
});

const mapDispatchToProps = dispatch => ({
  createUser: userData => dispatch(createUserAction(userData)),
  resendEmail: email => dispatch(resendEmailAction(email)),
  checkUserRegistration: email => dispatch(checkUserRegistrationAction(email)),
  resetIsRegistered: () => dispatch(resetIsRegisteredAction())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUp);
