// Absolute imports
import React, { useState } from "react";
import { connect } from "react-redux";

// Components
import MobileNavigation from "../MobileNavigation";
import Navigation from "../Navigation";
import Hamburger from "../Hamburger";
import { Container } from "../UI/Container";

// Styles
import {
  Root,
  Inner,
  Logo,
  LogoImg,
  LogoDescription,
  MobileMenu,
  Menu
} from "./styles";

// Img
import siteLogoImg from "./site-logo.svg";

// Action creators
import { toggleDropDownAction } from "../../redux/actionCreators";

const Header = ({ toggleDropDown }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleToggleMenu = () => {
    setIsMenuOpen(state => !state);
  };

  const closeDropDown = event => {
    if (event.target.tagName !== "A") {
      toggleDropDown(false);
    }
  };

  return (
    <Root active={isMenuOpen} onClick={closeDropDown}>
      <Container>
        <Inner>
          <Logo>
            <LogoImg src={siteLogoImg} alt="Ethereum express coin" />
            <LogoDescription>
              ETHEREUM
              <br />
              EXPRESS
              <br />
              COIN
              <br />
            </LogoDescription>
          </Logo>

          <Navigation />
          <MobileNavigation
            active={isMenuOpen}
            handleToggleMenu={handleToggleMenu}
          />

          <Menu>
            <MobileMenu>
              <Hamburger active={isMenuOpen} onClick={handleToggleMenu} />
            </MobileMenu>
          </Menu>
        </Inner>
      </Container>
    </Root>
  );
};

const mapDispatchToProps = dispatch => ({
  toggleDropDown: isShown => dispatch(toggleDropDownAction(isShown))
});

export default connect(
  null,
  mapDispatchToProps
)(Header);
