// Absolute imports
import React, { useState, useEffect } from "react";
import { isEmail } from "validator";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// Styles
import {
  Root,
  Logo,
  LogoImg,
  LogoDescription,
  FormWrapper,
  Label,
  Input,
  Error,
  Button,
  H2,
  Info,
  Text,
  Emoji
} from "./styles";
import { linkStyles } from "../../theme/linkStyles";

// Components
import Loading from "../Loading";
import PageNotFound from "../PageNotFound";

// Images
import siteLogoImg from "./site-logo.svg";

// Action creators
import { resendPasswordAction } from "../../redux/actionCreators";

const Restore = ({
  resendPassword,
  isPasswordSent,
  attemptForResendPassword,
  isUserCreated,
  resendPasswordError,
  isLoggedIn
}) => {
  const [
    { step, email, emailError, isLoading, isBtnDisabled, timesClicked },
    setState
  ] = useState({
    step: 1,
    email: "",
    emailError: null,
    isLoading: false,
    isBtnDisabled: false,
    timesClicked: 0
  });

  useEffect(() => {
    window.scrollTo(0, 0);

    if (isPasswordSent && step < 2) {
      setState(values => ({
        ...values,
        step: values.step + 1
      }));
    } else if (isPasswordSent === false) {
      setState(values => ({
        ...values,
        isLoading: false,
        isBtnDisabled: false,
        email: "",
        emailError: resendPasswordError
      }));
    }
  }, [isPasswordSent, attemptForResendPassword]);

  useEffect(() => {
    setState(values => ({
      ...values,
      isLoading: false
    }));
  }, [isUserCreated]);

  const nextStep = () => {
    if (step === 1) {
      const emailError = validateEmail(email);

      if (emailError) {
        setState(values => ({
          ...values,
          emailError,
          email: ""
        }));
        return;
      }

      setState(values => ({
        ...values,
        isLoading: true,
        isBtnDisabled: true,
        emailError: null
      }));

      resendPassword(email);
    }
  };

  const validateEmail = email => {
    if (!email) {
      return "This field is required";
    }
    if (!isEmail(email)) {
      return "Email is not valid";
    }
    return null;
  };

  const handleChange = event => {
    const {
      target: { value, name }
    } = event;

    setState(values => ({
      ...values,
      [name]: value
    }));
  };

  const sendAgain = () => {
    if (timesClicked < 1) {
      resendPassword(email);
    }

    setState(values => ({
      ...values,
      timesClicked: values.timesClicked + 1
    }));
  };

  const handleSubmit = event => {
    event.preventDefault();
    setState(values => ({
      ...values,
      step: 1
    }));
  };

  return (
    <>
      {isLoggedIn ? (
        <PageNotFound />
      ) : (
        <Root>
          <Logo>
            <Link to="/" data-test="logo">
              <LogoImg src={siteLogoImg} alt="Logo" />
            </Link>
            <LogoDescription>
              Ethereum
              <br /> Express
              <br /> Coin
            </LogoDescription>
          </Logo>

          <FormWrapper>
            <form onSubmit={handleSubmit}>
              {step === 1 && (
                <>
                  <H2>Restore password</H2>
                  <Label>
                    Email <br />
                    <Input
                      value={email}
                      onChange={handleChange}
                      type="text"
                      name="email"
                      placeholder="Enter your email to send recovery letter"
                      error={emailError}
                      data-test="restore-email"
                    />
                  </Label>

                  {emailError && (
                    <Error data-test="restore-email-error">{emailError}</Error>
                  )}

                  <Button
                    background={isLoading ? "lightViolet" : "main"}
                    onClick={nextStep}
                    disabled={isBtnDisabled}
                    data-test="restore-button"
                  >
                    {isLoading ? <Loading /> : <span>Restore password</span>}
                  </Button>
                  <Info data-test="restore-to-sign-in">
                    <Link to="/signin" style={linkStyles}>
                      I have remembered my password
                    </Link>
                  </Info>
                </>
              )}

              {step === 2 && (
                <>
                  <H2>Email sent!</H2>
                  <Text>
                    We have sent you an email with further instructions to
                    restore your password. Please check your inbox{" "}
                    <Emoji role="img" aria-label="clap">
                      😉
                    </Emoji>{" "}
                  </Text>
                  <Button data-test="restore-to-sign-in">
                    <Link
                      style={{ color: "#fff", textDecoration: "none" }}
                      to="signin"
                    >
                      Back to sign in
                    </Link>
                  </Button>
                  <Info
                    color={timesClicked > 0 ? "lightViolet" : "main"}
                    onClick={timesClicked > 0 ? () => {} : sendAgain}
                    data-test="restore-send-again"
                  >
                    Send again
                  </Info>{" "}
                </>
              )}
            </form>
          </FormWrapper>
        </Root>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  isPasswordSent: state.registration.isPasswordSent,
  isUserCreated: state.registration.isUserCreated,
  attemptForResendPassword: state.registration.attemptForResendPassword,
  resendPasswordError: state.registration.resendPasswordError,
  isLoggedIn: state.registration.isLoggedIn
});

const mapDispatchToProps = dispatch => ({
  resendPassword: email => dispatch(resendPasswordAction(email))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Restore);
