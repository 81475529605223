import { put, take, takeLatest, select, race, delay } from "redux-saga/effects";
import * as types from "../types";
import * as actions from "../actionCreators/exchangeAddressActions";
import * as exchangeApi from "./services/exchangeApi";

export function* fetchBtcExchangeAddress() {
  const accessToken = localStorage.getItem("accessToken");
  const { error, result } = yield exchangeApi.getBtcExchangeAddress(accessToken);
  if (error) {
    yield put(actions.fetchBtcExchangeAddressFailure(error));
  } else {
    yield put(actions.fetchBtcExchangeAddressSuccess(result));
  }
}

export function* fetchEthExchangeAddress() {
  const { error, result } = yield exchangeApi.getEthExchangeAddress();
  if (error) {
    yield put(actions.fetchEthExchangeAddressFailure(error));
  } else {
    yield put(actions.fetchEthExchangeAddressSuccess(result));
  }
}

export function* fetchBtcExchangeAddressOnEverySignIn() {
  while (true) {
    const { login, fetchFailure } = yield race({
      login: take(types.SET_LOG_IN),
      fetchFailure: take(types.FETCH_BTC_EXCHANGE_ADDRESS_FAILURE)
    });
    if (login.payload || fetchFailure) {
      yield put(actions.fetchBtcExchangeAddress());
      yield delay(1000);
    }
  }
}

export function* fetchEthExchangeAddressOnce() {
  while (true) {
    const address = yield select(s => s.exchangeAddress.ethExchangeAddress);
    if (!address) {
      yield put(actions.fetchEthExchangeAddress());
    }
    yield take(types.FETCH_ETH_EXCHANGE_ADDRESS_FAILURE);
    yield delay(1000);
  }
}

export function* watchFetchBtcExchangeAddress() {
  yield takeLatest(types.FETCH_BTC_EXCHANGE_ADDRESS, fetchBtcExchangeAddress);
}

export function* watchFetchEthExchangeAddress() {
  yield takeLatest(types.FETCH_ETH_EXCHANGE_ADDRESS, fetchEthExchangeAddress);
}
