import { call, takeLatest, put, all, spawn } from "redux-saga/effects";
import * as types from "../types";
import * as generalRatesSagas from "./generalRatesSagas";
import * as eexPurchaseSagas from "./eexPurchaseSagas";
import * as exchangeAddressSagas from "./exchangeAddressSagas";

function* subscribeSaga(action) {
  const body = JSON.stringify({
    email: action.payload
  });

  try {
    const data = yield call(() => {
      return fetch(`${process.env.REACT_APP_SUBSCRIBE_URL}/subscribe/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body
      }).then(response => response.json());
    });

    if (data) {
      yield put({ type: types.ADD_SUBSCRIBER, payload: data });
    }
  } catch (error) {
    yield put({ type: types.ADD_SUBSCRIBER_FAILED });
    throw new Error(error);
  }
}

function* createUserSaga({ type, payload }) {
  const body = JSON.stringify({
    email: payload.email,
    password: payload.password
  });

  try {
    const data = yield call(() => {
      return fetch(`${process.env.REACT_APP_SIGN_UP_URL}/cabinet/users`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body
      }).then(response => response.json());
    });

    if (!data.code) {
      yield put({
        type: types.CREATE_USER_SUCCESS
      });
    } else {
      yield put({
        type: types.CREATE_USER_FAILED,
        payload: "Try again in a minute."
      });
    }
  } catch (error) {
    yield put({
      type: types.CREATE_USER_FAILED,
      payload: "Try again in a minute."
    });
    throw new Error(error);
  }
}

function* checkRegistrationSaga({ type, payload }) {
  try {
    const data = yield call(() => {
      return fetch(`${process.env.REACT_APP_SIGN_UP_URL}/cabinet/users?email=${payload}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      }).then(response => response.json());
    });

    if (!data.data.length) {
      yield put({
        type: types.CHECK_REGISTRATION_SUCCESS
      });
    } else {
      yield put({ type: types.CHECK_REGISTRATION_FAILED, payload });
    }
  } catch (error) {
    yield put({ type: types.CHECK_REGISTRATION_FAILED, payload });
    throw new Error(error);
  }
}

function* verifyUserSaga({ type, payload }) {
  const body = JSON.stringify({
    action: "verifySignupLong",
    value: payload.verifyToken
  });

  try {
    const data = yield call(() => {
      return fetch(`${process.env.REACT_APP_SIGN_UP_URL}/cabinet/authManagement`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body
      }).then(response => response.json());
    });

    if (data && payload.message) {
      yield put({
        type: types.EMAIL_VERIFICATION_SUCCESS,
        payload: { noPopUp: true }
      });
    } else if (data) {
      yield put({
        type: types.EMAIL_VERIFICATION_SUCCESS
      });
    }
  } catch (error) {
    yield put({ type: types.EMAIL_VERIFICATION_FAILED });
    throw new Error(error);
  }
}

function* checkCredentialsSaga({ type, payload }) {
  const body = JSON.stringify({
    strategy: "local",
    email: payload.email,
    password: payload.password
  });

  try {
    const data = yield call(() => {
      return fetch(`${process.env.REACT_APP_SIGN_UP_URL}/cabinet/authentication`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body
      }).then(response => response.json());
    });

    if (!data.code) {
      yield put({
        type: types.USER_VERIFICATION_SUCCESS,
        payload: { email: data.user.email, accessToken: data.accessToken }
      });

      localStorage.setItem("accessToken", data.accessToken);
      localStorage.setItem("userEmail", data.user.email);
      window.history.pushState("signed in", "", "/");
    } else {
      yield put({
        type: types.USER_VERIFICATION_FAILED,
        payload: data.message
      });
    }
  } catch (error) {
    yield put({
      type: types.USER_VERIFICATION_FAILED,
      payload: "Try again in a minute."
    });
    throw new Error(error);
  }
}

function* resendEmailSaga({ type, payload }) {
  const body = JSON.stringify({
    action: "resendVerifySignup",
    value: { email: payload }
  });

  try {
    const data = yield call(() => {
      return fetch(`${process.env.REACT_APP_SIGN_UP_URL}/cabinet/authManagement`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body
      }).then(response => response.json());
    });

    if (!data.code) {
      yield put({
        type: types.RESEND_EMAIL_SUCCESS
      });
    } else {
      yield put({
        type: types.RESEND_EMAIL_FAILED
      });
    }

    if (data.code >= 500) {
      yield put({
        type: types.RESEND_EMAIL_FAILED,
        payload: "Try again in a minute."
      });
    }
  } catch (error) {
    yield put({ type: types.RESEND_EMAIL_FAILED });
    throw new Error(error);
  }
}

function* checkIsLoggedInSaga({ payload }) {
  const body = JSON.stringify({
    strategy: "jwt",
    accessToken: payload
  });

  try {
    const data = yield call(() => {
      return fetch(`${process.env.REACT_APP_SIGN_UP_URL}/cabinet/authentication`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body
      }).then(response => response.json());
    });

    if (data.user) {
      yield put({
        type: types.SET_LOG_IN,
        payload: true
      });
    } else if (data.message === "jwt expired") {
      // localStorage.clear();
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userEmail");
    }
  } catch (error) {
    yield put({ type: types.SET_LOG_IN, payload: false });
    throw new Error(error);
  }
}

function* resendPasswordSaga({ type, payload }) {
  const body = JSON.stringify({
    action: "sendResetPwd",
    value: { email: payload }
  });

  try {
    const data = yield call(() => {
      return fetch(`${process.env.REACT_APP_SIGN_UP_URL}/cabinet/authManagement`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body
      }).then(response => response.json());
    });

    if (!data.code) {
      yield put({
        type: types.RESEND_PASSWORD_SUCCESS
      });
    } else {
      yield put({ type: types.RESEND_PASSWORD_FAILED, payload: data.message });
    }

    if (data.code >= 500) {
      yield put({
        type: types.RESEND_PASSWORD_FAILED,
        payload: "Try again in a minute."
      });
    }
  } catch (error) {
    yield put({
      type: types.RESEND_PASSWORD_FAILED,
      payload: "Try again in a minute"
    });
    throw new Error(error);
  }
}

function* resetPasswordSaga({ type, payload }) {
  const body = JSON.stringify({
    action: "resetPwdLong",
    value: { token: payload.resetToken, password: payload.password }
  });

  try {
    const data = yield call(() => {
      return fetch(`${process.env.REACT_APP_SIGN_UP_URL}/cabinet/authManagement`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body
      }).then(response => response.json());
    });

    if (!data.errors) {
      yield put({
        type: types.CHANGE_PASSWORD_SUCCESS
      });
    } else {
      yield put({ type: types.CHANGE_PASSWORD_FAILED, payload: data.message });
    }
  } catch (error) {
    yield put({
      type: types.CHANGE_PASSWORD_FAILED,
      payload: "Try again in a minute."
    });
    throw new Error(error);
  }
}

function* resetIsRegisteredSaga() {
  yield put({
    type: types.RESET_IS_REGISTERED
  });
}

function* setIsVerifiedSaga() {
  yield put({
    type: types.SET_IS_VERIFIED
  });
}

function* getCountriesSaga() {
  try {
    const data = yield call(() => {
      return fetch(`${process.env.REACT_APP_SIGN_UP_URL}/api/v1/identity/public/countries`, {
        method: "GET"
      }).then(response => response.json());
    });

    if (!data.messages) {
      yield put({
        type: types.GET_COUNTRIES_SUCCESS,
        payload: data
      });
    } else {
      yield put({ type: types.GET_COUNTRIES_FAILED, payload: data.messages });
    }
  } catch (error) {
    yield put({
      type: types.GET_COUNTRIES_FAILED,
      payload: ["Try again in a minute"]
    });
    throw new Error(error);
  }
}

function* sendDataFirstStepSaga({ type, payload }) {
  const { name, surname, passport, country } = payload;
  const accessToken = localStorage.getItem("accessToken");

  const body = JSON.stringify({
    first_name: name,
    last_name: surname,
    doc_number: passport,
    country
  });

  try {
    const data = yield call(() => {
      return fetch(`${process.env.REACT_APP_SIGN_UP_URL}/api/v1/identity/private/profile`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        body
      }).then(response => response.json());
    });

    if (!data.code) {
      yield put({
        type: types.SEND_DATA_FIRST_STEP_SUCCESS,
        payload: { status: data.status, userId: data.id, step: data.step }
      });
    } else {
      yield put({
        type: types.SEND_DATA_FIRST_STEP_FAILED,
        payload: data.messages
      });
    }

    if (data.code >= 500) {
      yield put({
        type: types.SEND_DATA_FIRST_STEP_FAILED,
        payload: "Try again in a minute."
      });
    }
  } catch (error) {
    yield put({
      type: types.SEND_DATA_FIRST_STEP_FAILED,
      payload: "Try again in a minute"
    });
    throw new Error(error);
  }
}

function* sendDataSecondStepSaga({ type, payload }) {
  const { passportPhoto, userWithPassportPhoto, id } = payload;
  const accessToken = localStorage.getItem("accessToken");

  const data = new FormData();
  data.append("doc_photo", passportPhoto);
  data.append("person_photo", userWithPassportPhoto);

  const body = data;

  try {
    const data = yield call(() => {
      return fetch(`${process.env.REACT_APP_SIGN_UP_URL}/api/v1/identity/private/profiles/${id}/document`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        body
      }).then(response => response.json());
    });

    if (data.doc_photo.success && data.person_photo.success) {
      yield put({
        type: types.SEND_DATA_SECOND_STEP_SUCCESS
      });
    } else {
      yield put({
        type: types.SEND_DATA_SECOND_STEP_FAILED,
        payload: {
          docPhotoErrors: data.doc_photo.success ? [] : data.doc_photo.messages,
          profilePhotoErrors: data.person_photo.success ? [] : data.person_photo.messages,
          generalErrors: data.messages ? data.messages : []
        }
      });
    }
  } catch (error) {
    yield put({
      type: types.SEND_DATA_SECOND_STEP_FAILED,
      payload: { generalErrors: ["Try again in a minute."] }
    });
  }
}

function* getUserDataSaga({ payload }) {
  const accessToken = payload;

  try {
    const data = yield call(() => {
      return fetch(`${process.env.REACT_APP_SIGN_UP_URL}/api/v1/identity/private/users/me`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }).then(response => response.json());
    });

    if (data) {
      yield put({
        type: types.GET_USER_DATA_SUCCESS,
        payload: data.profiles[0]
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_USER_DATA_FAILED,
      payload: "Try again in a minute."
    });
  }
}

function* getPhotosSaga({ payload }) {
  const accessToken = localStorage.getItem("accessToken");
  const id = payload;

  try {
    const data = yield call(() => {
      return fetch(`${process.env.REACT_APP_SIGN_UP_URL}/api/v1/identity/private/profiles/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }).then(response => response.json());
    });

    if (data) {
      yield put({
        type: types.GET_PHOTOS_SUCCESS,
        payload: {
          passportPhotoUrl: data.doc_photo_url,
          userWithPassportPhotoUrl: data.person_photo_url
        }
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_PHOTOS_FAILED,
      payload: "Try again in a minute."
    });
  }
}

function* checkUserStatusSaga({ payload }) {
  const accessToken = payload;

  try {
    const data = yield call(() => {
      return fetch(`${process.env.REACT_APP_SIGN_UP_URL}/api/v1/identity/private/users/me`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }).then(response => response.json());
    });

    if (data) {
      yield put({
        type: types.CHECK_USER_STATUS_SUCCESS,
        payload: {
          status: data.profiles.length ? data.profiles[0].status : data.approved ? "Verified" : "Not verified"
        }
      });
    }
  } catch (error) {
    yield put({
      type: types.CHECK_USER_STATUS_FAILED,
      payload: "Try again in a minute."
    });
  }
}

function* confirmProfileSaga({ payload }) {
  const { id, action } = payload;
  const accessToken = localStorage.getItem("accessToken");

  try {
    const data = yield call(() => {
      return fetch(`${process.env.REACT_APP_SIGN_UP_URL}/api/v1/identity/private/profiles/${id}/${action}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }).then(response => response.json());
    });

    if (data) {
      yield put({
        type: types.RESET_PROFILE_SUCCESS,
        payload: data.status
      });
    }
  } catch (error) {
    yield put({
      type: types.RESET_PROFILE_FAILED,
      payload: "Try again in a minute."
    });
  }
}

function* saveUserSettingsSaga({ payload }) {
  const { firstName, lastName, eexAddress, btcAddress, ethAddress, password, newsletters } = payload;
  const accessToken = localStorage.getItem("accessToken");

  const body = JSON.stringify({
    firstName,
    lastName,
    eexAddress,
    btcAddress,
    ethAddress,
    password,
    newsletters
  });

  try {
    const data = yield call(() => {
      return fetch(`${process.env.REACT_APP_SIGN_UP_URL}/cabinet/users`, {
        method: "PATCH",
        headers: {
          Authorization: accessToken,
          "Content-Type": "application/json"
        },
        body
      }).then(response => response.json());
    });

    if (!data.code) {
      yield put({
        type: types.SAVE_USER_SETTINGS_SUCCESS,
        payload: data.status
      });
    } else {
      yield put({
        type: types.SAVE_USER_SETTINGS_FAILED,
        payload: data.message
      });
    }
  } catch (error) {
    yield put({
      type: types.SAVE_USER_SETTINGS_FAILED,
      payload: "Try again in a minute."
    });
  }
}

function* uploadProfilePhotoSaga({ payload: profilePhoto }) {
  const accessToken = localStorage.getItem("accessToken");
  const data = new FormData();
  data.append("uri", profilePhoto);
  const body = data;

  try {
    const data = yield call(() => {
      return fetch(`${process.env.REACT_APP_SIGN_UP_URL}/cabinet/uploads`, {
        method: "POST",
        headers: {
          Authorization: accessToken
        },
        body
      }).then(response => response.json());
    });

    if (data.id) {
      yield put({
        type: types.UPLOAD_PROFILE_PHOTO_SUCCESS
      });
    } else {
      yield put({
        type: types.UPLOAD_PROFILE_PHOTO_FAILED,
        payload: data.message
      });
    }
  } catch (error) {
    yield put({
      type: types.UPLOAD_PROFILE_PHOTO_FAILED,
      payload: "Try again in a minute."
    });
  }
}

function* setUserSettingsSaga({ payload }) {
  const body = JSON.stringify({
    strategy: "jwt",
    accessToken: payload
  });

  try {
    const data = yield call(() => {
      return fetch(`${process.env.REACT_APP_SIGN_UP_URL}/cabinet/authentication`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body
      }).then(response => response.json());
    });

    if (data.user) {
      yield put({
        type: types.SET_USER_SETTINGS_DATA_SUCCESS,
        payload: data.user
      });
    } else if (data.message === "jwt expired") {
      // localStorage.clear();
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userEmail");
    } else {
      yield put({
        type: types.SET_USER_SETTINGS_DATA_FAILED,
        payload: data.message
      });
    }
  } catch (error) {
    yield put({
      type: types.SET_USER_SETTINGS_DATA_FAILED,
      payload: "Try again in a minute."
    });
    throw new Error(error);
  }
}

/* eslint-disable no-unused-vars */
function* watchSubscribeSaga() {
  yield takeLatest(types.ADD_SUBSCRIBER_ASYNC, subscribeSaga);
}

/* eslint-disable no-unused-vars */
function* watchCreateUserSaga() {
  yield takeLatest(types.CREATE_USER_ASYNC, createUserSaga);
}

/* eslint-disable no-unused-vars */
function* watchVerifyUserSaga() {
  yield takeLatest(types.VERIFY_USER_ASYNC, verifyUserSaga);
}

/* eslint-disable no-unused-vars */
function* watchCheckCredentialsSaga() {
  yield takeLatest(types.CHECK_CREDENTIALS_ASYNC, checkCredentialsSaga);
}

/* eslint-disable no-unused-vars */
function* watchResendEmailSaga() {
  yield takeLatest(types.RESEND_EMAIL_ASYNC, resendEmailSaga);
}

/* eslint-disable no-unused-vars */
function* watchCheckIsLoggedInSaga() {
  yield takeLatest(types.CHECK_IS_LOGGED_IN_ASYNC, checkIsLoggedInSaga);
}

/* eslint-disable no-unused-vars */
function* watchResendPasswordSaga() {
  yield takeLatest(types.RESEND_PASSWORD_ASYNC, resendPasswordSaga);
}

/* eslint-disable no-unused-vars */
function* watchVerifyResetTokenSaga() {
  yield takeLatest(types.CHANGE_PASSWORD_ASYNC, resetPasswordSaga);
}

/* eslint-disable no-unused-vars */
function* watchCheckRegistrationSaga() {
  yield takeLatest(types.CHECK_REGISTRATION_ASYNC, checkRegistrationSaga);
}

/* eslint-disable no-unused-vars */
function* watchResetIsRegisteredSaga() {
  yield takeLatest(types.RESET_IS_REGISTERED_ASYNC, resetIsRegisteredSaga);
}

/* eslint-disable no-unused-vars */
function* watchSetIsVerifiedSaga() {
  yield takeLatest(types.SET_IS_VERIFIED_ASYNC, setIsVerifiedSaga);
}

/* eslint-disable no-unused-vars */
function* watchGetCountriesSaga() {
  yield takeLatest(types.GET_COUNTRIES_ASYNC, getCountriesSaga);
}

/* eslint-disable no-unused-vars */
function* watchSendDataFirstStepSaga() {
  yield takeLatest(types.SEND_DATA_FIRST_STEP_ASYNC, sendDataFirstStepSaga);
}

/* eslint-disable no-unused-vars */
function* watchSendDataSecondStepSaga() {
  yield takeLatest(types.SEND_DATA_SECOND_STEP_ASYNC, sendDataSecondStepSaga);
}

/* eslint-disable no-unused-vars */
function* watchGetUserDataSaga() {
  yield takeLatest(types.GET_USER_DATA_ASYNC, getUserDataSaga);
}

/* eslint-disable no-unused-vars */
function* watchGetPhotosSaga() {
  yield takeLatest(types.GET_PHOTOS_ASYNC, getPhotosSaga);
}

/* eslint-disable no-unused-vars */
function* watchCheckUserStatusSaga() {
  yield takeLatest(types.CHECK_USER_STATUS_ASYNC, checkUserStatusSaga);
}

/* eslint-disable no-unused-vars */
function* watchResetProfileSaga() {
  yield takeLatest(types.RESET_PROFILE_ASYNC, confirmProfileSaga);
}

/* eslint-disable no-unused-vars */
function* watchSubmitProfileSaga() {
  yield takeLatest(types.SUBMIT_PROFILE_ASYNC, confirmProfileSaga);
}

/* eslint-disable no-unused-vars */
function* watchSaveUserSettingsSaga() {
  yield takeLatest(types.SAVE_USER_SETTINGS_ASYNC, saveUserSettingsSaga);
}

/* eslint-disable no-unused-vars */
function* watchUploadProfilePhotoSaga() {
  yield takeLatest(types.UPLOAD_PROFILE_PHOTO_ASYNC, uploadProfilePhotoSaga);
}

/* eslint-disable no-unused-vars */
function* watchSetUserSettingsData() {
  yield takeLatest(types.SET_USER_SETTINGS_DATA_ASYNC, setUserSettingsSaga);
}

export default function* rootSaga() {
  const sagas = [
    watchSubscribeSaga,
    watchCreateUserSaga,
    watchVerifyUserSaga,
    watchCheckCredentialsSaga,
    watchResendEmailSaga,
    watchCheckIsLoggedInSaga,
    watchResendPasswordSaga,
    watchVerifyResetTokenSaga,
    watchCheckRegistrationSaga,
    watchResetIsRegisteredSaga,
    watchSetIsVerifiedSaga,
    watchGetCountriesSaga,
    watchSendDataFirstStepSaga,
    watchSendDataSecondStepSaga,
    watchGetUserDataSaga,
    watchGetPhotosSaga,
    watchCheckUserStatusSaga,
    watchResetProfileSaga,
    watchSubmitProfileSaga,
    watchSaveUserSettingsSaga,
    watchUploadProfilePhotoSaga,
    watchSetUserSettingsData,
    generalRatesSagas.watchFetchGeneralRatesSaga,
    generalRatesSagas.periodicFetchGeneralRatesSaga,
    eexPurchaseSagas.watchFetchUserLimit,
    eexPurchaseSagas.fetchUserLimitOnSignIn,
    eexPurchaseSagas.watchFetchUserTransactions,
    eexPurchaseSagas.periodicFetchUserTransactions,
    eexPurchaseSagas.watchFetchGlobalTransactions,
    eexPurchaseSagas.periodicFetchGlobalTransactions,
    eexPurchaseSagas.watchFetchLastUserTransaction,
    eexPurchaseSagas.periodicFetchLastUserTransaction,
    eexPurchaseSagas.watchFetchEexPriceInCurrency,
    eexPurchaseSagas.refetchEexPriceInCurrencyOnFetchGeneralRates,
    eexPurchaseSagas.watchBuyEex,
    exchangeAddressSagas.watchFetchBtcExchangeAddress,
    // exchangeAddressSagas.watchFetchEthExchangeAddress,
    exchangeAddressSagas.fetchBtcExchangeAddressOnEverySignIn
    // exchangeAddressSagas.fetchEthExchangeAddressOnce
  ];
  yield all(
    sagas.map(saga =>
      spawn(function*() {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (error) {
            console.log(`Unhandled error in saga '${saga.name}': `, error);
            console.log(`Restarting saga '${saga.name}'...`);
          }
        }
      })
    )
  );
}
