// Absolute imports
import styled from "styled-components/macro";

// Theme
import { breakpoints } from "../../theme/breakpoints";

export const Root = styled.header`
  position: ${({ active }) => (active ? "fixed" : "absolute;")};
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
`;

export const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  padding-top: 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.28);
  position: relative;
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

export const LogoImg = styled.img`
  width: 49px;
  height: 49px;
  object-fit: contain;
`;

export const LogoDescription = styled.span`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: -0.47px;
  margin-left: 12px;
  color: #fff;
`;

export const Menu = styled.div`
  display: flex;
  align-items: center;
`;

export const Language = styled.div`
  display: flex;
`;

export const MobileMenu = styled.div`
  margin-left: 16px;
  position: relative;
  z-index: 15;

  @media (min-width: ${breakpoints.md}px) {
    display: none;
  }
`;
