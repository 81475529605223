import styled from "styled-components";

// Breakpoints
import { breakpoints } from "../../theme/breakpoints";

const themes = {
  light: "border: 1px solid #441eda; background: #fff; color: #441eda;"
};

export const Root = styled.div`
  display: flex;
  padding: 0 0 160px 0;
  flex-direction: column;

  @media (max-width: ${breakpoints.md}px) {
    padding: 50px 20px;
  }

  @media (max-width: ${breakpoints.sm}px) {
    align-items: center;
    padding: 50px 20px;
  }
`;

export const H2 = styled.h2`
  width: 100%;
  margin: 0 0 20px 0;

  @media (max-width: ${breakpoints.sm}px) {
    text-align: center;
  }
`;

export const Text = styled.p`
  margin: 10px 0;
  font-size: 16px;
  font-family: "Roboto", sans-serif;

  @media (max-width: ${breakpoints.sm}px) {
    text-align: center;
  }
`;

export const ButtonsBlock = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;

  @media (max-width: ${breakpoints.sm}px) {
    justify-content: center;
  }
`;

export const Button = styled.button`
  width: 18%;
  height: 40px;
  border: none;
  border-radius: 4px;
  margin: 20px 0;
  font-size: 16px;
  color: #fff;
  background: #441eda;
  margin-right: 30px;
  cursor: pointer;

  position: relative;

  ${({ theme }) => theme && `${themes[theme]};`}

  @media (max-width: ${breakpoints.sm}px) {
    width: 30%;
    margin: 0 10px;
  }
`;

export const Line = styled.div`
  background: #dbdbdb;
  height: 1px;
  width: 100%;
  margin: 30px 0;
`;

export const Span = styled.span`
  color: #441eda;
`;
