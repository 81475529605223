import React, { useState, useEffect } from "react";
import * as types from "../../redux/types";
import bitcoin from "./img/bitcoin.svg";
import ethereum from "./img/ethereum.svg";
import { Icon, ArrowDown, ArrowUp, DropDownButton, DropDownContainer, DropDownItem, Span } from "./styles";

const CURRENCY_BY_TYPE = Object.freeze({
  eex: {
    type: types.CURRENCIES.EEX,
    text: "EEX"
  },
  btc: {
    type: types.CURRENCIES.BTC,
    text: "BTC (Bitcoin)",
    iconSrc: bitcoin,
    iconAlt: "bitcoin-icon"
  },
  eth: {
    type: types.CURRENCIES.ETH,
    text: "ETH (Ethereum)",
    iconSrc: ethereum,
    iconAlt: "ethereum-icon"
  }
});

const CURRENCIES = Object.freeze(Object.values(CURRENCY_BY_TYPE));

export default function ExchangeDropDown({ availableCurrencies, selectedCurrency, onSelectedCurrencyChange }) {
  const [isOptionsShown, setIsOptionsShown] = useState(false);

  const currencyData = selectedCurrency && CURRENCY_BY_TYPE[selectedCurrency];
  const isInvalidState = !selectedCurrency || !availableCurrencies[selectedCurrency] || !currencyData;
  const availableCurrenciesCount = Object.values(availableCurrencies).filter(x => x).length;

  // in invalid state try to select the first available currency (if any)
  useEffect(() => {
    if (isInvalidState && availableCurrenciesCount > 0) {
      for (const c in availableCurrencies) {
        if (availableCurrencies[c]) {
          onSelectedCurrencyChange(c);
          return;
        }
      }
    }
  }, [availableCurrencies, availableCurrenciesCount, onSelectedCurrencyChange, isInvalidState]);

  const handleDropDownItemClick = (event, option) => {
    event.stopPropagation();
    setIsOptionsShown(false);
    onSelectedCurrencyChange(option.type);
  };

  const toggleOptions = () => {
    if (availableCurrenciesCount > 1) {
      setIsOptionsShown(!isOptionsShown);
    }
  };

  return (
    <DropDownButton disabled={isInvalidState} onClick={toggleOptions}>
      {isInvalidState ? null : (
        <React.Fragment>
          <Icon src={currencyData.iconSrc} alt={currencyData.iconAlt} top="12px" />
          <Span>{currencyData.text}</Span>
          {availableCurrenciesCount > 1 && (isOptionsShown ? <ArrowUp /> : <ArrowDown />)}
          {isOptionsShown && (
            <DropDownContainer>
              {CURRENCIES.filter(c => !!availableCurrencies[c.type])
                .filter(c => c.type !== selectedCurrency)
                .map(c => (
                  <DropDownItem key={c.type} onClick={e => handleDropDownItemClick(e, c)}>
                    <Icon src={c.iconSrc} alt={c.iconAlt} top="12px" />
                    {c.text}
                  </DropDownItem>
                ))}
            </DropDownContainer>
          )}
        </React.Fragment>
      )}
    </DropDownButton>
  );
}
