import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ExchangeCurrencyInput from "../ExchangeCurrencyInput";
import ExchangeDropDown from "../ExchangeDropDown";
import * as actions from "../../redux/actionCreators/eexPurchaseActions";
import * as types from "../../redux/types";
import { getUserSettingsAction } from "../../redux/actionCreators";
import eex from "./img/eex_icon.svg";
import { Form, Text, Flex, Input, Icon, InputWrapper, Button, Error } from "./styles";

const ExchangeForm = ({
  isLoggedIn,
  requestedEexAmount,
  targetCurrency,
  price,
  pricePending,
  pricePendingAutomatic,
  currentTransactionStatus,
  limit,
  frontendError,
  backendError,
  onRequestedEexChange,
  getUserSettings,
  buyEex
}) => {
  useEffect(() => {
    if (isLoggedIn) {
      const accessToken = localStorage.getItem("accessToken");
      getUserSettings(accessToken);
    }
  }, [getUserSettings, isLoggedIn]);

  const handleRequestedEexChange = newRequestedEexAmount => {
    onRequestedEexChange(newRequestedEexAmount, targetCurrency);
  };

  const handleSelectedCurrencyChange = newTargetCurrency => {
    onRequestedEexChange(requestedEexAmount, newTargetCurrency);
  };

  const handleBuyClick = () => {
    buyEex(requestedEexAmount, targetCurrency, price);
  };

  const anyError = frontendError || backendError;
  const buyDisabled =
    !isLoggedIn ||
    !!frontendError ||
    price === "0" ||
    (pricePending && !pricePendingAutomatic) ||
    currentTransactionStatus === "processing";

  return (
    <Form>
      <Text>I want</Text>
      <Flex>
        <ExchangeCurrencyInput value={requestedEexAmount} max={limit} onChange={handleRequestedEexChange} />
        <InputWrapper>
          <Input width="100%" type="text" color="#2C2A32" readOnly value="EEX (Express coin)" padding="0 40px" />
          <Icon src={eex} alt="eex-icon" />
        </InputWrapper>
      </Flex>
      <Text>I give</Text>
      <Flex>
        <Input type="text" value={price} readOnly />
        <Flex direction="column" width="48%">
          <ExchangeDropDown
            availableCurrencies={{
              btc: true,
              eth: false
            }}
            selectedCurrency={targetCurrency}
            onSelectedCurrencyChange={handleSelectedCurrencyChange}
          />
        </Flex>
        <Flex justify={anyError ? "space-between" : "flex-end"}>
          <Error>{anyError}</Error>
          <Button disabled={buyDisabled} onClick={handleBuyClick}>
            Buy
          </Button>
        </Flex>
      </Flex>
    </Form>
  );
};

const ErrorLink = ({ to, children }) => (
  <Link to={to} style={{ color: "#BA2612" }}>
    {children}
  </Link>
);

const errors = Object.freeze({
  unauthorized: (
    <div>
      To purchase EEX coin you must <ErrorLink to="/signup">register</ErrorLink> and{" "}
      <ErrorLink to="/signin">sign in</ErrorLink>!
    </div>
  ),
  kyc: (
    <div>
      To purchase EEX coin you must first pass <ErrorLink to="/kyc">KYC verification</ErrorLink>!
    </div>
  ),
  eexAddressNotSet: (
    <div>
      To purchase EEX coin you must first specify your EEX address in <ErrorLink to="/settings">settings</ErrorLink>!
    </div>
  ),
  walletAddressNotSet: (
    <div>
      To purchase EEX coin you must specify your wallet address in <ErrorLink to="/settings">settings</ErrorLink>!
    </div>
  ),
  cantResolveExchangeAddress: <div>Can't resolve exchange address, please try again later</div>
});

const mapStateToError = state => {
  if (!state.registration.isLoggedIn) {
    return errors.unauthorized;
  } else if (state.kyc.status !== "approved") {
    return errors.kyc;
  } else if (!state.settings.userSettings.eexAddress) {
    return errors.eexAddressNotSet;
  } else if (
    (state.eexPurchase.targetCurrency === types.CURRENCIES.BTC && !state.settings.userSettings.btcAddress) ||
    (state.eexPurchase.targetCurrency === types.CURRENCIES.ETH && !state.settings.userSettings.ethAddress)
  ) {
    return errors.walletAddressNotSet;
  } else if (
    !state.exchangeAddress.btcExchangeAddress
    // || !state.exchangeAddress.ethExchangeAddress
  ) {
    return errors.cantResolveExchangeAddress;
  } else {
    return undefined;
  }
};

// TODO: only access state via selectors (reselect)
const mapStateToProps = state => ({
  isLoggedIn: state.registration.isLoggedIn,
  requestedEexAmount: state.eexPurchase.requestedEexAmount,
  targetCurrency: state.eexPurchase.targetCurrency,
  price: state.eexPurchase.requestedEexPriceInTargetCurrency,
  pricePending: state.eexPurchase.requestedEexPriceInTargetCurrencyPending,
  pricePendingAutomatic: state.eexPurchase.requestedEexPriceInTargetCurrencyPendingAutomatic,
  currentTransactionStatus: state.eexPurchase.currentTransaction && state.eexPurchase.currentTransaction.status,
  limit: state.eexPurchase.limit,
  eexAddress: state.settings.userSettings.eexAddress,
  frontendError: mapStateToError(state),
  backendError: state.eexPurchase.backendError
});

const mapDispatchToProps = dispatch => ({
  onRequestedEexChange: (requestedEexAmount, targetCurrency) =>
    dispatch(
      actions.fetchEexPriceInCurrency({
        requestedEexAmount,
        targetCurrency,
        automatic: false
      })
    ),
  getUserSettings: token => dispatch(getUserSettingsAction(token)),
  buyEex: (requestedEexAmount, targetCurrency, price) =>
    dispatch(
      actions.buyEex({
        requestedEexAmount,
        targetCurrency,
        price
      })
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExchangeForm);
