import React from "react";

// Styles
import { Root, Outer, Inner, Line, TextWrapper, Text } from "./styles";

// Img
import success from "./success.svg";

const Steps = ({ step = 1 }) => {
  return (
    <>
      <Root>
        <Outer done={step >= 2 && true} active={step === 1 && true}>
          {step === 1 && <Inner done={step === 2 ? true : false} />}
          {step >= 2 && <img src={success} alt="success" />}
        </Outer>
        <Line active={step >= 2 && true} />
        <Outer active={step >= 2 && true} done={step >= 3 && true}>
          {step === 2 && <Inner />}
          {step >= 3 && <img src={success} alt="success" />}
        </Outer>
        <Line active={step >= 3 && true} />
        <Outer active={step >= 3 && true}>{step === 3 && <Inner />}</Outer>
      </Root>
      <TextWrapper>
        <Text>Personal Information</Text>
        <Text>Personal Documents</Text>
        <Text>Review</Text>
      </TextWrapper>
    </>
  );
};

export default Steps;
