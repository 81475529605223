import React, { useState, useEffect, useRef } from 'react';
import {
  InputContainer,
  StyledInput,
  Separator,
  OverlayButton,
} from './styles';

export default function ExchangeCurrencyInput({ value, onChange, max }) {
  const inputRef = React.createRef();
  const clipboardData = useRef(null);
  const [selectionEnd, setSelectionEnd] = useState({ value: 0 });

  useEffect(() => {
    if (!inputRef.current) return;
    inputRef.current.setSelectionRange(
      selectionEnd.value,
      selectionEnd.value
    );
  }, [inputRef, selectionEnd]);

  const handleInputChange = (event) => {
    const {
      value: newValue,
      selectionEnd: newSelectionEnd
    } = updateInput(
      inputRef.current.selectionEnd,
      clipboardData.current,
      value,
      event.target.value
    );
    setSelectionEnd({ value: newSelectionEnd });
    onChange(newValue);
    clipboardData.current = null;
  };

  const handleInputPaste = (event) => {
    clipboardData.current = event.clipboardData.getData('Text');
  };

  const handleOverlayButtonClick = () => {
    setSelectionEnd({ value: max.length });
    onChange(max);
  }

  return (
    <InputContainer>
      <StyledInput
        type="text"
        maxLength={24}
        ref={inputRef}
        value={value}
        onChange={handleInputChange}
        onPaste={handleInputPaste}
      />
      <Separator />
      <OverlayButton
        onClick={handleOverlayButtonClick}
      >
        Max
      </OverlayButton>
    </InputContainer>
  );
}

function updateInput(selectionEnd, clipboardData, oldValue, newValue) {
  const oldChars = oldValue.split('');
  const newChars = newValue.split('');
  if (clipboardData) { // paste
    const sanitizedClipboardData = sanitizeClipboardData(clipboardData);
    if (sanitizedClipboardData.length > 0) {
      return {
        selectionEnd: sanitizedClipboardData.length,
        value: sanitizedClipboardData,
      };
    } else {
      return {
        selectionEnd: selectionEnd,
        value: oldValue,
      };
    }
  } else { // inserting and deleting single chars & deleting selection
    const [shorterChars, longerChars] = [oldChars, newChars]
      .sort((a, b) => a.length - b.length);
    const changeStartIndex = shorterChars.findIndex((c, i) => c !== longerChars[i]);
    const pivotStart = changeStartIndex === -1
      ? shorterChars.length
      : changeStartIndex;
    const newChar = newChars[pivotStart];
    const lhs = newChars.slice(0, pivotStart);
    const rhs = newChars.slice(pivotStart + 1);
    if (newChar === '.') {
      const newLhs = lhs.filter(isDigit);
      const newRhs = rhs.filter(isDigit);
      const lhsDeletedCharsCount = lhs.length - newLhs.length;
      return {
        selectionEnd: selectionEnd - lhsDeletedCharsCount,
        value: [].concat(newLhs, ['.'], newRhs).join(''),
      };
    } else {
      const newCharIsDigit = isDigit(newChar);
      return {
        selectionEnd: selectionEnd,
        value: [].concat(lhs, newCharIsDigit ? [newChar] : [], rhs).join(''),
      };
    }
  }
};

function sanitizeClipboardData(clipboardData) {
  const chars = clipboardData.split('');
  const lastDotIndex = chars.lastIndexOf('.');
  return chars
    .filter((c, i) => isDigit(c) || i === lastDotIndex)
    .join('');
}

function isDigit(char) {
  return char >= '0' && char <= '9';
}
