import * as types from "../types";

const registrationState = {
  isLoggedIn: null,
  isUserCreated: false,
  createUserError: null,
  attemptForCreateUser: 1,
  resendEmailError: null,
  isVerified: null,
  logInError: null,
  isEmailVerified: false,
  userEmail: "",
  isPasswordSent: null,
  isRequestForUserCreationFailed: false,
  isRegistered: null,
  registeredEmail: null,
  attempt: 1,
  isEmailResent: null,
  isPasswordChanged: null,
  attemptForLogIn: 1,
  attemptForResendPassword: 1,
  attemptForResendEmail: 1,
  resendPasswordError: null,
  attemptForPasswordChange: 1,
  accessToken: "",
  resetToken: "",
  isPagesInvisible: false,
  changePasswordError: false,
  restoreError: null,
  noPopUp: false
};

const registrationReducer = (state = registrationState, { type, payload }) => {
  switch (type) {
    case types.SET_LOG_IN:
      return {
        ...state,
        isLoggedIn: payload
      };

    case types.CREATE_USER_SUCCESS:
      return {
        ...state,
        isUserCreated: true,
        createUserError: null,
        attemptForCreateUser: state.attemptForCreateUser + 1
      };
    case types.CREATE_USER_FAILED:
      return {
        ...state,
        isUserCreated: false,
        createUserError: payload,
        attemptForCreateUser: state.attemptForCreateUser + 1
      };
    case types.EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        isEmailVerified: true,
        noPopUp: payload ? payload.noPopUp : false
      };
    case types.EMAIL_VERIFICATION_FAILED:
      return {
        ...state,
        isEmailVerified: false
      };
    case types.USER_VERIFICATION_SUCCESS:
      return {
        ...state,
        isVerified: true,
        userEmail: payload.email,
        accessToken: payload.accessToken
      };
    case types.USER_VERIFICATION_FAILED:
      return {
        ...state,
        isVerified: false,
        logInError: payload,
        attemptForLogIn: state.attemptForLogIn + 1
      };
    case types.SET_USER_EMAIL:
      return {
        ...state,
        userEmail: payload
      };
    case types.RESEND_PASSWORD_SUCCESS:
      return {
        ...state,
        isPasswordSent: true,
        attemptForResendPassword: state.attemptForResendPassword + 1
      };

    case types.RESEND_PASSWORD_FAILED:
      return {
        ...state,
        isPasswordSent: false,
        attemptForResendPassword: state.attemptForResendPassword + 1,
        resendPasswordError: payload
      };
    case types.CHECK_REGISTRATION_SUCCESS:
      return {
        ...state,
        isRegistered: false,
        registeredEmail: null
      };
    case types.CHECK_REGISTRATION_FAILED:
      return state.registeredEmail === payload
        ? {
            ...state,
            isRegistered: true,
            registeredEmail: payload,
            attempt: state.attempt + 1
          }
        : {
            ...state,
            isRegistered: true,
            registeredEmail: payload,
            attempt: state.attempt
          };
    case types.RESEND_EMAIL_SUCCESS:
      return {
        ...state,
        isEmailResent: true,
        resendEmailError: null
      };
    case types.RESEND_EMAIL_FAILED:
      return {
        ...state,
        isEmailResent: false,
        attemptForResendEmail: state.attemptForResendEmail + 1,
        resendEmailError: payload
      };
    case types.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isPasswordChanged: true,
        attemptForPasswordChange: state.attemptForPasswordChange + 1,
        changePasswordError: false,
        restoreError: null
      };
    case types.CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        isPasswordChanged: false,
        attemptForPasswordChange: state.attemptForPasswordChange + 1,
        changePasswordError: true,
        restoreError: payload
      };
    case types.RESET_IS_REGISTERED:
      return {
        ...state,
        isRegistered: null
      };
    case types.SET_IS_VERIFIED:
      return {
        ...state,
        isVerified: null
      };
    case types.RESET_TOKEN:
      return {
        ...state,
        resetToken: payload
      };

    default:
      return state;
  }
};

export default registrationReducer;
