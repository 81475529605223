import styled from "styled-components";

// Breakpoints
import { breakpoints } from "../../theme/breakpoints";

const colors = {
  light: "#A8A5B4",
  success: "#42BA12",
  error: "#BA2612",
  lightViolet: "rgba(68, 30, 218, .3)"
};

export const Label = styled.label`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Input = styled.input`
  margin: 10px 0 5px;
  border-radius: 4px;
  border: 1px solid #e4e3e8;
  padding-left: 10px;
  height: 50px;
  color: #12072c;
  font-size: 16px;

  ${({ error }) => error && `border: 2px solid #ed2222`}
`;

export const Button = styled.button`
  width: 100%;
  height: 50px;
  color: #fff;
  cursor: pointer;
  background: #441eda;
  text-transform: uppercase;
  border-radius: 4px;
  border: none;
  margin-top: 10px;
  font-size: 16px;

  ${({ background }) => background && `background: ${colors[background]};`}

  @media (max-width: ${breakpoints.sm}px) {
    height: 40px;
  }
`;

export const Info = styled.p`
  color: #441eda;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  margin: 20px 0 0 0;
`;

export const IconWrapper = styled.span`
  position: absolute;
  top: 45px;
  right: 20px;

  @media (max-width: ${breakpoints.sm}px) {
    top: 45px;
    right: 10px;
  }
`;

export const List = styled.ul`
  margin: 0;
  padding: 0 0 0 20px;
`;

export const ListItem = styled.li`
  font-size: 14px;
  color: #a8a5b4;
  margin-bottom: 5px;

  ${({ color }) => color && `color: ${colors[color]};`}
`;

export const Error = styled.div`
  color: #ed2222;
`;
