import * as types from "../types";
import * as constants from "../../constants";

const initialState = {
  isFirstTimeInfoShown: true,
  requestedEexAmount: "0",
  targetCurrency: types.CURRENCIES.BTC,
  requestedEexPriceInTargetCurrency: "0",
  requestedEexPriceInTargetCurrencyPending: false,
  requestedEexPriceInTargetCurrencyPendingAutomatic: false,
  limit: "10000",
  backendError: null,
  currentTransaction: null,
  userTransactions: {
    total: 0,
    pagesCount: 0,
    page: 0,
    records: []
  },
  globalTransactions: {
    total: 0,
    pagesCount: 0,
    page: 0,
    records: []
  },
  initialFetch: {
    limitReady: false,
    userTransactionsReady: false
  }
};

function eexPurchaseReducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.HIDE_FIRST_TIME_EEX_PURCHASE_INFO:
      return {
        ...state,
        isFirstTimeInfoShown: false
      };
    case types.FETCH_EEX_PRICE_IN_CURRENCY:
      return {
        ...state,
        targetCurrency: payload.targetCurrency,
        requestedEexAmount: payload.requestedEexAmount,
        requestedEexPriceInTargetCurrencyPending: true,
        requestedEexPriceInTargetCurrencyPendingAutomatic: payload.automatic
      };
    case types.FETCH_EEX_PRICE_IN_CURRENCY_SUCCESS:
      return {
        ...state,
        requestedEexPriceInTargetCurrency: payload.price,
        requestedEexPriceInTargetCurrencyPending: false
      };
    case types.FETCH_EEX_PRICE_IN_CURRENCY_FAILURE:
      return {
        ...state,
        requestedEexPriceInTargetCurrencyPending: false
      };
    case types.FETCH_USER_LIMIT_SUCCESS:
      return {
        ...state,
        limit: payload,
        initialFetch: {
          ...state.initialFetch,
          limitReady: true
        }
      };
    case types.FETCH_USER_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        userTransactions: {
          ...state.userTransactions,
          total: payload.total,
          pagesCount: Math.ceil(+payload.total / constants.USER_TRANSACTIONS_PER_PAGE),
          page: payload.page,
          records: payload.data
        },
        initialFetch: {
          ...state.initialFetch,
          userTransactionsReady: true
        }
      };
    case types.FETCH_GLOBAL_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        globalTransactions: {
          ...state.globalTransactions,
          total: payload.total,
          pagesCount: Math.ceil(+payload.total / constants.USER_TRANSACTIONS_PER_PAGE),
          page: payload.page,
          records: payload.data
        }
      };
    case types.FETCH_LAST_USER_TRANSACTION_SUCCESS:
      if (!payload) return { ...state, currentTransaction: null };
      const isTransactionFinished = constants.USER_TRANSACTION_FINAL_STATUSES.indexOf(payload.status) !== -1;
      const isTranscationExpiredLocally = payload.secondsUntilExpire < 0;
      if (isTransactionFinished || isTranscationExpiredLocally) {
        return { ...state, currentTransaction: null };
      }
      return {
        ...state,
        currentTransaction: {
          targetCurrency: payload.pair.split("/")[0].toLowerCase(),
          targetAddress: payload.targetAddress || "?",
          price: payload.fundsExpected,
          status: payload.status,
          expireAt: payload.expireAt,
          secondsUntilExpire: payload.secondsUntilExpire
        }
      };
    case types.BUY_EEX_SUCCESS:
      return {
        ...state,
        backendError: null,
        currentTransaction: {
          targetCurrency: payload.pair.split("/")[0].toLowerCase(),
          targetAddress: payload.targetAddress || "?",
          price: payload.fundsExpected,
          status: payload.status,
          expireAt: payload.expireAt,
          secondsUntilExpire: payload.secondsUntilExpire
        }
      };
    case types.BUY_EEX_FAILURE:
      return {
        ...state,
        backendError: payload.message
      };
    case types.SET_LOG_IN:
      if (payload === false) {
        return {
          ...state,
          backendError: initialState.backendError,
          currentTransaction: initialState.currentTransaction,
          userTransactions: initialState.userTransactions
        };
      } else {
        return state;
      }
    default:
      return state;
  }
}

export default eexPurchaseReducer;
