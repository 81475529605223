// Absolute imports
import styled from "styled-components/macro";

// Breakpoints
import { breakpoints } from "../../theme/breakpoints";

export const Container = styled.div`
  width: 100%;
  padding: 0 20px;
  margin: auto;

  @media (min-width: ${breakpoints.lg}px) {
    width: 1280px;
  }

  @media (max-width: ${breakpoints.sm}px) {
    padding: 10px;
  }
`;
