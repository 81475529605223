// Absolute imports
import styled from "styled-components/macro";

// Components
import { Input } from "../UI/Input";
import { Button } from "../UI/Button";

// Theme
import { breakpoints } from "../../theme/breakpoints";

export const Root = styled.form`
  margin: 24px 0;
  width: 100%;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-wrap: wrap;

  margin: 0 10px 12px;
`;

export const InputWrapper = styled.div`
  flex-basis: 100%;

  @media (min-width: ${breakpoints.md}px) {
    flex-basis: auto;
    flex-grow: 1;
  }
`;

export const ButtonWrapper = styled.div`
  flex-basis: 100%;

  @media (min-width: ${breakpoints.md}px) {
    flex-basis: auto;
    margin-left: 12px;
  }
`;

export const SubscribeInput = styled(Input)`
  border-radius: 5px 5px 0 0;

  @media (min-width: ${breakpoints.md}px) {
    border-radius: 5px;
  }
`;

export const SubscribeButton = styled(Button)`
  display: flex;
  flex-basis: 100%;
  width: 100%;
  border-radius: 0 0 5px 5px;
  height: 53px;
  font-family: "Roboto", sans-serif;

  background: #441eda;

  &:hover {
    background: #5a3ded;
  }

  @media (min-width: ${breakpoints.md}px) {
    display: inline-flex;
    flex-basis: auto;
    border-radius: 5px;
  }
`;

export const ValidationError = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #ed2222;
`;

export const ThankYou = styled.div`
  border-radius: 5px;
  // background-color: rgba(61, 130, 65, 0.39);
  // color: #5bd762;
  background-color: rgba(21, 175, 59, 0.2);
  color: #066b22;
  padding: 14px 24px;
  font-size: 20px;
  line-height: 1.35;
  letter-spacing: 0.6px;
  text-align: center;
`;
