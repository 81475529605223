import styled from "styled-components";

// Breakpoints
import { breakpoints } from "../../theme/breakpoints";

export const Root = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid gray;
  color: #2c2a32;
  padding: 40px 0;

  @media (max-width: ${breakpoints.sm}px) {
    padding: 10px;
  }
`;

export const TransactionContainer = styled.div`
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  width: 50%;
  padding: 0;

  @media (max-width: ${breakpoints.md}px) {
    width: 60%;
  }

  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
    border: none;
  }
`;

export const TransactionHeader = styled.header`
  font-family: Fira Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #441eda;
  margin: 24px 0;
`;

export const TransactionBody = styled.div`
  padding: 30px 30px;
`;

export const HR = styled.div`
  width: 100%;
  height: 1px;
  border-top: 1px solid #dbdbdb;
`;

export const H3 = styled.h2`
  width: 50%;

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 18px;
  }
`;

export const Label = styled.div`
  padding: 0;
  margin-bottom: 8px;
`;

export const Flex = styled.div`
  display: flex;
  width: 50%;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  ${({ justify }) => justify && `justify-content: ${justify};`}

  ${({ align }) => align && `align-items: ${align};`}
  ${({ width }) => width && `width: ${width};`}
  ${({ direction }) => direction && `flex-direction: ${direction};`}
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom};`}


  @media (max-width: ${breakpoints.md}px) {
    width: 60%;
  }

  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
  }
`;

export const InputContainer = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  /* margin: 20px 0; */
  margin-bottom: 32px;
  /* justify-content: space-between; */

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 14px;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 10px;
  border: 1px solid #dbdbdb;
  color: #2c2a32;
  background: #fafafa;
  font-size: 16px;
  ${({ disable }) => disable && `background: #FAFAFA;`}

  &::placeholder {
    font-size: 14px;
  }

  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
    font-size: 12px;
    padding-right: 30px;

    &::placeholder {
      font-size: 12px;
    }
  }
`;

export const Icon = styled.div`
  position: absolute;
  right: 10px;
  top: 17px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  // border: 1px solid indigo;

  ${({ active }) => active && `border: 2px solid rgba(17,196,133,.7);`}
  @media (max-width: ${breakpoints.sm}px) {
    right: 5px;
  }

`;

export const QrContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  color: #316d32;

  & > *:not(:first-child) {
    margin-left: 27px;
  }

  @media (max-width: ${breakpoints.md}px) {
    flex-direction: column;

    & > *:not(:first-child) {
      margin-left: 0;
      margin-top: 15px;
    }
  }
`;

export const Button = styled.button`
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px 30px;
  margin: 48px 0;
  border: 1px solid #441eda;
  background-color: #fff;

  & > a {
    text-decoration: none;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #441eda;
  }

  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
  }
`;

export const StatusContainer = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #12072c;
`;

export const Status = styled.div`
  display: inline-block;
  font-weight: bold;
  color: #441eda;
`;

export const ExpireContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 46px;
  margin-bottom: 48px;
`;

export const ExpireLabel = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 19px;
  text-align: center;
  color: #2c2a32;
`;

export const ExpireTime = styled.div`
  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 40px;
  text-align: center;
  color: #2c2a32;
`;
