// Absolute imports
import styled from "styled-components/macro";

// Images
import bgImageTopLeft from "./img/bg-left-top.png";
import bgImageRightBottom from "./img/bg-right-bottom.png";

// Theme
import { breakpoints } from "../../theme/breakpoints";

export const Root = styled.section`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;

  background-image: url(${bgImageTopLeft}), url(${bgImageRightBottom}),
    linear-gradient(to left, #261066, #3a1269, #471368, #59176c);
  background-position: left top, right bottom, center center;
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-size: 639px 650px, 560px 663px, 100% 100%;

  @media (max-width: ${breakpoints.md}px) {
    background-size: 539px 450px, 460px 463px, 100% 100%;
  }

  @media (max-width: ${breakpoints.sm}px) {
    background-size: 339px 250px, 260px 263px, 100% 100%;
  }
`;

export const H2 = styled.h2`
  color: #fff;
  font-size: 48px;
  text-align: center;
  font-family: "Fira Sans", sans-serif;
  margin: 0 0 30px 0;
  font-weight: 600;

  @media (max-width: ${breakpoints.lg}px) {
    font-size: 46px;
  }

  @media (max-width: ${breakpoints.md}px) {
    font-size: 44px;
  }

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 30px;
    margin: 0;
  }
`;

export const Button = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  color: #fff;
  background: #441eda;
  border-radius: 4px;
  text-transform: uppercase;
  padding: 16px 38px;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  margin: 30px auto;

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 16px;
  }
`;

export const Text = styled.p`
  font-size: 22px;
  margin: 10px 0;
`;
