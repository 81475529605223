/**
 * Absolute imports
 */
import styled from 'styled-components/macro';

export const Button = styled.button`
  display: inline-flex;
  align-items: center;
  color: inherit;
  margin: 0;
  padding: 14px 24px;
  border: 0;
  cursor: pointer;
  outline: 0;
  position: relative;
  user-select: none;
  vertical-align: middle;
  justify-content: center;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;

  border-radius: 5px;
  background-color: #d944bb;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.35;
  letter-spacing: 0.6px;
  text-decoration: none;
  text-transform: uppercase;
  color: #ffffff;

  &:hover {
    background-color: #ff62e0;
  }

  &:active {
    background-color: #ac2090;
  }
`;
