import React from "react";
import moment from "moment";
import Paginator from "../Paginator";
import * as stringUtils from "../../utils/stringUtils";
import { Root, Row, NoOrdersContainer, TableHeader, StyledTable } from "./styles";

export default function ExchangeHistory({ pagesCount, page, records, onSelectPage }) {
  return (
    <React.Fragment>
      <Root>
        <StyledTable>
          <TableHeader>
            <tr>
              <th>Date</th>
              <th>Pair</th>
              <th>Status</th>
              <th>Paid</th>
              <th>Received</th>
            </tr>
          </TableHeader>

          <tbody>
            {records.length === 0 ? (
              <Row>
                <td colSpan="5">
                  <NoOrdersContainer>No orders available</NoOrdersContainer>
                </td>
              </Row>
            ) : (
              records.map((data, i) => (
                <Row key={i}>
                  <td>{moment(data.createdAt).format("DD.MM.YYYY HH:mm")}</td>
                  <td>{data.pair}</td>
                  <td>{stringUtils.capitalize(data.status)}</td>
                  <td>
                    {data.funds || "0"} {data.pair.split("/")[0]}
                  </td>
                  <td>{data.tokens || "0"} EEX</td>
                </Row>
              ))
            )}
          </tbody>
        </StyledTable>
      </Root>

      {records.length > 0 && <Paginator currentPage={page} pagesCount={pagesCount} onSelectPage={onSelectPage} />}
    </React.Fragment>
  );
}
