import React from "react";

const colors = {
  dark: "#2C2A32",
  light: "#fff"
};

const sizes = {
  sm: "22",
  lg: "32"
};

const ProfileIcon = ({ color, size }) => (
  <svg
    style={{ verticalAlign: "middle" }}
    width={sizes[size]}
    height={sizes[size]}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.61987 8.70857C8.69451 9.68887 9.0844 9.26633 9.16483 9.6959C9.29856 10.41 9.93077 10.9264 10.3243 11.1048C10.5539 11.2089 10.7903 11.2611 11.0389 11.2652V11.2654C11.0398 11.2654 11.0407 11.2653 11.0415 11.2653C11.0424 11.2653 11.0432 11.2654 11.0441 11.2654V11.2652C11.2927 11.2611 11.5291 11.2089 11.7587 11.1048C12.1523 10.9264 12.7845 10.41 12.9182 9.6959C12.9987 9.26635 13.3885 9.68887 13.4632 8.70857C13.4632 8.3179 13.2503 8.22067 13.2503 8.22067C13.2503 8.22067 13.3585 7.64241 13.4009 7.19746C13.4534 6.64293 13.0772 5.21045 11.0705 5.21045C11.0617 5.21045 11.0536 5.21093 11.045 5.21102C11.0447 5.21102 11.0444 5.21102 11.0441 5.211V5.2109C11.0433 5.21093 11.0424 5.21095 11.0415 5.21095C11.0407 5.21095 11.0398 5.21093 11.039 5.2109V5.21102C11.0387 5.21105 11.0384 5.21105 11.0381 5.21105C11.0295 5.21093 11.0214 5.21047 11.0126 5.21047C9.00591 5.21047 8.62968 6.64295 8.68219 7.19748C8.72456 7.64244 8.83279 8.2207 8.83279 8.2207C8.83279 8.2207 8.61987 8.3179 8.61987 8.70857Z"
      fill={colors[color]}
    />
    <path
      d="M15.5936 13.5209C14.5814 13.006 13.1144 12.3628 13.044 12.3401C13.0432 12.3399 13.0426 12.3398 13.0418 12.3396C13.0006 12.3249 12.9564 12.3168 12.9101 12.3168C12.7382 12.3168 12.5923 12.4269 12.5382 12.5804C12.5379 12.5802 12.5376 12.5801 12.5373 12.5799C12.2761 13.2095 11.7962 14.3317 11.4982 14.8728L11.4265 13.3558C11.4265 13.3558 11.8739 12.406 11.9229 12.319C12.0382 12.115 11.9344 11.9414 11.7182 11.9414C11.611 11.9414 11.3427 11.9414 11.074 11.9414C11.0737 11.9414 11.0734 11.9414 11.0731 11.9414C11.0714 11.9414 11.0697 11.9414 11.068 11.9414C11.0676 11.9414 11.0674 11.9414 11.067 11.9414C11.0634 11.9414 11.0597 11.9414 11.056 11.9414C11.0523 11.9414 11.0486 11.9414 11.0449 11.9414C11.0446 11.9414 11.0443 11.9414 11.044 11.9414C11.0423 11.9414 11.0406 11.9414 11.0389 11.9414C11.0386 11.9414 11.0383 11.9414 11.038 11.9414C10.7693 11.9414 10.5011 11.9414 10.3938 11.9414C10.1776 11.9414 10.0738 12.115 10.1891 12.319C10.2381 12.406 10.6855 13.3558 10.6855 13.3558L10.6138 14.8728C10.3158 14.3317 9.83593 13.2096 9.57469 12.5799C9.5744 12.5801 9.57411 12.5802 9.57382 12.5804C9.51971 12.427 9.37385 12.3168 9.20188 12.3168C9.15564 12.3168 9.11138 12.3249 9.07021 12.3396C9.06944 12.3399 9.06884 12.3399 9.06805 12.3401C8.99767 12.3628 7.5306 13.006 6.51843 13.5209C6.06388 13.7521 5.78955 14.0398 5.78955 14.3424C5.78955 15.3431 5.78955 16.7248 5.78955 16.7248H11.0343H11.0388H11.0414H11.0415H11.0441H11.0486H11.056H11.0634H11.068H11.0705H11.0706H11.0731H11.0777H16.3225C16.3225 16.7248 16.3225 15.3431 16.3225 14.3424C16.3224 14.0398 16.0481 13.7521 15.5936 13.5209Z"
      fill={colors[color]}
    />
    <circle cx="11" cy="11" r="10.5" stroke={colors[color]} />
  </svg>
);

export default ProfileIcon;
